import { Booking } from '../../../../domain/Booking'
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Customer } from '../../../../domain/Customer'
import { InputComponent, InputType } from '../input/input.component'
import { ModelCloningService } from '../../../../services/model-cloning.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastType } from '../../../../domain/Toast'
import { ToastService } from '../../../../services/toast.service'
import { ToastBuilder } from '../../../../domain/ToastBuilder'
import { finalize, Observable, takeUntil } from 'rxjs'
import { CustomerService } from '../../../../services/customer.service'
import { ContextService } from '../../../../services/context.service'
import { ValidatorFn, Validators } from '@angular/forms'

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
})
export class CustomerComponent {

    @Input() customer!: Customer
    @Output() bookingSelected = new EventEmitter<Booking>()
    @Output() customerChanged = new EventEmitter<Customer>()
    tab: 'details' | 'bookings' = 'details'

    constructor(
        private modal: NgbActiveModal
    ) { }

    dismissClicked() {
        this.modal.dismiss()
    }

    detailsTabSelected() {
        this.tab = 'details'
    }

    bookingsTabSelected() {
        this.tab = 'bookings'
    }

    customerUpdated(customer: Customer) {
        this.customer = customer
        this.customerChanged.emit(customer)
    }
}
