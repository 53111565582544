<div class="hstack">
    <div class="p-1 vstack gap-1">
        <div class="hstack">
            <span class="fw-semibold flex-grow-1">
                {{ title }}
            </span>
        </div>
        <div class="hstack gap-2 align-items-center">
            @if (subtitleIcon) {
                <img
                    ngSrc="{{ subtitleIcon }}"
                    width="16"
                    height="16"
                    alt="subtitle icon"
                />
            }
            <span class="form-text m-0"
            [innerText]="subtitle">
            </span>
        </div>
    </div>
    <div class="hstack gap-2">
        <div class="hstack gap-2 align-items-center">
            @if (descriptionIcon) {
                <img
                    ngSrc="{{ descriptionIcon }}"
                    width="16"
                    height="16"
                    alt="description icon"
                />
            }
            <span
                *ngIf="description"
                class="fw-semibold text-secondary"
                [innerText]="description"
            >
            </span>
        </div>
        <span class="bi bi-chevron-right"></span>
    </div>
</div>
