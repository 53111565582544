import { Observable } from 'rxjs'

export class ResizeObservable extends Observable<ResizeObserverEntry[]> {
    constructor(elem: HTMLElement) {
        super(subscriber => {
            const ro = new ResizeObserver(entries => {
                subscriber.next(entries)
            })
            ro.observe(elem)
            return function unsubscribe() {
                ro.unobserve(elem)
                ro.disconnect()
            }
        })
    }
}
