import { QuestionTypeDTO } from './QuestionDTO'

export class AnswerDTO {
    constructor(
        public questionId: string,
        public question: string,
        public required: boolean,
        public type: QuestionTypeDTO,
        public answer: string
    ) { }
}
