import { AuthService } from '@services/auth.service'
import { DestroyedDirective } from '@app/features/shared/directives/destroyed.directive'
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core'
import { takeUntil } from 'rxjs'

@Directive({
    selector: '[appIfLoggedIn]',
    hostDirectives: [DestroyedDirective],
})
export class IfLoggedInDirective implements OnInit {

    private templateRef = inject(TemplateRef<unknown>)
    private viewContainer = inject(ViewContainerRef)
    private authService = inject(AuthService)
    private destroy$ = inject(DestroyedDirective).destroyed$

    @Input() appIfLoggedIn!: TemplateRef<any>
    @Input() appIfLoggedInElse!: TemplateRef<any>

    ngOnInit() {
        this.authService.loggedIn$
            .pipe(takeUntil(this.destroy$))
            .subscribe(loggedIn => {
                this.viewContainer.clear()
                if (loggedIn) {
                    this.viewContainer.createEmbeddedView(this.appIfLoggedIn)
                } else {
                    this.viewContainer.createEmbeddedView(this.appIfLoggedInElse)
                }
            })

    }
}
