import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.sass'],
})
export class LoadingComponent<T> implements OnInit {

    @Input() observable!: Observable<T>
    constructor() { }

    ngOnInit() { }
}
