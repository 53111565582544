import {
    DateComponentDescriptionPipe,
} from '@app/features/shared/pipes/date-component-description.pipe'
import { Pipe, PipeTransform } from '@angular/core'
import { Schedule } from '@app/domain/Schedule'

@Pipe({
    name: 'scheduleRules',
})
export class ScheduleRulesPipe implements PipeTransform {

    constructor(
        private dateComponentsPipe: DateComponentDescriptionPipe
    ) { }

    transform(schedule: Schedule): string {
        if (schedule.openRules.length === 0) {
            return 'Closed'
        }
        return schedule.openRules.map(rule => {
            const timeRange = `${rule.period.start} - ${rule.period.end}`
            const day = this.dateComponentsPipe.transform(rule.dateComponents)
            return `${timeRange} ${day}`
        })
            .join('\n')
    }
}
