import { Pipe, PipeTransform } from '@angular/core';
import { ExternalReviewSource } from '../../../domain/CommunicationPreferences'

@Pipe({
  name: 'feedbackReviewSource'
})
export class FeedbackReviewSourcePipe implements PipeTransform {

  transform(source: ExternalReviewSource | null,): string {
      switch (source) {
        case ExternalReviewSource.Google:
            return 'Google';
        case ExternalReviewSource.TripAdvisor:
            return 'TripAdvisor';
        default:
            return 'Unknown';
      }
  }

}
