import { Booking } from '@app/domain/Booking'
import { BookingService } from '@services/booking.service'
import { Component, Input, OnInit } from '@angular/core'
import { ContextService } from '@services/context.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-requested-booking-details',
    templateUrl: './requested-booking-details.component.html',
})
export class RequestedBookingDetailsComponent implements OnInit {

    @Input() booking!: Booking
    concurrentActiveTableCount!: Observable<number>
    concurrentTablesPluralMapping = {
        '=0': '# other tables have',
        '=1': '# other table has',
        other: '# other tables have',
    }

    constructor(
        private bookingService: BookingService,
        public contextService: ContextService,
        public modal: NgbActiveModal
    ) { }

    ngOnInit() {
        const business = this.contextService.getBusiness()
        if (business === null) {
            return
        }
        const venue = this.contextService.getVenue()
        if (venue === null) {
            return
        }
        this.concurrentActiveTableCount = this.bookingService.getVenueActiveTableCount(
            business.id,
            venue.id,
            this.booking.start,
            this.booking.end
        )
    }
}
