import { Time } from '@app/domain/Time'

export class Period {
    constructor(
        public start: Time,
        public end: Time,
        public open: boolean
    ) { }

    containsTime(time: Date): boolean {
        if (!this.open) {
            return false
        }
        const start = new Date(time)
        start.setHours(this.start.hours, this.start.minutes, 0, 0)
        const end = new Date(time)
        end.setHours(this.end.hours, this.end.minutes, 0, 0)
        return time >= start && time <= end
    }

    dateRangeOnDate(date: Date): Date[] {
        const start = new Date(date)
        start.setHours(this.start.hours, this.start.minutes, 0, 0)
        const end = new Date(date)
        end.setHours(this.end.hours, this.end.minutes, 0, 0)
        return [start, end]
    }

    isOpenAnyTimeOnDate(date: Date): boolean {
        if (!this.open) {
            return false
        }
        const range = this.dateRangeOnDate(date)
        return range[0] < range[1]
    }
}
