import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core'

@Directive({
    selector: '[appFadeOnChange]',
})
export class FadeOnChangeDirective implements OnInit, OnChanges {

    @Input() fadeOnChangeValue: any
    private duration = 0.2

    constructor(private renderer: Renderer2, private el: ElementRef) { }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'textContent', this.fadeOnChangeValue)
        this.renderer.setStyle(
            this.el.nativeElement,
            'transition', 'opacity ' + this.duration + 's ease-in-out'
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['fadeOnChangeValue'] && !changes['fadeOnChangeValue'].firstChange) {
            this.animateTextChange(
                changes['fadeOnChangeValue'].previousValue,
                changes['fadeOnChangeValue'].currentValue
            )
        }
    }

    private animateTextChange(oldText: string, newText: string) {
        this.renderer.setProperty(this.el.nativeElement, 'textContent', oldText)
        this.renderer.setStyle(this.el.nativeElement, 'opacity', '0')
        setTimeout(() => {
            this.renderer.setStyle(this.el.nativeElement, 'opacity', '1')
            this.renderer.setProperty(this.el.nativeElement, 'textContent', newText)
        }, this.duration * 1000)
    }
}
