import { Pipe, PipeTransform } from '@angular/core'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'

@Pipe({
    name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {

    transform(
        phoneNumber: string | null,
        fallbackCountryCode: string = 'GB'
    ): string | null {
        if (!phoneNumber) {
            return null
        }
        const phoneUtil = PhoneNumberUtil.getInstance()
        const number = phoneUtil.parse(phoneNumber, fallbackCountryCode)
        return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL)
    }
}
