import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
    selector: 'app-html',
    templateUrl: './html.component.html',
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class HTMLComponent implements OnChanges {

    @Input() html!: string
    safeHtml!: SafeHtml

    constructor(
        private sanitiser: DomSanitizer
    ) { }

    ngOnChanges() {
        this.safeHtml = this.sanitiser.bypassSecurityTrustHtml(this.html)
    }
}
