import { Component, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi:true,
            useExisting: NumberInputComponent,
        },
    ],
})
export class NumberInputComponent implements ControlValueAccessor {

    @Input() minimum!: number
    @Input() maximum!: number
    @Input() iconPath?: string = undefined
    @Input() pluralMapping?: { [key: number]: string } = undefined

    number: number | null = null

    onChange = (_: number | null) => {}

    onTouched = () => {}

    touched = false

    disabled = false

    get inputRange() {
        return Array
            .from({ length: this.maximum - this.minimum + 1 }, (_, i) => i + this.minimum)
    }

    increment() {
        this.changeValue(this.number ? this.number + 1 : 1)
    }

    decrement() {
        this.changeValue(this.number ? this.number - 1 : 0)
    }

    registerOnChange(fn: any) {
        this.onChange = fn
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled
    }

    writeValue(obj: number) {
        this.number = obj
    }

    changeValue(value: number | null) {
        if (this.disabled) {
            return
        }
        if (value === null) {
            this.number = null
            this.onChange(this.number)
            return
        }
        if (this.minimum !== null && value < this.minimum) {
            this.number = this.minimum
            this.onChange(this.number)
            return
        }
        this.number = value
        this.onChange(this.number)
    }
}
