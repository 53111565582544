import { Contact } from '@app/domain/Contact'
import { Dinero } from 'dinero.js'

export enum DepositStatus {
    Paid = 'Paid',
    Refunded = 'Refunded',
}

export class DepositsReportData {

    constructor(
        public bookingId: string,
        public start: Date,
        public amount: Dinero,
        public partySize: number,
        public contact: Contact,
        public datePaid: Date,
        public dateRefunded: Date | null
    ) { }

    get status(): DepositStatus {
        if (this.dateRefunded) {
            return DepositStatus.Refunded
        }
        return DepositStatus.Paid
    }
}
