<div
    class="vstack gap-1"
    role="group"
    [formGroup]="form"
>
    <div
        class="hstack"
        [ngClass]="{
            'btn-group': style === 'toolbar',
            'btn-not-group-md gap-0 gap-md-1': style === 'title',
        }"
    >
        <button
            *ngIf="style !== 'dropdown'"
            type="button"
            class="btn btn-sm btn-outline-secondary flex-grow-0"
            (click)="decrementDate()"
        >
            <img src="../../../../../assets/left/normal.svg">
        </button>
        <button
            class="btn btn-outline-secondary hstack gap-2 justify-content-center text-nowrap"
            [ngClass]="{
                'order-1': style === 'toolbar',
                'order-1 order-md-2': style === 'title',
                'ms-0 ms-md-1': style === 'title',
                'dropdown-toggle': style === 'dropdown',
                'flex-md-grow-0 w-100': style === 'dropdown',
                'flex-grow-1': style === 'title' || style === 'toolbar',
            }"
            (click)="d.toggle()"
            type="button"
        >
            <div
                *ngIf="style !== 'dropdown'"
                class="hstack gap-2"
            >
                <div
                    [ngClass]="{
                        'd-none': style === 'title',
                    }"
                >
                    <span class="fs-4 d-none d-md-block">
                        {{ form.get('date')?.value | ngbDate | date: 'EEEE, MMMM dd, yyyy' }}
                    </span>
                    <span class="fs-4 d-block d-md-none">
                        {{ form.get('date')?.value | ngbDate | date: 'EE, MMM dd, yyyy' }}
                    </span>
                </div>
                <div
                    class="hstack gap-1 fw-normal"
                    [ngClass]="{
                        'd-none': style === 'toolbar',
                    }"
                >
                    <span class="fs-5">
                        {{ form.get('date')?.value | ngbDate | date: 'EE' }}
                    </span>
                    <span class="fs-5 fw-semibold d-none d-md-block">
                        {{ form.get('date')?.value | ngbDate | date: 'MMMM dd, yyyy' }}
                    </span>
                    <span class="fs-5 fw-semibold d-block d-md-none">
                        {{ form.get('date')?.value | ngbDate | date: 'MMM dd, yyyy' }}
                    </span>
                </div>
                <img
                    ngSrc="../../../../../assets/calendar/normal.svg"
                    alt="Date Icon"
                    height="24"
                    width="24"
                >
            </div>
            <div
                *ngIf="style === 'dropdown'"
                class="hstack gap-2"
            >
                <img
                    ngSrc="../../../../../assets/calendar/normal.svg"
                    alt="Date Icon"
                    height="24"
                    width="24"
                >
                <span class="">
                    {{ form.get('date')?.value | ngbDate | date: 'EEE, MMM dd' }}
                </span>
            </div>
        </button>
        <input
            formControlName="date"
            class="form-control p-0 m-0 border-0"
            ngbDatepicker
            [placement]="{
                'title': 'bottom-start',
                'toolbar': 'bottom-end',
                'dropdown': 'bottom-start',
            }[style]"
            #d="ngbDatepicker"
            style="width: 0; height: 0"
            [popperOptions]="offsetCalendarPopoverToBelowNavBar"
        />

        <button
            *ngIf="style !== 'dropdown'"
            type="button"
            class="btn btn-sm btn-outline-secondary flex-grow-0"
            [ngClass]="{
                'order-1': style === 'title',
                'order-3': style === 'toolbar',
            }"
            (click)="incrementDate()"
        >
            <img src="../../../../../assets/right/normal.svg">
        </button>
    </div>
</div>
