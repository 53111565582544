import { BookingStatusType } from '@app/domain/BookingStatus'
import { HasBookingStatus } from '@app/features/bookings/pipes/booking-status-filter.pipe'
import { Helper } from '@app/helpers/utilities/helper'

export class BookingsReportData implements HasBookingStatus {
    public bookingId: string
    public statusType: BookingStatusType
    public venueName: string
    public areaName: string
    public tableName: string
    public size: number
    public start: Date
    public durationMinutes: number

    constructor(
        bookingId: string,
        statusType: BookingStatusType,
        venueName: string,
        areaName: string,
        tableName: string,
        size: number,
        start: Date,
        durationMinutes: number
    ) {
        this.bookingId = bookingId
        this.statusType = statusType
        this.venueName = venueName
        this.areaName = areaName
        this.tableName = tableName
        this.size = size
        this.start = start
        this.durationMinutes = durationMinutes
    }

    get formattedTableName(): string {
        return Helper.getDisplayNameFromName(this.tableName, 'Table')
    }
}
