import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ContextService } from '@services/context.service'
import { Observable, Subject, combineLatest, map, of, takeUntil } from 'rxjs'
import { Venue } from '@app/domain/Venue'

@Component({
    selector: 'app-venue-selection',
    templateUrl: './venue-selection.component.html',
})
export class VenueSelectionComponent implements OnInit, OnDestroy {

    @Input() compact = false
    venue$!: Observable<Venue>
    venues$!: Observable<Venue[]>
    venueDropdownTitle$: Observable<string>
    private onDestroy$ = new Subject<void>()

    constructor(
        private contextService: ContextService
    ) {
        this.venueDropdownTitle$ = of('Select Venue')
    }

    ngOnInit() {
        this.venue$ = this.contextService.venue$
        this.venues$ = combineLatest([
            this.contextService.business$,
            this.contextService.user$,
        ])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([business, user]) => {
                    if (business === null || user === null) {
                        return []
                    }
                    return business.venuesUserWorksIn(user)
                })
            )
        this.venueDropdownTitle$ = this.contextService.venue$
            .pipe(
                takeUntil(this.onDestroy$),
                map(venue => venue.displayName)
            )
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    selectVenue(venue: Venue) {
        this.contextService.setVenue(venue)
    }
}
