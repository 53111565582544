<ng-container *ngIf="bookings.length === 0">
    <ng-container *ngTemplateOutlet="emptyState">
    </ng-container>
</ng-container>
<ng-container *ngIf="bookings.length > 0">
    <ng-container *ngTemplateOutlet="content">
    </ng-container>
</ng-container>

<ng-template #emptyState>
    <h4
        class="p-3 text-center"
    >
        No Bookings
    </h4>
</ng-template>

<ng-template #content>
    <div class="bg-white">
        <table
            class="table table-responsive table-hover mb-0"
        >
            <thead class="sticky-top top-0 text-nowrap">
            <tr>
                <th class="ps-3">
                    Name
                </th>
                <th class="text-center">
                    Covers
                </th>
                @if (showBookingDateTime) {
                <th class="text-center">
                    Start
                </th>
                }
                @if (showBookingTime) {
                <th class="text-center">
                    Start
                </th>
                }
                <th class="text-center">
                    Status
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                *ngFor="let booking of bookings"
                role="button"
                (click)="bookingClicked(booking)"
            >
                <td class="align-middle ps-3 pe-2">
                    @if (booking.isWalkIn()) {
                        {{ 'Walk-In' }}
                    } @else {
                    {{ booking.name }}
                    }
                </td>
                <td class="text-center align-middle pe-2">
                    <app-party-size-badge
                        [partySize]="booking.size"
                    >
                    </app-party-size-badge>
                </td>
                @if (showBookingDateTime) {
                <td class="text-nowrap text-center align-middle pe-2">
                    <div class="vstack justify-content-around">
                        <span>{{ booking.start | date: 'HH:mm a' }},</span>
                        <span>{{ booking.start | date: 'dd MMM' }}</span>
                    </div>
                </td>
                }
                @if (showBookingTime) {
                <td class="text-nowrap text-center align-middle pe-2">
                    <div class="vstack justify-content-around">
                        <span>{{ booking.start | date: 'shortTime' }}</span>
                    </div>
                </td>
                }
                <td class="text-nowrap text-center align-middle">
                    <app-booking-status-badge
                        [statusType]="booking.status().type"
                        [showNotificationIndicator]="booking.canChargeCancellationFee()"
                        [ngbTooltip]="booking.canChargeCancellationFee() ? 'Chargeable' : ''"
                        container="body"
                    >
                        {{ booking.status().type | bookingStatusName }}
                    </app-booking-status-badge>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-template>
