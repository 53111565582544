import { Helper } from '@app/helpers/utilities/helper'

export interface DateRange {
    fromDate: Date
    toDate: Date | null
}

export function dateRangeEquals(dateRange1: DateRange, dateRange2: DateRange) {
    return Helper.startOfDay(dateRange1.fromDate).getTime()
        === Helper.startOfDay(dateRange2.fromDate).getTime()
        && Helper.startOfDay(dateRange1.toDate!).getTime()
        === Helper.startOfDay(dateRange2.toDate!).getTime()
}
