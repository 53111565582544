import * as d3 from 'd3'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'pathPipe',
})
export class PathPipe implements PipeTransform {

    transform(
        x: number,
        y: number,
        width: number,
        height: number,
        topLeftRadius = 0,
        topRightRadius = 0,
        bottomRightRadius = 0,
        bottomLeftRadius = 0
    ): string {
        const path = d3.path()
        path.moveTo(x + topLeftRadius, y)
        path.lineTo(x + width - topRightRadius, y)
        path.quadraticCurveTo(x + width, y, x + width, y + topRightRadius)
        path.lineTo(x + width, y + height - bottomRightRadius)
        path.quadraticCurveTo(
            x + width,
            y + height,
            x + width - bottomRightRadius,
            y + height
        )
        path.lineTo(x + bottomLeftRadius, y + height)
        path.quadraticCurveTo(x, y + height, x, y + height - bottomLeftRadius)
        path.lineTo(x, y + topLeftRadius)
        path.quadraticCurveTo(x, y, x + topLeftRadius, y)
        path.closePath()
        return path.toString()
    }
}
