export enum QuestionType {
    Checkbox,
    Dropdown,
    AcknowledgeEndTime,
    DOB,
    ReasonsForVisit,
}

export type QuestionSource = 'merchant' | 'system-defined'

export function sourceTypeOfQuestionType(type: QuestionType): QuestionSource {
    switch (type) {
    case QuestionType.AcknowledgeEndTime:
        return 'system-defined'
    case QuestionType.DOB:
        return 'system-defined'
    case QuestionType.ReasonsForVisit:
        return 'system-defined'
    default:
        return 'merchant'
    }
}

export class Question {
    constructor(
        public id: string,
        public text: string,
        public required: boolean,
        public type: QuestionType,
        public options: string[],
        public reasonIds: string[]
    ) { }

    get isTickable(): boolean {
        return this.type === QuestionType.Checkbox || this.type === QuestionType.AcknowledgeEndTime
    }

    get merchantDisplayText(): string {
        if (this.type === QuestionType.AcknowledgeEndTime) {
            return 'I acknowledge when my booking ends'
        }
        if (this.type === QuestionType.DOB) {
            return 'Birthday'
        }
        return this.text
    }
}
