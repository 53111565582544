export class ScheduleException {
    constructor(
        public id: string,
        public startDate: Date,
        public endDate: Date,
        public description: string | null
    ) { }

    appliesToDate(date: Date) {
        const startOfStartDate = new Date(this.startDate)
        startOfStartDate.setHours(0, 0, 0, 0)
        const endOfEndDate = new Date(this.endDate)
        endOfEndDate.setHours(23, 59, 59, 999)
        return date >= startOfStartDate && date <= endOfEndDate
    }
}
