<div class="d-flex flex-fill w-100">
    <svg
        #svg
        class="user-select-none bg-light rounded rounded-2 border-light-subtle"
        width="100%"
        [attr.height]="height"
        data-testid="floor-plan"
        (mousedown)="onMouseDown($event)"
        (touchstart)="onTouchStart($event)"
        (mousemove)="onMouseMove($event)"
        (touchmove)="onTouchMove($event)"
        (mouseup)="onMouseUp($event)"
        (touchend)="onTouchEnd($event)"
    >
        <defs>
            <pattern
                id="grid"
                [attr.width]="xScale.range()[1] / (floorDimensions.width + 0)"
                [attr.height]="yScale.range()[1] / (floorDimensions.height + 0)"
                patternUnits="userSpaceOnUse"
            >
                <circle
                    [attr.cx]="xScale.range()[1] / (floorDimensions.width + 0) - 2"
                    [attr.cy]="yScale.range()[1] / (floorDimensions.height + 0) - 2"
                    r="1"
                    class="floor-grid-point"
                ></circle>
            </pattern>
        </defs>
        <rect
            [attr.x]="margin.left"
            [attr.y]="margin.top"
            [attr.width]="xScale.range()[1]"
            [attr.height]="yScale.range()[1]"
            fill="url(#grid)"
        ></rect>
        <g
            [attr.transform]="'translate(' + margin.left + ',' + margin.top + ')'"
        >
            <g
                [tableViewModels]="tableViewModels"
                [xScale]="xScale"
                [yScale]="yScale"
                [mouseEvents$]="mouseEvents$"
                app-floor-tables
            >
            </g>
        </g>
    </svg>
</div>
