import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common'
import { BookingStatusDirective } from '@app/features/shared/directives/booking-status.directive'
import { BookingStatusType } from '@app/domain/BookingStatus'
import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-booking-status-badge',
    standalone: true,
    templateUrl: './booking-status-badge.component.html',
    styleUrls: ['./booking-status-badge.component.sass'],
    imports: [
        BookingStatusDirective,
        NgIf,
        AsyncPipe,
        NgOptimizedImage,
    ],
})
export class BookingStatusBadgeComponent {

    @Input() statusType!: BookingStatusType
    @Input() showNotificationIndicator = false
}
