import { Pipe, PipeTransform } from '@angular/core'
import { UserRole } from '@app/domain/User'

@Pipe({
    name: 'userRoleName',
})
export class UserRoleNamePipe implements PipeTransform {

    transform(userRole: UserRole): string {
        switch (userRole) {
        case UserRole.OWNER:
            return 'Owner'
        case UserRole.MANAGER:
            return 'Manager'
        }
    }
}
