<div class="modal-header">
    <div class="hstack gap-2 flex-wrap">
        <h4 class="mb-0">
            Customer Profile
        </h4>
    </div>
    <button
        type="button"
        class="btn-close"
        data-testid="booking-dismiss-button"
        (click)="dismissClicked()"
        ngbAutofocus
    >
    </button>
</div>
<div class="modal-body p-0 vstack h-100">
    <ul class="nav nav-pills px-3 px-md-4 tabbed">
        <li class="nav-item">
            <button
                class="nav-link"
                [ngClass]="tab === 'details' ? 'active' : ''"
                (click)="detailsTabSelected()"
            >
                Details
            </button>
        </li>
        <li class="nav-item">
            <button
                class="nav-link"
                [ngClass]="tab === 'bookings' ? 'active' : ''"
                (click)="bookingsTabSelected()"
            >
                Booking History
            </button>
        </li>
    </ul>
    @switch (tab) {
        @case ('details') {
            <app-customer-details
                [customer]="customer"
                (customerUpdated)="customerUpdated($event)"
            >
            </app-customer-details>
        }
        @case ('bookings') {
            <app-customer-booking-list
                style="display: contents;"
                [customer]="customer"
                (bookingSelected)="bookingSelected.emit($event)"
            >
            </app-customer-booking-list>
        }
    }
</div>
