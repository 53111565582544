import { Injectable } from '@angular/core'
import Prism from 'prismjs'

@Injectable({
    providedIn: 'root',
})
export class CodeHighlightService {

    html(code: string): string {
        return Prism.highlight(code, Prism.languages['html'], 'html')
    }
}
