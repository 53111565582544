<ng-container
    [formGroup]="form"
>
    <div class="vstack gap-2">
        <div class="hstack gap-2 align-items-center">
            <label
                class="mb-0 form-label fw-semibold"
            >
                Customer
            </label>
            @if (selectedCustomerNote) {
                <div class="hstack gap-2 rounded rounded-2 bg-light px-2 py-1">
                    <img
                        ngSrc="assets/person/normal.svg"
                        alt="Customer Note"
                        width="14"
                        height="14"
                    />
                    <span
                        class="text-muted fw-semibold pe-1"
                    >
                        {{ selectedCustomerNote }}
                    </span>
                </div>
            }
        </div>
        <div class="">
            <div class="row gap-3 gap-lg-0">
                <div class="col-lg-6">
                    <div class="position-relative">
                        <input
                            [formControlName]="'firstName'"
                            type="text"
                            class="form-control"
                            id="firstName"
                            placeholder="First Name"
                            autocomplete="off"
                            autocapitalize="words"
                            appCapitaliseOnBlur
                            (focusin)="focusInFirstName()"
                            (focusout)="focusOutFirstName()"
                        >
                        <ng-container *ngIf="focusedInput === 'firstName'">
                            <ng-container
                                [ngTemplateOutlet]="contactSuggestions"
                                [ngTemplateOutletContext]="{ alignment: 'start-0' }"
                            >
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="position-relative">
                        <input
                            [formControlName]="'lastName'"
                            type="text"
                            class="form-control"
                            id="lastName"
                            placeholder="Last Name"
                            autocomplete="off"
                            autocapitalize="words"
                            appCapitaliseOnBlur
                            (focusin)="focusInLastName()"
                            (focusout)="focusOutLastName()"
                        >
                        <ng-container *ngIf="focusedInput === 'lastName'">
                            <ng-container
                                [ngTemplateOutlet]="contactSuggestions"
                                [ngTemplateOutletContext]="{ alignment: 'end-0' }"
                            >
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <label
            class="mt-1 mb-0 form-label fw-semibold"
        >
            Contact Information
        </label>
        <div class="row gap-3 gap-lg-0">
            <div class="col-lg-6">
                <div class="position-relative">
                    <input
                        [formControlName]="'emailAddress'"
                        type="text"
                        class="form-control"
                        id="emailAddress"
                        placeholder="Email Address"
                        autocomplete="off"
                        (focusin)="focusInEmailAddress()"
                        (focusout)="focusOutEmailAddress()"
                    >
                    <ng-container *ngIf="focusedInput === 'emailAddress'">
                        <ng-container
                            [ngTemplateOutlet]="contactSuggestions"
                            [ngTemplateOutletContext]="{ alignment: 'start-0' }"
                        >
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="position-relative">
                    <input
                        [formControlName]="'phoneNumber'"
                        type="text"
                        class="form-control"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        autocomplete="off"
                        (focusin)="focusInPhoneNumber()"
                        (focusout)="focusOutPhoneNumber()"
                    >
                    <ng-container *ngIf="focusedInput === 'phoneNumber'">
                        <ng-template
                            [ngTemplateOutlet]="contactSuggestions"
                            [ngTemplateOutletContext]="{ alignment: 'end-0' }"
                        >
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template
    #contactSuggestions
    let-alignment="alignment"
>
    <div
        *ngIf="showCustomerSuggestions && customerSearch.length > 0"
        class="position-absolute position-relative bg-white shadow-lg border border-1 border-light rounded-3 z-1"
        [ngClass]="alignment"
        style="max-height: 250px; max-width: 400px; overflow-y: auto;"
    >
        <div class="hstack gap-3 justify-content-between position-sticky top-0 bg-white px-3 py-2 z-2 border-bottom">
            <h6
                class="list-group-item list-group-item-action fw-semibold m-0"
            >
                Suggestions
            </h6>
            <button
                class="btn-close"
                (click)="hideCustomerSuggestions()"
                role="button"
            >
            </button>
        </div>
        <ul class="list-group m-0 p-0">
            <ng-container *ngFor="let item of customerSearch">
                <ng-container
                    *ngTemplateOutlet="suggestionTemplate; context: { $implicit: item }"
                ></ng-container>
            </ng-container>
        </ul>
    </div>
</ng-template>

<ng-template
    #suggestionTemplate
    let-item
>
    <li
        class="list-group-item list-group-item-action rounded-0"
        (click)="customerSuggestionSelected(item)"
        role="button"
    >
            <div class="hstack gap-1 fw-semibold">
                {{ item.firstName }}
                <span *ngIf="item.lastName">
                    {{ item.lastName }}
                </span>
            </div>
            <div class="text-secondary hstack gap-2 justify-content-between">
                <div
                    *ngIf="item.emailAddress"
                    class="hstack gap-1 align-items-center"
                >
                    <span class="text-truncate">
                        {{ item.emailAddress }}
                    </span>
                </div>
                <div
                    *ngIf="item.phoneNumber"
                    class="hstack gap-1 align-items-center"
                >
                    <img
                        ngSrc="../../../../../assets/phone/normal_dark.svg"
                        alt="Phone Number"
                        width="16"
                        height="16"
                    >
                    <span class="text-truncate">
                        {{ item.phoneNumber | phoneNumber }}
                    </span>
                </div>
            </div>
    </li>
</ng-template>
