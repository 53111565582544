export enum BookingStatusTypeDTO {
    Requested = 'Requested',
    PendingPayment = 'PendingPayment',
    Booked = 'Booked',
    Cancelled = 'Cancelled',
    NoShow = 'NoShow',
    Waiting = 'Waiting',
    PartiallySeated = 'PartiallySeated',
    Seated = 'Seated',
    Finished = 'Finished',
    Rejected = 'Rejected',
}

export class BookingStatusDTO {
    constructor(
        public id: string,
        public metadata: { [k: string]: any | null },
        public dateTime: string,
        public type: BookingStatusTypeDTO
    ) { }
}
