<span
    class="hstack align-items-center gap-2"
    [class.small]="size === 'small'"
    [ngClass]="{
        'merchant': source === BookingSource.Merchant,
        'customer': source === BookingSource.Customer,
        'google': source === BookingSource.Google,
    }"
>
    <ng-container *ngIf="source === BookingSource.Merchant">
        By Merchant
    </ng-container>
    <ng-container *ngIf="source === BookingSource.Customer">
        By Customer
    </ng-container>
    <ng-container *ngIf="source === BookingSource.Google">
        By Customer
        <img
            ngSrc="assets/google/normal.svg"
            width=32
            height=32
            alt="Google"
            class="icon-xs"
        >
    </ng-container>
</span>
