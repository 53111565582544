import { Address } from '@app/domain/Address'
import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingReason } from '@app/domain/BookingReason'
import { BookingSlot } from '@app/domain/BookingSlot'
import { Brand } from '@app/domain/Brand'
import { Charge } from '@app/domain/Charge'
import { CommunicationPreferences } from '@app/domain/CommunicationPreferences'
import { DiaryPreferences } from '@app/domain/DiaryPreferences'
import { Event } from '@app/domain/VenueEvent'
import { Helper } from '@app/helpers/utilities/helper'
import { Question, sourceTypeOfQuestionType } from '@app/domain/Question'
import { Table } from '@app/domain/Table'
import { VenueClosure } from '@app/domain/VenueClosure'
import { VenueVelocity } from '@app/domain/VenueVelocity'
import { WidgetConfiguration } from '@app/domain/WidgetConfiguration'

export class Venue {

    constructor(
        public id: string,
        public displayName: string,
        public phoneNumber: string,
        public address: Address,
        public bookingInterval: number,
        public minLargePartySize: number | null,
        public largePartyMessage: string | null,
        public noBookingSlotAvailableMessage: string | null,
        public shortPreBookingWindowMessage: string | null,
        public timeZone: string,
        public cancellationChargeMinPartySize: number | null,
        public cancellationChargeMaxPartySize: number | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargePartyMinPartySize: number | null,
        public cancellationChargePartyAmount: number | null,
        public cancellationChargeCutOffHours: number,
        public cancellationChargeAutomatically: boolean,
        public communicationPreferences: CommunicationPreferences,
        public depositMinPartySize: number | null,
        public depositAmount: number | null,
        public depositRefundCutOffDays: number | null,
        public diaryPreferences: DiaryPreferences,
        public velocity: VenueVelocity,
        public widgetConfiguration: WidgetConfiguration,
        public areas: Area[],
        public areaBookingOrder: string[],
        public manualAcceptanceUntilDate: Date | null,
        public brand: Brand,
        public reasons: BookingReason[],
        public questions: Question[],
        public events: Event[]
    ) { }

    get activeAreas(): Area[] {
        return this.areas.filter(area => area.isActive)
    }

    get usesAreaBookingOrder(): boolean {
        return this.areaBookingOrder.length > 0
    }

    get areasSortedByDisplayOrder() {
        return [...this.areas].sort((a, b) => a.displayOrder - b.displayOrder)
    }

    get areasSortedByBookingOrder() {
        if (!this.usesAreaBookingOrder) {
            return this.areasSortedByDisplayOrder
        }
        return this.areaBookingOrder
            .map(id => {
                return this.areaWithId(id)
            })
            .filter(area => area !== null) as Area[]
    }

    areaWithId(id: string): Area | null {
        return this.areas.find(a => a.id === id) || null
    }

    areaWithTableId(tableId: string): Area | null {
        return this.areas.find(area => area.getTableWithId(tableId) !== null) || null
    }

    areaWithTableCombinationId(combinationId: string): Area | null {
        return this.areas.find(area => {
            return area.combinations.find(c => c.id === combinationId) !== undefined
        }) || null
    }

    areaBookingIsIn(booking: Booking): Area | null {
        return this.areas.find(area => {
            return area.tablesUsedForBooking(booking).length > 0
        }) || null
    }

    updateArea(area: Area) {
        const index = this.areas.findIndex(a => a.id === area.id)
        this.areas[index] = area
    }

    removeArea(area: Area) {
        const index = this.areas.findIndex(a => a.id === area.id)
        this.areas.splice(index, 1)
    }

    get reasonsSortedByDisplayOrder(): BookingReason[] {
        return [...this.reasons].sort((a, b) => a.displayOrder - b.displayOrder)
    }

    reasonWithId(id: string): BookingReason | null {
        return this.reasons.find(r => r.id === id) || null
    }

    addReason(reason: BookingReason) {
        if (this.reasonWithId(reason.id) !== null) {
            return
        }
        this.reasons.push(reason)
    }

    updateReason(reason: BookingReason) {
        const index = this.reasons.findIndex(r => r.id === reason.id)
        if (index === -1) {
            return
        }
        this.reasons[index] = reason
    }

    removeReason(reason: BookingReason) {
        const index = this.reasons.findIndex(r => r.id === reason.id)
        if (index === -1) {
            return
        }
        this.reasons.splice(index, 1)
        this.areas.forEach(area => {
            area.tables.forEach(table => {
                table.removeReason(reason)
            })
        })
    }

    get eventsSortedByDisplayOrder(): Event[] {
        return [...this.events].sort((a, b) => a.displayOrder - b.displayOrder)
    }

    get pinnedEvent(): Event | null {
        return this.events.find(e => e.id === this.widgetConfiguration.pinnedEventId) || null
    }

    eventWithId(id: string): Event | null {
        return this.events.find(e => e.id === id) || null
    }

    addEvent(event: Event) {
        if (this.eventWithId(event.id) !== null) {
            return
        }
        this.events.push(event)
    }

    updateEvent(event: Event) {
        const index = this.events.findIndex(e => e.id === event.id)
        if (index === -1) {
            return
        }
        this.events[index] = event
    }

    removeEvent(event: Event) {
        const index = this.events.findIndex(e => e.id === event.id)
        if (index === -1) {
            return
        }
        this.events.splice(index, 1)
    }

    addQuestion(question: Question) {
        if (this.questionWithId(question.id) !== null) {
            return
        }
        this.questions.push(question)
    }

    updateQuestion(question: Question) {
        const index = this.questions.findIndex(q => q.id === question.id)
        if (index === -1) {
            return
        }
        this.questions[index] = question
    }

    removeQuestion(question: Question) {
        const index = this.questions.findIndex(q => q.id === question.id)
        if (index === -1) {
            return
        }
        this.questions.splice(index, 1)
    }

    questionWithId(id: string): Question | null {
        return this.questions.find(q => q.id === id) || null
    }

    get hasMerchantQuestions(): boolean {
        return this.questions
            .filter(question => sourceTypeOfQuestionType(question.type) === 'merchant')
            .length > 0
    }

    get hasSystemDefinedQuestions(): boolean {
        return this.questions
            .filter(question => sourceTypeOfQuestionType(question.type) === 'system-defined')
            .length > 0
    }

    tablesUsingReason(reason: BookingReason): Table[] {
        return this.areas
            .map(area => area.tablesReservableForReasonOrNone(reason))
            .flat()
    }

    reservableTablesUsingNoReason(): Table[] {
        if (this.reasons.length === 0) {
            return []
        }
        return this.areas
            .map(area => area.tablesReservableForReasonOrNone(null))
            .flat()
    }

    tablesAssignedToEvent(event: Event): Table[] {
        return this.areas
            .map(area => area.tables)
            .flat()
            .filter(table => event.tableIds.some(ids => ids.includes(table.id)))
    }

    reasonsThatQuestionIsLimitedTo(question: Question): BookingReason[] {
        return this.reasons.filter(reason => question.reasonIds.includes(reason.id))
    }

    questionsAskedForEvent(event: Event): Question[] {
        return this.questions.filter(question => event.questionIds.includes(question.id))
    }

    reasonsReservableOnDate(date: Date): BookingReason[] {
        return this.reasons.filter(reason => {
            return this.areas.some(area => {
                return area.tablesReservableForReasonOrNoneOnDate(reason, date).length > 0
            })
        })
    }

    reasonsReservableInAreaOnDate(area: Area, dateTime: Date): BookingReason[] {
        return this.reasons.filter(reason => {
            return area.tablesReservableForReasonOrNoneOnDate(reason, dateTime).length > 0
        })
    }

    eventsReservableOnDate(date: Date): Event[] {
        return this.events.filter(event => {
            return event.recurrence.appliesToDate(date)
        })
    }

    cancellationChargeForPartySize(eventId: string | null, partySize: number): Charge | null {
        if (eventId !== null) {
            const event = this.eventWithId(eventId)
            if (event === null) {
                return null
            }
            return event.cancellationChargeForPartySize(partySize)
        }
        if (this.depositChargeForPartySize(eventId, partySize) !== null) {
            return null
        }
        const smallPartyCharge = this.doesCancellationChargeMaxContainsPartySize(partySize)
        if (smallPartyCharge !== null) {
            return smallPartyCharge
        }
        const largePartyPartyCharge = this.doesCancellationChargePartyMinContainsPartySize(partySize)
        if (largePartyPartyCharge !== null) {
            return largePartyPartyCharge
        }
        const largePartyCharge = this.doesCancellationChargeMinContainsPartySize(partySize)
        if (largePartyCharge !== null) {
            return largePartyCharge
        }
        return null
    }

    depositChargeForPartySize(eventId: string | null, partySize: number): Charge | null {
        if (eventId !== null) {
            const event = this.eventWithId(eventId)
            if (event === null) {
                return null
            }
            return event.depositChargeForPartySize(partySize)
        }
        if (this.depositAmount === null) {
            return null
        }
        if (this.depositMinPartySize === null) {
            return null
        }
        if (partySize < this.depositMinPartySize) {
            return null
        }
        return new Charge(
            partySize,
            this.depositMinPartySize,
            null,
            this.depositAmount,
            true
        )
    }

    doPaymentMechanismsOverlap(): boolean {
        if (this.depositMinPartySize === null) {
            return false
        }
        if (this.cancellationChargeMaxPartySize !== null) {
            if (this.cancellationChargeMaxPartySize >= this.depositMinPartySize) {
                return true
            }
        }
        if (this.cancellationChargeMinPartySize === null) {
            return false
        }
        return true
    }

    bookingSlotClosestToDate(
        date: Date,
        startDate: Date,
        endDate: Date,
        dateIntervalsFactory: (startDate: Date, endDate: Date, interval: number) => Date[]
    ): BookingSlot | null {
        const bookingSlots = this.bookingSlotsBetweenDates(
            startDate,
            endDate,
            dateIntervalsFactory
        )
        const closestBookingSlot = bookingSlots.find(bookingSlot => {
            return Helper.isBetweenDate(
                date,
                bookingSlot.dateTime,
                bookingSlot.endDateTime(this.bookingInterval)
            )
        })
        if (!closestBookingSlot) {
            return null
        }
        return closestBookingSlot
    }

    bookingSlotsBetweenDates(
        startDate: Date,
        endDate: Date,
        dateIntervalsFactory: (startDate: Date, endDate: Date, interval: number) => Date[]
    ): BookingSlot[] {
        const intervalDates = dateIntervalsFactory(
            startDate,
            endDate,
            this.bookingInterval
        )
        return intervalDates.map(intervalDate => new BookingSlot(intervalDate))
    }

    hasDepositRefundCutOffPassed(booking: Booking): boolean {
        const cutOffDate = this.depositRefundCutOffDate(booking)
        if (cutOffDate === null) {
            return true
        }
        const now = new Date()
        return now.getTime() > cutOffDate.getTime()
    }

    depositRefundCutOffDate(booking: Booking): Date | null {
        let cutOffDays = this.depositRefundCutOffDaysForBooking(booking)
        if (cutOffDays === null) {
            return null
        }
        const bookingStart = new Date(booking.start)
        return new Date(
            bookingStart.getTime() - (cutOffDays * 24 * 60 * 60 * 1000)
        )
    }

    isSendFeedbackRequestEnabled(): boolean {
        return this.communicationPreferences.sendFeedbackRequest
    }

    tableGroupsInClosure(closure: VenueClosure): Table[][] {
        if (closure.appliesToEntireVenue) {
            return [[]]
        }
        return this.contiguouslyDisplayedTablesInList(closure.tableIds)
    }

    closestEdgeToEndBookingAt(now: Date, bookingSlot: BookingSlot, booking: Booking) {
        const bookingSlotEdge = bookingSlot.closestEdgeToDateTime(now, this.bookingInterval)
        const newDuration = Helper.minutesBetweenDates(booking.start, bookingSlotEdge)
        if (newDuration === undefined || newDuration <= 0) {
            return bookingSlot.endDateTime(this.bookingInterval)
        }
        return bookingSlotEdge
    }

    removeBrandBannerImage() {
        this.brand.bannerImageId = null
    }

    private doesCancellationChargeMinContainsPartySize(partySize: number): Charge | null {
        if (this.cancellationChargeAmount === null) {
            return null
        }
        if (this.cancellationChargeMinPartySize === null) {
            return null
        }
        let largestApplicablePartySize = Number.MAX_SAFE_INTEGER
        if (this.cancellationChargePartyMinPartySize !== null) {
            largestApplicablePartySize = this.cancellationChargePartyMinPartySize - 1
        }
        if (!Helper.isIntegerInRange(
            partySize,
            this.cancellationChargeMinPartySize,
            largestApplicablePartySize
        )) {
            return null
        }
        return new Charge(
            partySize,
            this.cancellationChargeMinPartySize,
            largestApplicablePartySize === Number.MAX_SAFE_INTEGER ? null : largestApplicablePartySize,
            this.cancellationChargeAmount,
            true
        )
    }

    private doesCancellationChargeMaxContainsPartySize(partySize: number): Charge | null {
        if (this.cancellationChargeAmount === null) {
            return null
        }
        if (this.cancellationChargeMaxPartySize === null) {
            return null
        }
        if (!Helper.isIntegerInRange(
            partySize,
            0,
            this.cancellationChargeMaxPartySize
        )) {
            return null
        }
        return new Charge(
            partySize,
            null,
            this.cancellationChargeMaxPartySize,
            this.cancellationChargeAmount,
            true
        )
    }

    private doesCancellationChargePartyMinContainsPartySize(partySize: number): Charge | null {
        if (this.cancellationChargePartyAmount === null) {
            return null
        }
        if (this.cancellationChargePartyMinPartySize === null) {
            return null
        }
        if (!Helper.isIntegerInRange(
            partySize,
            this.cancellationChargePartyMinPartySize,
            Number.MAX_SAFE_INTEGER
        )) {
            return null
        }
        return new Charge(
            partySize,
            this.cancellationChargePartyMinPartySize,
            null,
            this.cancellationChargePartyAmount,
            false
        )
    }

    private depositRefundCutOffDaysForBooking(booking: Booking): number | null {
        if (booking.eventId === null) {
            return this.depositRefundCutOffDays
        }
        const event = this.eventWithId(booking.eventId)
        return event?.depositRefundCutOffDays || null
    }

    private contiguouslyDisplayedTablesInList(tableIds: string[]): Table[][] {
        return this.areasSortedByDisplayOrder
            .map(area => {
                return area.contiguouslyDisplayedTablesInList(tableIds)
            })
            .filter(tables => tables.length > 0)
            .flat()
    }
}
