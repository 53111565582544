import { BookingSource } from '../../../../domain/BookingSource'
import { Component, Input } from '@angular/core'
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-booking-source-badge',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgOptimizedImage,
    ],
    templateUrl: './booking-source-badge.component.html',
    styleUrl: './booking-source-badge.component.sass',
})
export class BookingSourceBadgeComponent {

    @Input() source!: BookingSource
    @Input() size: 'small' | 'medium' = 'medium'
    protected readonly BookingSource = BookingSource
}
