import { CancellationCharge } from '@app/domain/CancellationCharge'
import { Charge } from './Charge'

export class Cancellation {
    constructor(
        public id: string,
        public paymentMethodId: string | null,
        public unitAmount: number,
        public currencyCode: string,
        public isPerCover: boolean,
        public partySize: number,
        public cutOffHours: number | null,
        public chargeAutomatically: boolean,
        public charges: CancellationCharge[],
        public dateCreated: Date
    ) { }

    charge(): Charge {
        return new Charge(
            this.partySize,
            null,
            null,
            this.unitAmount,
            this.isPerCover
        )
    }

    addCharge(charge: CancellationCharge) {
        this.charges.push(charge)
    }

    amountCharged(): number {
        return this.charges.reduce((total, charge) => {
            return total + charge.charge().amount
        }, 0)
    }

    amountRemaining(): number {
        const originalCharge = new Charge(
            this.partySize,
            null,
            null,
            this.unitAmount,
            this.isPerCover
        )
        const amountCharged = this.amountCharged()
        return originalCharge.amount - amountCharged
    }
}
