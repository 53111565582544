<app-loading
    class="flex-grow-1"
    [observable]="concurrentActiveTableCount"
>
    <ng-container
        *ngIf="{count: concurrentActiveTableCount | async } as count"
    >
        <div
            *ngIf="count.count !== null"
            class="alert alert-light d-flex justify-content-center"
        >
            <div class="vstack">
                <span class="fw-semibold">
                    Venue Activity
                </span>
                <span class="fw-normal">
                    {{ count.count | i18nPlural: concurrentTablesPluralMapping }} bookings at the same time.
                </span>
            </div>
        </div>
    </ng-container>
</app-loading>
