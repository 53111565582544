export class CustomerDTO {
    constructor(
        public id: string,
        public firstName: string | null,
        public lastName: string | null,
        public emailAddress: string | null,
        public phoneNumber: string | null,
        public notes: string[],
        public venueId: string
    ) { }
}
