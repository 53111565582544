import { BookingReason } from '@app/domain/BookingReason'
import {
    DateComponentDescriptionPipe,
} from '@app/features/shared/pipes/date-component-description.pipe'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'reasonSchedule',
})
export class ReasonSchedulePipe implements PipeTransform {

    constructor(
        private dateComponentsPipe: DateComponentDescriptionPipe
    ) { }

    transform(reason: BookingReason): string | null {
        return reason.schedule.map(rule => {
            let timeRange = 'All Day'
            if (rule.period !== null) {
                timeRange = `${rule.period.start} - ${rule.period.end}`
            }
            const day = this.dateComponentsPipe.transform(rule.daysOfWeek)
            return `${timeRange} ${day}`
        })
            .join('\n')
    }
}
