import { ValidatorFn, Validators } from '@angular/forms'

export interface ContactDetailsViewModelDelegate {
    updateName(firstName: string | null, lastName: string | null): void
    updateEmailAddress(emailAddress: string | null ): void
    updatePhoneNumber(phoneNumber: string | null ): void
    updateSendFeedbackRequest(isFeedbackRequested: boolean): void
}

export class ContactDetailsViewModel {

    customerNameValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
    ]
    emailAddressValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
        Validators.email,
    ]
    phoneNumberValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
    ]
    isEditingBooking: boolean = false

    constructor(
        private delegate: ContactDetailsViewModelDelegate,
        public shouldSendFeedbackRequest: boolean
    ) { }

    updateName(customerName: string | null) {
        let firstName: string | null = customerName?.split(' ')[0]?.trim() ?? null
        let lastName: string | null = firstName ?
            (customerName?.replace(firstName, '').trim() ?? null) : null
        this.delegate.updateName(firstName, lastName)
    }

    updateEmailAddress(emailAddress: string | null) {
        this.delegate.updateEmailAddress(emailAddress)
    }

    updatePhoneNumber(phoneNumber: string | null) {
        this.delegate.updatePhoneNumber(phoneNumber)
    }

    onChangeSendFeedbackRequest() {
        this.shouldSendFeedbackRequest = !this.shouldSendFeedbackRequest
        this.delegate.updateSendFeedbackRequest(this.shouldSendFeedbackRequest)
    }
}
