export class Customer {
    constructor(
        public id: string,
        public firstName: string | null,
        public lastName: string | null,
        public emailAddress: string | null,
        public phoneNumber: string | null,
        public notes: string[],
        public venueId: string
    ) { }

    name(): string {
        const nameParts = [this.firstName, this.lastName]
        return nameParts.join(' ').trim()
    }

    updatePhoneNumber(phoneNumber: string | null) {
        this.phoneNumber = phoneNumber
    }

    updateEmailAddress(emailAddress: string | null) {
        this.emailAddress = emailAddress
    }

    updateFirstName(firstName: string | null) {
        this.firstName = firstName
    }

    updateLastName(lastName: string | null) {
        this.lastName = lastName
    }

    note(): string | null {
        return this.notes.length > 0 ? this.notes[0] : null
    }

    updateNote(note: string | null) {
        if (note) {
            this.notes = [note]
        } else {
            this.notes = []
        }
    }
}
