import { BookingSource } from '@app/domain/BookingSource'
import { Contact } from '@app/domain/Contact'

export class CustomerListExportData {
    public bookingId: string
    public contact: Contact
    public bookingSize: number
    public bookingStartTime: Date
    public agreedToMarketing: boolean
    public source: BookingSource

    constructor(
        bookingId: string,
        contact: Contact,
        bookingSize: number,
        bookingStartTime: Date,
        agreedToMarketing: boolean,
        source: BookingSource
    ) {
        this.bookingId = bookingId
        this.contact = contact
        this.bookingSize = bookingSize
        this.bookingStartTime = bookingStartTime
        this.agreedToMarketing = agreedToMarketing
        this.source = source
    }
}
