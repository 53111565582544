import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'numberRange',
})
export class NumberRangePipe implements PipeTransform {

    constructor(
        @Inject(LOCALE_ID) public locale: string
    ) { }

    transform(end: number, start: number): number[] {
        return this.range(start, end+start)
    }


    range(start: number, end: number){
        return Array.from({ length: (end - start) }, (v, k) => k + start)
    }
}
