import { DashboardEvent, EventType } from '@app/domain/Event'
import { Injectable } from '@angular/core'
import { Subject, filter } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class EventService {

    private events = new Subject<DashboardEvent>()
    public events$ = this.events.asObservable()

    constructor() { }

    public publishEvent(event: DashboardEvent) {
        this.events.next(event)
    }

    eventsOfType(types: EventType[]) {
        return this.events$.pipe(
            filter(event => types.includes(event.type))
        )
    }
}
