import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'

@Directive({
    selector: '[appCapitaliseOnBlur]',
    standalone: true,
})
export class CapitaliseOnBlurDirective {

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @HostListener('blur') onBlur() {
        const inputValue: string = this.el.nativeElement.value
        const capitalizedValue = this.capitalizeWords(inputValue)
        this.renderer.setProperty(this.el.nativeElement, 'value', capitalizedValue)
        this.el.nativeElement.dispatchEvent(new Event('input'))
    }

    private capitalizeWords(str: string): string {
        return str.replace(/\b\w+/g, (word: string) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        })
    }
}
