import {
    AfterViewInit,
    Directive,
    ElementRef, Input,
    NgZone,
    OnDestroy,
    Output,
} from '@angular/core'
import { CursorMovement } from '@app/features/shared/directives/cursor-movement'
import { Subject, fromEvent, takeUntil, throttleTime } from 'rxjs'

@Directive({
    selector: '[appSvgCursorMovement]',
})
export class SvgCursorMovementDirective implements AfterViewInit, OnDestroy {

    @Input() movementThrottleInterval = 100
    @Output() cursorChanged = new Subject<CursorMovement>()
    private onDestroy = new Subject<void>()
    constructor(
        private svg: ElementRef,
        private zone: NgZone
    ) { }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            fromEvent<MouseEvent>(this.svg.nativeElement, 'mousemove', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => this.onMouseMove(event))
            fromEvent<MouseEvent>(this.svg.nativeElement, 'mousedown', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => this.onMouseDown(event))
            fromEvent<MouseEvent>(this.svg.nativeElement, 'mouseup', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => this.onMouseUp(event))
            fromEvent<MouseEvent>(this.svg.nativeElement, 'mouseout', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => this.onMouseOut(event))
            fromEvent<TouchEvent>(this.svg.nativeElement, 'touchmove', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => this.onTouchMove(event))
            fromEvent<TouchEvent>(this.svg.nativeElement, 'touchstart', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => {
                    this.onTouchStart(event)
                })
            fromEvent<TouchEvent>(this.svg.nativeElement, 'touchend', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => this.onTouchEnd(event))
            fromEvent<TouchEvent>(this.svg.nativeElement, 'touchcancel', { passive: false })
                .pipe(
                    takeUntil(this.onDestroy),
                    throttleTime(this.movementThrottleInterval)
                )
                .subscribe(event => this.onTouchCancel(event))
        })
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    onMouseDown(event: MouseEvent) {
        const [x, y] = this.translateToPosition(event.clientX, event.clientY)
        this.cursorChanged.next({
            event: 'down',
            x,
            y,
        })
    }

    onTouchStart(event: TouchEvent) {
        const touch = event.touches[0]
        const [x, y] = this.translateToPosition(touch.clientX, touch.clientY)
        this.cursorChanged.next({
            event: 'down',
            x,
            y,
        })
    }

    onMouseMove(event: MouseEvent) {
        const [x, y] = this.translateToPosition(event.clientX, event.clientY)
        this.cursorChanged.next({
            event: 'move',
            x,
            y,
        })
    }

    onTouchMove(event: TouchEvent) {
        const touch = event.touches[0]
        const [x, y] = this.translateToPosition(touch.clientX, touch.clientY)
        this.cursorChanged.next({
            event: 'move',
            x,
            y,
        })
    }

    onMouseUp(event: MouseEvent) {
        const [x, y] = this.translateToPosition(event.clientX, event.clientY)
        this.cursorChanged.next({
            event: 'up',
            x,
            y,
        })
    }

    onTouchEnd(event: TouchEvent) {
        const touch = event.changedTouches[0]
        const [x, y] = this.translateToPosition(touch.clientX, touch.clientY)
        this.cursorChanged.next({
            event: 'up',
            x: x,
            y: y,
        })
    }

    onMouseOut(_: MouseEvent) {
        this.cursorChanged.next({
            event: 'out',
            x: 0,
            y: 0,
        })
    }

    onTouchCancel(_: TouchEvent) {
        this.cursorChanged.next({
            event: 'out',
            x: 0,
            y: 0,
        })
    }

    private translateToPosition(x: number, y: number) {
        const element = this.svg.nativeElement
        if (!(element instanceof SVGSVGElement)) {
            const rect = element.getBoundingClientRect()
            return [x - rect.left, y - rect.top]
        }
        const point = element.createSVGPoint()
        point.x = x
        point.y = y
        const screenPoint = point.matrixTransform(element.getScreenCTM()?.inverse())
        return [screenPoint.x, screenPoint.y]
    }
}
