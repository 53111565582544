export class ReasonDateRangeFilter {
    constructor(
        public id: string,
        public startDate: Date,
        public endDate: Date
    ) { }

    appliesToDate(date: Date): boolean {
        if (date < this.startDate) {
            return false
        }
        if (date > this.endDate) {
            return false
        }
        return true
    }
}
