<span
    appBookingStatus
    class="position-relative"
    data-testid="booking-status-badge"
    [statusType]="statusType"
>
    <ng-content></ng-content>
    <div
        *ngIf="showNotificationIndicator"
        class="position-absolute d-none d-lg-flex"
        style="right: -8px; top: -8px;"
    >
        <div
            class="rounded bg-warning top-0"
            [style.width.px]="20"
            [style.height.px]="20"
        >
            <img
                ngSrc="assets/credit-card/normal_white.svg"
                width="12"
                height="12"
                alt="Notification"
            />
        </div>
    </div>
</span>
