export const PATHS = {
    authCallback: 'auth-callback',
    activity: 'activity',
    bookings: 'bookings',
    diary: 'bookings/diary',
    inbox: 'bookings/inbox',
    management: 'management',
    profile: 'profile',
    reports: 'reports',
}
