import { Bookable } from '@app/domain/Bookable'
import { Table } from '@app/domain/Table'

export class TableCombination implements Bookable {

    constructor(
        public id: string,
        public tableIds: string[],
        public minimumSeats: number,
        public maximumSeats: number,
        public bookingOrder: number,
        public independentReservable: boolean
    ) { }

    get tableBookingOrder(): number | null {
        return null
    }

    get combinationBookingOrder(): number | null {
        return this.bookingOrder
    }

    fitsPartySize(partySize: number): boolean {
        return partySize >= this.minimumSeats && partySize <= this.maximumSeats
    }

    doesNotExceedPartySize(partySize: number): boolean {
        return partySize <= this.maximumSeats
    }

    isFormedFromTables(tables: Table[]): boolean {
        if (tables.length !== this.tableIds.length) {
            return false
        }
        return this.tableIds.every(tableId => tables.some(table => table.id === tableId))
    }

    isReservableForReasonWithId(reasonId: string): boolean {
        return true
    }
}
