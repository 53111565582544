import { AddressDTO } from '@services/DTO/AddressDTO'
import { PaymentsAccountDTO } from '@services/DTO/PaymentsAccountDTO'
import { VenueDTO } from '@services/DTO/VenueDTO'
export class BusinessDTO {

    constructor(
        public id: string,
        public displayName: string,
        public phoneNumber: string,
        public address: AddressDTO,
        public paymentsAccount: PaymentsAccountDTO | null,
        public venues: VenueDTO[]
    ) { }
}
