<div
    class="d-print-none p-2 px-2 px-lg-4 info-banner"
    [ngClass]="colourClass"
>
    <div class="mx-2 hstack gap-2 justify-content-between align-items-center">
        <div class="hstack gap-2">
            <img
                *ngIf="type === 'warning'"
                src="assets/warning/orange.svg"
                [ngbTooltip]="tooltipText"
            >
            <span>
                {{ text }}
            </span>
        </div>
        <button
            type="button"
            class="btn btn-outline-primary btn-xs"
            (click)="buttonClicked.emit()"
        >
            {{ buttonTitle }}
        </button>
    </div>
</div>
