import { Booking } from '@app/domain/Booking'
import { BookingAuditEntry } from '@app/domain/BookingAuditEntry'
import { BookingAuditService } from '@services/booking-audit.service'
import { BookingStatusType } from '@app/domain/BookingStatus'
import { Component, Input, OnInit } from '@angular/core'
import {
    DatePipe,
    I18nPluralPipe,
    NgForOf,
    NgIf,
    NgOptimizedImage,
    NgTemplateOutlet,
} from '@angular/common'
import { finalize, Subject, takeUntil } from 'rxjs'
import { Venue } from '@app/domain/Venue'
import { TableNamePipe } from '@app/features/shared/pipes/table-name.pipe'
import { BookingHistoryFactory } from '@app/domain/BookingHistoryFactory'
import { Organisation } from '@app/domain/Organisation'
import {
    BookingSourceBadgeComponent
} from '@app/features/shared/components/booking-source-badge/booking-source-badge.component'
import { BookingSource } from '@app/domain/BookingSource'
import { CurrencyUnitPipe } from '@app/features/shared/pipes/currency-unit.pipe'

@Component({
    selector: 'app-booking-history',
    standalone: true,
    imports: [
        DatePipe,
        I18nPluralPipe,
        NgForOf,
        NgIf,
        NgOptimizedImage,
        NgTemplateOutlet,
        BookingSourceBadgeComponent,
    ],
    templateUrl: './booking-history.component.html',
})
export class BookingHistoryComponent implements OnInit {

    @Input() organisation!: Organisation
    @Input() venue!: Venue
    @Input() booking!: Booking
    auditLog: BookingAuditEntry[] = []
    history: {
        title: string,
        subtitle?: string,
        date: Date,
        performedBy?: 'customer' | 'merchant',
    }[] = []
    isLoading = false
    private onDestroy$ = new Subject<void>()

    constructor(
        private bookingAuditService: BookingAuditService,
        private currencyUnitPipe: CurrencyUnitPipe,
        private tableNamePipe: TableNamePipe,
    ) { }

    ngOnInit() {
        if (this.auditLog.length === 0) {
            this.isLoading = true
            this.bookingAuditService.getAuditLogForBooking(this.organisation, this.booking)
                .pipe(
                    takeUntil(this.onDestroy$),
                    finalize(() => this.isLoading = false)
                )
                .subscribe({
                    next: (auditLog) => {
                        this.auditLog = auditLog
                        const factory = new BookingHistoryFactory(
                            this.booking,
                            this.auditLog,
                            this.currencyUnitPipe,
                            this.tableNamePipe,
                            this.venue
                        )
                        this.history = factory.make()
                    },
                    error: (error) => console.error(error),
                })
        }
    }

    protected readonly BookingStatusType = BookingStatusType
    protected readonly BookingSource = BookingSource
}
