import { DateComponentDTO } from './DateComponentDTO'
import { PartySizeDurationDTO } from '@services/DTO/PartySizeDurationDTO'
import { PeriodDTO } from './PeriodDTO'

export class ScheduleRuleDTO {

    constructor(
        public id: string,
        public dateComponents: DateComponentDTO[],
        public period: PeriodDTO,
        public bookingDurations: PartySizeDurationDTO[],
        public paymentsEnabled: boolean,
        public preBookingWindowMinutes: number | null,
        public coverLimit: number | null,
        public manualAcceptance: boolean
    ) { }
}
