import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
})
export class ListItemComponent implements OnInit {

    @Input() title!: string
    @Input() subtitle!: string
    @Input() subtitleIcon?: string
    @Input() description?: string
    @Input() descriptionIcon?: string

    constructor() { }

    ngOnInit() { }
}
