<div
    class=""
>
    <a
        role="button"
        (click)="logoClicked()"
    >
        <img
            *ngIf="!compact"
            src="assets/logo/logotype.svg"
        >
        <img
            *ngIf="compact"
            ngSrc="assets/logo/logo.svg"
            width="26"
            height="26"
        >
    </a>
</div>
