import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-page-content',
    templateUrl: './page-content.component.html',
    styleUrls: ['./page-content.component.sass'],
})
export class PageContentComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}
