<app-spinner-overlay
    [showSpinner]="isSaving"
>
</app-spinner-overlay>
<div class="modal-header">
    <h4 class="modal-title">
        Edit Booking Start Time
    </h4>
    <button
        type="button"
        class="btn-close"
        (click)="cancelClicked()"
    >
    </button>
</div>
<div class="modal-body">
    <div
        class="form-group vstack gap-3"
        [formGroup]="form"
    >
        <div class="vstack gap-2">
            <div
                class="px-3 py-2 rounded bg-light hstack gap-1 align-items-baseline"
            >
                <label>
                    Date
                </label>
                <div
                    class="flex-grow-1 text-end fw-semibold text-primary"
                    appFadeOnChange
                    [fadeOnChangeValue]="selectedOption.date | ngbDate | date: 'shortDate':undefined:'en-GB'"
                >
                </div>
            </div>
            <div class="px-3 py-2 rounded bg-light hstack gap-1 align-items-baseline">
                <label>
                    Start Time
                </label>
                <div
                    class="ms-auto fw-semibold text-primary"
                    appFadeOnChange
                    [fadeOnChangeValue]="selectedOption.start | ngbTime | date: 'shortTime'"
                >
                </div>
            </div>
            <div class="px-3 py-2 rounded bg-light hstack gap-1 align-items-baseline">
                <label>
                    Table
                </label>
                <div
                    class="ms-auto fw-semibold text-primary"
                    appFadeOnChange
                    [fadeOnChangeValue]="selectedOption.tableNames"
                >
                </div>
            </div>
        </div>
        <app-day-picker
            (daySelected)="dateSelected($event)"
        >
        </app-day-picker>
        <div
            class="border border-1 border-light-subtle rounded overflow-auto"
            style="height: 350px"
        >
            <ng-container
                *ngIf="isLoadingOptions"
                [ngTemplateOutlet]="loadingOptions"
            >
            </ng-container>
            <h4
                *ngIf="!isLoadingOptions && options.length === 0"
                class="d-flex h-100 justify-content-center align-items-center"
            >
                Area is closed
            </h4>
            <div
                *ngIf="!isLoadingOptions && options.length > 0"
                class="d-grid three-column-grid-sm-two-column-grid p-2"
            >
                <label
                    *ngFor="let option of options"
                    class="btn btn-outline-secondary px-2 py-1 d-flex flex-column justify-content-center align-items-center"
                    [class.disabled]="!option.possibleTables.length"
                    (click)="form.patchValue({
                        selectedOptionId: option.id,
                    })"
                >
                    <div class="m-1 hstack gap-2">
                        <input
                            type="radio"
                            formControlName="selectedOptionId"
                            [value]="option.id"
                            [id]="option.id"
                            class="form-check-input m-0"
                            role="button"
                        >
                        <div class="vstack align-items-start">
                            <span class="fw-semibold">
                                {{ option.start | ngbTime | date: 'shortTime' }}
                            </span>
                            <span
                                *ngIf="option.possibleTables.length === 0"
                            >
                                Unavailable
                            </span>
                            <span
                                *ngIf="option.possibleTables.length > 0"
                            >
                                {{ option.concurrentCoverCount | i18nPlural: coversPluralMapping }}
                            </span>
                        </div>
                    </div>
                </label>
            </div>
            <ng-template #loadingOptions>
                <div class="d-flex h-100 justify-content-center align-items-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="cancelClicked()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        type="submit"
        ngbAutofocus
        [disabled]="form.invalid"
        (click)="saveClicked()"
    >
        Save
    </button>
</div>
