import { Table } from '@app/domain/Table'

export class VenueClosure {

    constructor(
        public id: string,
        public start: Date,
        public end: Date,
        public tableIds: string[],
        public reasonIds: string[],
        public closes: boolean
    ) { }

    get appliesToEntireVenue() {
        return this.tableIds.length === 0
    }

    occursBetweenDatesOnTable(start: Date, end: Date, table: Table) {
        const occursDuringTimePeriod = !(this.end <= start || this.start >= end)
        const occursOnTable = this.appliesToEntireVenue || this.tableIds.includes(table.id)
        return occursDuringTimePeriod && occursOnTable
    }
}
