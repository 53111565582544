import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingService } from '@services/booking.service'
import { BookingSlot } from '@app/domain/BookingSlot'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ContextService } from '@services/context.service'
import { DayPickerComponent } from '@app/features/shared/components/day-picker/day-picker.component'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Helper } from '@app/helpers/utilities/helper'
import { NGBDateTimeAdapter } from '@app/helpers/utilities/NgbDateTimeAdapter'
import { NgbActiveModal, NgbDateNativeAdapter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct'
import { Subject, finalize, map, takeUntil } from 'rxjs'
import { Table } from '@app/domain/Table'
import { Venue } from '@app/domain/Venue'
import { activeBookingStatusTypes } from '@app/domain/BookingStatus'
import { startWith } from 'rxjs/operators'

class BookingSlotOption {
    constructor(
        public date: NgbDateStruct,
        public start: NgbTimeStruct,
        public possibleTables: Table[][],
        public concurrentCoverCount: number
    ) { }

    get tableNames() {
        return this.possibleTables[0]
            .map(table => table.shortFormattedDisplayName)
            .join(', ')
    }

    get id() {
        return this.date.year +
            '-' + this.date.month +
            '-' + this.date.day +
            '-' + this.start.hour +
            '-' + this.start.minute
    }
}

@Component({
    selector: 'app-booking-start-edit',
    templateUrl: './booking-start-edit.component.html',
})
export class BookingStartEditComponent implements OnInit, OnDestroy {

    venue!: Venue
    booking!: Booking
    form!: FormGroup
    options: BookingSlotOption[] = []
    selectedOption!: BookingSlotOption
    isLoadingOptions = false
    isSaving = false
    coversPluralMapping = {
        '=0': '0 covers',
        '=1': '1 cover',
        other: '# covers',
    }
    @ViewChild(DayPickerComponent, { static: true }) dayPicker!: DayPickerComponent
    private ngbTimeAdapter = new NGBDateTimeAdapter()
    private ngbDateAdapter = new NgbDateNativeAdapter()
    private onDestroy$ = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private contextService: ContextService,
        private formBuilder: FormBuilder,
        private modal: NgbActiveModal,
        private modalService: NgbModal
    ) { }

    ngOnInit() {
        this.dayPicker?.setDate(this.booking.start)
        this.makeForm()
        this.bindSelectedOptionIdToSelectedOption()
        this.dateSelected(this.booking.start)
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    saveClicked() {
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            return
        }
        const business = this.contextService.getBusiness()
        if (!business) {
            return
        }
        const venue = this.contextService.getVenue()
        if (!venue) {
            return
        }
        const selectedOption = this.options.find(option => {
            return option.id === this.form.value.selectedOptionId
        })
        if (!selectedOption) {
            return
        }
        if (selectedOption.possibleTables.length === 0) {
            return
        }
        const start = new Date(
            selectedOption.date.year,
            selectedOption.date.month - 1,
            selectedOption.date.day,
            selectedOption.start.hour,
            selectedOption.start.minute,
            selectedOption.start.second
        )
        const tableIds = selectedOption.possibleTables[0]
            .map(table => table.id)
        this.isSaving = true
        this.bookingService.changeBookingDate(
            organisation.id,
            business.id,
            venue.id,
            this.booking.id,
            start,
            undefined,
            tableIds
        )
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => {
                    this.isSaving = false
                })
            )
            .subscribe((booking) => {
                this.modal.close(booking)
            })
    }

    cancelClicked() {
        this.modal.dismiss()
    }

    dateSelected(date: Date) {
        this.isLoadingOptions = true
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            return
        }
        const business = this.contextService.getBusiness()
        if (!business) {
            return
        }
        const venue = this.contextService.getVenue()
        if (!venue) {
            return
        }
        this.bookingService.getBookings(
            business.id,
            venue.id,
            Helper.startOfDay(date),
            Helper.endOfDay(date),
            activeBookingStatusTypes()
        )
            .pipe(
                takeUntil(this.onDestroy$),
                map((bookings) => {
                    return this.makeOptions(bookings, date)
                }),
                finalize(() => {
                    this.isLoadingOptions = false
                })
            )
            .subscribe(options => {
                this.options = options
            })
    }

    private makeForm() {
        const bookingTables = this.booking.tableIds.map(tableId => {
            return this.venue.areaWithTableId(tableId)?.getTableWithId(tableId)
        })
            .filter(table => table !== null) as Table[]
        this.selectedOption = new BookingSlotOption(
            this.ngbDateAdapter.fromModel(this.booking.start)!,
            this.ngbTimeAdapter.fromModel(this.booking.start)!,
            [bookingTables],
            0
        )
        this.form = this.formBuilder.group({
            selectedOptionId: [this.selectedOption.id, [Validators.required]],
        })
    }

    private makeOptions(bookingsOnDate: Booking[], date: Date): BookingSlotOption[] {
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            return []
        }
        const venue = this.contextService.getVenue()
        if (!venue) {
            return []
        }
        const area = venue.areaBookingIsIn(this.booking)
        if (!area) {
            return []
        }
        return organisation.unoccupiedBookingSlotsForBookingInVenueOnDate(
            venue,
            this.booking,
            [],
            date
        )
            .map(slot => {
                return this.adaptBookingSlotToOption(area, slot, bookingsOnDate)
            })
    }

    private adaptBookingSlotToOption(area: Area, slot: BookingSlot, bookingsOnDate: Booking[]) {
        const tables = area.availableTables
        const end = new Date(slot.dateTime.getTime() + this.booking.duration * 60 * 1000)
        const otherBookings = bookingsOnDate
            .filter(booking => booking.id !== this.booking.id)
        const emptyTables = tables.filter(table => {
            return !otherBookings
                .some(booking => {
                    return booking.occupiesTableBetweenDates(slot.dateTime, end, [table])
                })
        })
            .map(table => [table])
        const combinationTables = area.combinations.map(combination => {
            return combination.tableIds
                .map(id => area.getTableWithId(id))
                .filter(table => table !== null) as Table[]
        }) as Table[][]
        const emptyCombinations = combinationTables
            .filter(tables => {
                return !otherBookings
                    .some(booking => {
                        return booking.occupiesTableBetweenDates(slot.dateTime, end, tables)
                    })
            })
        const coversStartingDuringSlot = otherBookings
            .filter(booking => {
                return booking.start.getTime() === slot.dateTime.getTime()
            })
            .map(booking => booking.size)
            .reduce((a, b) => a + b, 0)
        let currentlySelectedTablesAsAnOption: Table[][] = []
        if (this.selectedOption) {
            const canFitOnCurrentlySelectedTables = !otherBookings
                .some(booking => {
                    return booking.occupiesTableBetweenDates(slot.dateTime, end, this.selectedOption.possibleTables[0])
                })
            if (canFitOnCurrentlySelectedTables) {
                currentlySelectedTablesAsAnOption = this.selectedOption.possibleTables
            }
        }
        return new BookingSlotOption(
            this.ngbDateAdapter.fromModel(slot.dateTime) as NgbDateStruct,
            this.ngbTimeAdapter.fromModel(slot.dateTime) as NgbTimeStruct,
            [...currentlySelectedTablesAsAnOption, ...emptyTables, ...emptyCombinations],
            coversStartingDuringSlot
        )
    }

    private bindSelectedOptionIdToSelectedOption() {
        this.form.get('selectedOptionId')?.valueChanges
            .pipe(
                takeUntil(this.onDestroy$),
                startWith(this.form.value.selectedOptionId)
            )
            .subscribe(selectedOptionId => {
                const selectedOption = this.options
                    .find(option => option.id === selectedOptionId)
                if (!selectedOption) {
                    return
                }
                this.selectedOption = selectedOption
            })
    }
}
