import { Pipe, PipeTransform } from '@angular/core'
import { Venue } from '@app/domain/Venue'

@Pipe({
    name: 'areaName',
})
export class AreaNamePipe implements PipeTransform {

    transform(areaId?: string, venue?: Venue, tableId?: string): string | null {
        if (venue === undefined) {
            return null
        }
        if (areaId !== undefined) {
            const area = venue.areas.find(area => {
                return area.id === areaId
            })
            return area ? area.displayName : null
        }
        if (tableId !== undefined) {
            const area = venue.areaWithTableId(tableId)
            return area ? area.displayName : null
        }
        return null
    }
}
