@if (isLoading) {
    <div class="p-3 text-center">
        <div class="spinner-border text-primary"></div>
    </div>
} @else {
    <ul class="p-3 list-group-flush">
        <ng-template
            *ngFor="let history of history"
            [ngTemplateOutlet]="row"
            [ngTemplateOutletContext]="{
                title: history.title,
                subtitle: history.subtitle,
                date: history.date,
                performedBy: history.performedBy,
            }"
        >
        </ng-template>
    </ul>
}

<ng-template
    #row
    let-title="title"
    let-subtitle="subtitle"
    let-date="date"
    let-performedBy="performedBy"
>
    <li
        class="p-2 border-bottom list-group-item rounded-0"
    >
        <div class="py-1 hstack gap-2 justify-content-between">
            <div class="vstack">
                <span class="text-muted">
                    {{ title }}
                </span>
                @if (subtitle) {
                    <span class="fw-semibold">
                        {{ subtitle }}
                    </span>
                }
            </div>
            <ng-template
                [ngTemplateOutlet]="dateAndTime"
                [ngTemplateOutletContext]="{
                    date: date,
                    performedBy: performedBy,
                }"
            ></ng-template>
            </div>
    </li>
</ng-template>

<ng-template
    #dateAndTime
    let-date="date"
    let-performedBy="performedBy"
>
    <div class="vstack gap-1 align-items-end">
        @if (performedBy) {
            <app-booking-source-badge
                [source]="performedBy === 'customer' ? BookingSource.Customer : BookingSource.Merchant"
                size="small"
            >
            </app-booking-source-badge>
        }
        <div class="hstack gap-2 justify-content-end">
            <span class="fw-semibold">
                {{ date | date: 'shortDate' }}
            </span>
            <span class="text-muted">
                at
            </span>
            <span class="fw-semibold">
                {{ date | date: 'shortTime' }}
            </span>
        </div>
    </div>
</ng-template>
