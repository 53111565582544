import { AddressDTO } from '@services/DTO/AddressDTO'
import { AreaDTO } from '@services/DTO/AreaDTO'
import { BookingReasonDTO } from '@services/DTO/BookingReasonDTO'
import { BrandDTO } from '@services/DTO/BrandDTO'
import { CommunicationPreferencesDTO } from '@services/DTO/CommunicationPreferencesDTO'
import { DiaryPreferencesDTO } from '@services/DTO/DiaryPreferencesDTO'
import { EventDTO } from '@services/DTO/EventDTO'
import { QuestionDTO } from '@services/DTO/QuestionDTO'
import { VenueVelocityDTO } from '@services/DTO/VenueVelocityDTO'
import { WidgetConfigurationDTO } from '@services/DTO/WidgetConfigurationDTO'

export class VenueDTO {
    constructor(
        public id: string,
        public displayName: string,
        public phoneNumber: string,
        public address: AddressDTO,
        public bookingInterval: number,
        public minLargePartySize: number | null,
        public largePartyMessage: string | null,
        public noBookingSlotAvailableMessage: string | null,
        public shortPreBookingWindowMessage: string | null,
        public timeZone: string,
        public cancellationChargeMinPartySize: number | null,
        public cancellationChargeMaxPartySize: number | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargePartyMinPartySize: number | null,
        public cancellationChargePartyAmount: number | null,
        public cancellationChargeCutOffHours: number,
        public cancellationChargeAutomatically: boolean,
        public communicationPreferences: CommunicationPreferencesDTO,
        public depositMinPartySize: number | null,
        public depositAmount: number | null,
        public depositRefundCutOffDays: number | null,
        public diaryPreferences: DiaryPreferencesDTO,
        public velocity: VenueVelocityDTO,
        public widgetConfiguration: WidgetConfigurationDTO,
        public areas: AreaDTO[],
        public areaBookingOrder: string[],
        public manualAcceptanceUntilDate: string | null,
        public brand: BrandDTO,
        public reasons: BookingReasonDTO[],
        public questions: QuestionDTO[],
        public events: EventDTO[]
    ) { }
}
