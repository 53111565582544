import { BusinessDTO } from './BusinessDTO'
import { ScheduleDTO } from '@services/DTO/ScheduleDTO'

export class OrganisationDTO {

    constructor(
        public id: string,
        public displayName: string,
        public businesses: BusinessDTO[],
        public schedules: ScheduleDTO[]
    ) { }
}
