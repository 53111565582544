import { FormStyle, TranslationWidth, getLocaleDayNames } from '@angular/common'
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'dayName',
})
export class DayNamePipe implements PipeTransform {

    constructor(
        @Inject(LOCALE_ID) public locale: string
    ) { }

    transform(dayOfWeek: number, format: 'short' | 'long'): string {
        return this.makeDayOfWeekDescription(dayOfWeek, format)
    }

    private makeDayOfWeekDescription(dayOfWeek: number, format: 'short' | 'long'): string {
        const ISOFirstDayIndex = 1
        const width = format === 'short' ? TranslationWidth.Abbreviated : TranslationWidth.Wide
        const dayNames = getLocaleDayNames(
            this.locale,
            FormStyle.Standalone,
            width
        )
        const dayOfWeekAsArrayIndex = dayOfWeek - 1
        const localeDayOfWeekIndex = (ISOFirstDayIndex + dayOfWeekAsArrayIndex) % dayNames.length
        return dayNames[localeDayOfWeekIndex]
    }
}
