import { BehaviorSubject, Observable, map, mergeMap } from 'rxjs'
import { Business } from '@app/domain/Business'
import { Injectable } from '@angular/core'
import { LocalStorageService } from '@services/local-storage-service'
import { Organisation } from '@app/domain/Organisation'
import { User } from '@app/domain/User'
import { Venue } from '@app/domain/Venue'
import { filterNull } from '../features/shared/rjxs/filter.null'

const businessIdKey = 'businessId'
const venueIdKey = 'venueId'

@Injectable({
    providedIn: 'root',
})
export class ContextService {

    private organisation = new BehaviorSubject<Organisation | null>(null)
    private business = new BehaviorSubject<string | null>(null)
    private venue = new BehaviorSubject<string | null>(null)
    private user = new BehaviorSubject<User | null>(null)
    public organisation$: Observable<Organisation>
    public business$: Observable<Business>
    public venue$: Observable<Venue>
    public user$: Observable<User>

    constructor(
        private localStorageService: LocalStorageService
    ) {
        this.organisation$ = this.organisation.asObservable().pipe(filterNull())
        this.business$ = this.business.asObservable().pipe(
            filterNull(),
            mergeMap(businessId => this.organisation$.pipe(
                map(organisation => organisation.getBusiness(businessId))
            )),
            filterNull()
        )
        this.venue$ = this.venue.asObservable().pipe(
            filterNull(),
            mergeMap(venueId => this.business$.pipe(
                map(business => business.getVenue(venueId))
            )),
            filterNull()
        )
        this.user$ = this.user.asObservable().pipe(filterNull())
    }

    setUpObjectGraph(organisation: Organisation, user: User) {
        this.organisation.next(organisation)
        this.user.next(user)
        this.setUpInitialBusiness(organisation, user)
    }

    getOrganisation(): Organisation | null {
        return this.organisation.value
    }

    getBusiness(): Business | null {
        const businessId = this.business.value
        if (businessId === null) {
            return null
        }
        const organisation = this.getOrganisation()
        if (organisation === null) {
            return null
        }
        return organisation.getBusiness(businessId)
    }

    getVenue(): Venue | null {
        const venueId = this.venue.value
        if (venueId === null) {
            return null
        }
        const business = this.getBusiness()
        if (business === null) {
            return null
        }
        return business.getVenue(venueId)
    }

    getUser(): User | null {
        return this.user.value
    }

    setBusiness(business: Business) {
        this.localStorageService.setItem(businessIdKey, business.id)
        this.business.next(business.id)
    }

    setVenue(venue: Venue) {
        this.localStorageService.setItem(venueIdKey, venue.id)
        this.venue.next(venue.id)
    }

    private setUpInitialBusiness(organisation: Organisation, user: User) {
        const previousBusinessId = this.localStorageService.getItem(businessIdKey) as string | null
        if (previousBusinessId === null) {
            this.defaultToFirstBusiness(organisation, user)
            return
        }
        const business = organisation.getBusiness(previousBusinessId)
        if (business === null) {
            this.defaultToFirstBusiness(organisation, user)
            return
        }
        this.setBusiness(business)
        this.setUpInitialVenue(business, user)
    }

    private defaultToFirstBusiness(organisation: Organisation, user: User) {
        const firstBusiness = organisation.businesses[0]
        if (firstBusiness === undefined) {
            return
        }
        this.setBusiness(firstBusiness)
        this.setUpInitialVenue(firstBusiness, user)
    }

    private setUpInitialVenue(business: Business, user: User) {
        const previousVenueId = this.localStorageService.getItem(venueIdKey) as string | null
        if (previousVenueId === null) {
            this.defaultToFirstVenue(business, user)
            return
        }
        const venue = business.getVenue(previousVenueId)
        if (venue === null || !user.worksInVenue(venue)) {
            this.defaultToFirstVenue(business, user)
            return
        }
        this.setVenue(venue)
    }

    private defaultToFirstVenue(business: Business, user: User) {
        const firstVenue = business.venuesUserWorksIn(user)[0]
        if (firstVenue === undefined) {
            return
        }
        this.setVenue(firstVenue)
        return
    }
}
