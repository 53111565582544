export class Contact {
    constructor(
        public firstName: string | null,
        public lastName: string | null,
        public emailAddress: string | null,
        public phoneNumber: string | null
    ) { }

    get name(): string {
        const nameParts = [this.firstName, this.lastName]
        return nameParts.join(' ').trim()
    }

    updatePhoneNumber(phoneNumber: string | null) {
        this.phoneNumber = phoneNumber
    }

    updateEmailAddress(emailAddress: string | null) {
        this.emailAddress = emailAddress
    }

    updateFirstName(firstName: string | null) {
        this.firstName = firstName
    }

    updateLastName(lastName: string | null) {
        this.lastName = lastName
    }
}
