export const environment = {
    bookingConfirmationEmailTemplate: 'BookingConfirmationStaging',
    cdnUrl: 'https://d1ytssun2732e8.cloudfront.net',
    chargebeeKey: 'test_pDaqNB87jXuvnJpY8Z59fDIHVptx5yfW',
    chargebeeSite: 'tablesense-test',
    cognitoAuthUrl: 'https://auth.staging.tablesense.com',
    cognitoClientId: '6s12sbgqt29klt8nr2p2kdcfkr',
    cognitoScopes: 'email openid phone profile',
    newRelicApplicationId: '',
    production: false,
    apiBaseURL: 'https://backend.staging.tablesense.com',
    landingPageBaseURL: 'https://book.staging.tablesense.com',
    stripePublishableKey: 'pk_test_51LwP0cEM5WnAQPYGeIR7UNiJs2HDe5XhBM4iqa05NzPP3CoCXfrmbsBDgcpgOheIZYEtLQ9qLEieCKohygVbNjRM00MQ9ZfOJh',
    stripeBaseURL: 'https://dashboard.stripe.com',
    widgetBaseURL: 'https://booking.staging.tablesense.com',
    authAllowedDomains: ['https://backend.staging.tablesense.com'],
}
