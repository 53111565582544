import { I18nPluralPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

export enum DurationUnit {
    Year = 'year',
    Month = 'month',
    Week = 'week',
    Day = 'day',
    Hour = 'hour',
    Minute = 'minute',
    Second = 'second',
}

@Pipe({
    name: 'duration',
})
export class DurationPipe implements PipeTransform {

    private yearPluralMapping = {
        '=1': '1 year',
        other: '# years',
    }
    private monthPluralMapping = {
        '=1': '1 month',
        other: '# months',
    }
    private weekPluralMapping = {
        '=1': '1 week',
        other: '# weeks',
    }
    private dayPluralMapping = {
        '=1': '1 day',
        other: '# days',
    }
    private hourPluralMapping = {
        '=1': '1 hour',
        other: '# hours',
    }
    private minutePluralMapping = {
        '=1': '1 minute',
        other: '# minutes',
    }
    private secondPluralMapping = {
        '=1': '1 second',
        other: '# seconds',
    }

    constructor(private pluralPipe: I18nPluralPipe) { }

    transform(number: number, unit: DurationUnit): string {
        const seconds = this.toSeconds(number, unit)
        const [
            largeNumber,
            largeUnit,
            remainder,
            remainderUnit,
        ] = this.toLargestUnitAndRemainder(seconds)
        const largeUnitString = this.pluralPipe.transform(
            largeNumber,
            this.getUnitPluralMapping(largeUnit)
        )
        const remainderUnitString = this.pluralPipe.transform(
            remainder,
            this.getUnitPluralMapping(remainderUnit)
        )
        if (largeNumber === 0) {
            return remainderUnitString
        }
        if (remainder === 0) {
            return largeUnitString
        }
        return `${largeUnitString} ${remainderUnitString}`
    }

    private getUnitPluralMapping(unit: DurationUnit): Record<string, string> {
        switch (unit) {
        case DurationUnit.Year:
            return this.yearPluralMapping
        case DurationUnit.Month:
            return this.monthPluralMapping
        case DurationUnit.Week:
            return this.weekPluralMapping
        case DurationUnit.Day:
            return this.dayPluralMapping
        case DurationUnit.Hour:
            return this.hourPluralMapping
        case DurationUnit.Minute:
            return this.minutePluralMapping
        case DurationUnit.Second:
            return this.secondPluralMapping
        }
    }

    private toLargestUnitAndRemainder(
        seconds: number
    ): [number, DurationUnit, number, DurationUnit] {
        const [largeNumber, largeUnit] = this.toLargestUnit(seconds)
        const secondsRemainder = seconds - this.toSeconds(largeNumber, largeUnit)
        const [remainder, remainderUnit] = this.toLargestUnit(secondsRemainder)
        return [largeNumber, largeUnit, remainder, remainderUnit]
    }

    private toLargestUnit(seconds: number): [number, DurationUnit] {
        const yearsInSeconds = this.toSeconds(1, DurationUnit.Year)
        if (seconds >= yearsInSeconds) {
            return [Math.floor(seconds / yearsInSeconds), DurationUnit.Year]
        }
        const monthsInSeconds = this.toSeconds(1, DurationUnit.Month)
        if (seconds >= monthsInSeconds) {
            return [Math.floor(seconds / monthsInSeconds), DurationUnit.Month]
        }
        const weeksInSeconds = this.toSeconds(1, DurationUnit.Week)
        if (seconds >= weeksInSeconds) {
            return [Math.floor(seconds / weeksInSeconds), DurationUnit.Week]
        }
        const daysInSeconds = this.toSeconds(1, DurationUnit.Day)
        if (seconds >= daysInSeconds) {
            return [Math.floor(seconds / daysInSeconds), DurationUnit.Day]
        }
        const hoursInSeconds = this.toSeconds(1, DurationUnit.Hour)
        if (seconds >= hoursInSeconds) {
            return [Math.floor(seconds / hoursInSeconds), DurationUnit.Hour]
        }
        const minutesInSeconds = this.toSeconds(1, DurationUnit.Minute)
        if (seconds >= minutesInSeconds) {
            return [Math.floor(seconds / minutesInSeconds), DurationUnit.Minute]
        }
        return [seconds, DurationUnit.Second]
    }

    private toSeconds(number: number, unit: DurationUnit): number {
        switch (unit) {
        case DurationUnit.Year:
            return number * 365 * 24 * 60 * 60
        case DurationUnit.Month:
            return number * 30 * 24 * 60 * 60
        case DurationUnit.Week:
            return number * 7 * 24 * 60 * 60
        case DurationUnit.Day:
            return number * 24 * 60 * 60
        case DurationUnit.Hour:
            return number * 60 * 60
        case DurationUnit.Minute:
            return number * 60
        case DurationUnit.Second:
            return number
        }
    }
}
