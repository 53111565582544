import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { Schedule } from '@app/domain/Schedule'

@Pipe({
    name: 'scheduleDateRange',
})
export class ScheduleDateRangePipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe
    ) { }

    transform(
        schedule: Schedule,
        format: 'inline' | 'block' = 'block'
    ): string {
        if (schedule.dateRanges.length === 0) {
            return 'Always Active'
        }
        return schedule.dateRanges
            .map(dateRange => this.transformDateRange(dateRange.start, dateRange.end))
            .join(format === 'block' ? '\n' : ', ')
    }

    private transformDateRange(start: Date | null, end: Date | null): string {
        if (start === null && end === null) {
            return 'Always Active'
        }
        if (start === null) {
            return `Until ${this.datePipe.transform(end, 'shortDate')}`
        }
        if (end === null) {
            return `From ${this.datePipe.transform(start, 'shortDate')}`
        }
        return `${this.datePipe.transform(start, 'shortDate')}` +
            ` - ${this.datePipe.transform(end, 'shortDate')}`
    }
}
