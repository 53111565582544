export class BookingsFeedbackReportData {
    public firstName: string | null
    public lastName: string | null
    public emailAddress: string
    public startTime: Date
    public rating: number
    public comment: string | null
    public isRedirectedExternally: boolean
    public feedbackDateCreated: Date

    constructor(
        firstName: string,
        lastName: string,
        emailAddress: string,
        startTime: Date,
        rating: number,
        comment: string,
        isRedirectedExternally: boolean,
        feedbackDateCreated: Date
    ) {
        this.firstName = firstName
        this.lastName = lastName
        this.emailAddress = emailAddress
        this.startTime = startTime
        this.rating = rating
        this.comment = comment
        this.isRedirectedExternally = isRedirectedExternally
        this.feedbackDateCreated = feedbackDateCreated
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`
    }
}
