import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'
import { ContextService } from '../../../../services/context.service'
import { CustomerService } from '../../../../services/customer.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ModelCloningService } from '../../../../services/model-cloning.service'
import { ToastService } from '../../../../services/toast.service'
import { Customer } from '../../../../domain/Customer'
import { finalize, Observable, takeUntil } from 'rxjs'
import { ToastType } from '../../../../domain/Toast'
import { ToastBuilder } from '../../../../domain/ToastBuilder'
import { InputComponent, InputType } from '../input/input.component'
import { TextareaComponent } from '@app/features/shared/components/textarea/textarea.component'

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
})
export class CustomerDetailsComponent {

    @Input() customer!: Customer
    @Output() customerUpdated = new EventEmitter<Customer>()
    @ViewChild('customerName', { static: true }) customerName!: InputComponent
    @ViewChild('emailAddress', { static: true }) emailAddress!: InputComponent
    @ViewChild('phoneNumber', { static: true }) phoneNumber!: InputComponent
    @ViewChild('note', { static: true }) note!: TextareaComponent
    customerNameValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
    ]
    emailAddressValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
        Validators.email,
    ]
    phoneNumberValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
    ]
    noteValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
    ]
    isEditing = false
    private onDestroy = new EventEmitter<void>()

    constructor(
        private contextService: ContextService,
        private customerService: CustomerService,
        private modelCloningService: ModelCloningService,
        private toastService: ToastService
    ) { }

    updateName(name: string | null): void {
        let firstName: string | null = name?.split(' ')[0]?.trim() ?? null
        let lastName: string | null = firstName ?
            (name?.replace(firstName, '').trim() ?? null) : null
        let updatedCustomer = this.modelCloningService.cloneCustomer(this.customer)
        updatedCustomer.updateFirstName(firstName)
        updatedCustomer.updateLastName(lastName)
        this.updateCustomer(updatedCustomer)
            .subscribe({
                next: customer => {
                    this.customerUpdated.emit(customer)
                    this.customerName.finishInputUpdate()
                    this.showSuccess(
                        'Customer Name updated',
                        'Customer Updated'
                    )
                },
                error: _ => {
                    this.customerName.cancelInputUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this customer\'s name. Please try again.',
                        'Customer Update Error'
                    )
                },
            })
    }

    updateEmailAddress(emailAddress: string | null): void {
        let updatedCustomer = this.modelCloningService.cloneCustomer(this.customer)
        updatedCustomer.updateEmailAddress(emailAddress)
        this.updateCustomer(updatedCustomer)
            .subscribe({
                next: customer => {
                    this.customerUpdated.emit(customer)
                    this.emailAddress.finishInputUpdate()
                    this.showSuccess(
                        'Email Address updated',
                        'Customer Updated'
                    )
                },
                error: _ => {
                    this.emailAddress.cancelInputUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this customer\'s email address. Please try again.',
                        'Customer Update Error'
                    )
                },
            })
    }

    updatePhoneNumber(phoneNumber: string | null): void {
        let updatedCustomer = this.modelCloningService.cloneCustomer(this.customer)
        updatedCustomer.updatePhoneNumber(phoneNumber)
        this.updateCustomer(updatedCustomer)
            .subscribe({
                next: customer => {
                    this.customerUpdated.emit(customer)
                    this.phoneNumber.finishInputUpdate()
                    this.showSuccess(
                        'Phone Number updated',
                        'Customer Updated'
                    )
                },
                error: _ => {
                    this.phoneNumber.cancelInputUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this customer\'s phone number. Please try again.',
                        'Customer Update Error'
                    )
                },
            })
    }

    updateNote(note: string | null): void {
        let updatedCustomer = this.modelCloningService.cloneCustomer(this.customer)
        updatedCustomer.updateNote(note)
        this.updateCustomer(updatedCustomer)
            .subscribe({
                next: customer => {
                    this.customerUpdated.emit(customer)
                    this.note.finishTextUpdate()
                    this.showSuccess(
                        'Note updated',
                        'Customer Updated'
                    )
                },
                error: _ => {
                    this.note.cancelTextUpdate()
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t update this customer\'s note. Please try again.',
                        'Customer Update Error'
                    )
                },
            })
    }

    private updateCustomer(updatedCustomer: Customer): Observable<Customer> {
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            throw new Error('No organisation found in context')
        }
        const business = this.contextService.getBusiness()
        if (!business) {
            throw new Error('No business found in context')
        }
        const venue = this.contextService.getVenue()
        if (!venue) {
            throw new Error('No venue found in context')
        }
        this.isEditing = true
        return this.customerService.updateCustomer(
            organisation.id,
            business.id,
            venue.id,
            this.customer,
            updatedCustomer
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.isEditing = false)
            )
    }

    private showError(message: string, title?: string) {
        this.showToast(ToastType.Danger, message, title)
    }

    private showSuccess(message: string, title?: string) {
        this.showToast(ToastType.Success, message, title)
    }

    private showToast(toastType: ToastType, message: string, title?: string) {
        let  toastBuilder = new ToastBuilder(message)
            .withType(toastType)

        if (title){
            toastBuilder = toastBuilder.withHeader(title)
        }

        this.toastService.show(toastBuilder.build())
    }

    protected readonly InputType = InputType
}
