export class DepositDTO {
    constructor(
        public id: string,
        public paymentMethodId: string | null,
        public amount: number,
        public currencyCode: string,
        public dateCreated: string,
        public datePaid: string | null,
        public dateRefunded: string | null
    ) { }
}
