<div class="btn-group w-100" role="group">
  <ng-container *ngIf="venues$ | async as venues">
      <div ngbDropdown class="w-100" display="dynamic">
          <button
              ngbDropdownToggle
              id="venueSelection"
              class="btn d-flex align-items-center text-start w-100"
              [ngClass]="compact ? 'compact px-2' : ''"
              type="button"
          >
              <div
                  class="location"
                  [ngClass]="compact ? 'me-2 me-lg-0' : 'me-2'"
              >
              </div>
              <span
                  *ngIf="!compact"
                  class="text-truncate"
              >
                  {{ venueDropdownTitle$ | async }}
              </span>
              <span
                  *ngIf="compact"
                  class="text-truncate d-lg-none"
              >
                {{ venueDropdownTitle$ | async }}
              </span>
          </button>
          <div
            *ngIf="venue$ | async as selectedVenue"
              ngbDropdownMenu
              aria-labelledby="venueSelection"
              class="position-fixed"
          >
            <button
              class="dropdown-item hstack gap-5"
              *ngFor="let venue of venues" (click)="selectVenue(venue)"
            >
              {{ venue.displayName }}
                <div class="ms-auto">
                    <i
                        *ngIf="venue.id === selectedVenue.id"
                        class="bi bi-check-circle-fill text-primary fs-5"
                    >
                    </i>
                    <i
                        *ngIf="venue.id !== selectedVenue.id"
                        class="bi bi-circle"
                    ></i>

                </div>
            </button>
          </div>
      </div>
  </ng-container>
</div>
