export class Charge {

    constructor(
        public partySize: number,
        public lowerBound: number | null,
        public upperBound: number | null,
        public unitAmount: number,
        public isPerCover: boolean
    ) { }

    get amount(): number {
        if (this.isPerCover) {
            return this.unitAmount * this.partySize
        }
        return this.unitAmount
    }
}
