<form>
    <div class="hstack align-bottom">
        <button
            class="btn btn-sm btn-outline-secondary"
            (click)="d.toggle()"
            type="button"
        >
            <img
                *ngIf="style === 'icon'"
                ngSrc="assets/calendar/normal.svg"
                alt="Date Range Icon"
                height="24"
                width="24"
            >
            <span *ngIf="style === 'text'">
                <ng-container
                    *ngIf="this.fromDate !== null && this.toDate !== null"
                >
                    {{ formatter.format(fromDate) }} - {{ formatter.format(toDate) }}
                </ng-container>
                <ng-container
                    *ngIf="!hasValidSelection"
                >
                    Select Date Range
                </ng-container>
            </span>
        </button>
        <input
            class="form-control p-0 m-0 border-0"
            ngbDatepicker
            #d="ngbDatepicker"
            style="width: 0; height: 0"
            [startDate]="fromDate"
            [displayMonths]="2"
            [dayTemplate]="t"
            [autoClose]="hasValidSelection ? 'outside' : false"
            [placement]="placement || 'bottom-start bottom-end top-start top-end'"
            (dateSelect)="onDateSelection($event, d)"
        />
<ng-template #t let-date let-focused="focused">
    <div
        class="custom-day ngb-dp-day"
        [class.focused]="focused"
        [class.range-start]="isRangeStart(date)"
        [class.range-end]="isRangeEnd(date)"
        [class.faded]="isInRange(date) || isHoveredDateInRange(date)"
        [class.hover]="isHovered(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
    >
        {{ date.day }}
    </div>
</ng-template>
    </div>
</form>
