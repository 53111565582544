import { UserNotificationPreferencesDTO } from '@services/DTO/UserNotificationPreferencesDTO'

export enum UserRoleDTO {
    OWNER = 'OWNER',
    MANAGER = 'MANAGER',
}

export class UserDTO {
    constructor(
        public id: string,
        public name: string,
        public emailAddress: string,
        public role: UserRoleDTO,
        public venueIds: string[] | null,
        public notificationPreferences: UserNotificationPreferencesDTO[]
    ) { }
}
