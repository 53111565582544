export enum EmailMarketingListAssociationType {
    FIRSTNAME = 'FIRST_NAME',
    LASTNAME = 'LAST_NAME',
    EMAIL_ADDRESS = 'EMAIL_ADDRESS',
    PHONE_NUMBER = 'PHONE_NUMBER'
}

export class EmailMarketingListField {
    constructor(
        public association: EmailMarketingListAssociationType,
        public tag : string,
        public type : string,
        public label: string,
        public fallback: string | null
    ) {
    }
}
