import { Helper } from '@app/helpers/utilities/helper'
import { Pipe, PipeTransform } from '@angular/core'

export type DateOffset = {
    days: number
    hours: number
    minutes: number
}

@Pipe({
    name: 'offsetDate',
})
export class OffsetDatePipe implements PipeTransform {

    transform(inputDate: Date | undefined, value: Partial<DateOffset>): Date {
        const date = inputDate || Helper.startOfDay(new Date())
        if (value.days) {
            date.setDate(date.getDate() + value.days)
        }
        if (value.hours) {
            date.setHours(date.getHours() + value.hours)
        }
        if (value.minutes) {
            date.setMinutes(date.getMinutes() + value.minutes)
        }
        return date
    }
}
