<div
    class="vstack"
>
    <button
        *ngIf="!isEditing"
        class="btn btn-sm btn-outline-secondary me-auto"
        [ngClass]="isEditable ? 'editable' : 'disabled'"
        appEditableIcon
        (click)="startEditing()"
    >
        {{ readonlyValue || value || placeholder || "" }}
    </button>
    <form
        [formGroup]="inputForm"
        *ngIf="isEditing"
        class="hstack gap-2 align-items-baseline"
    >
        <input
            class="form-control"
            formControlName='input'
            [type]="inputType || 'text'"
            [placeholder]="placeholder || ''"
            [class.is-invalid]="!inputForm.valid"
            (input)="onInputChange($event)"
        />
        <button
            type="button"
            class="btn btn-sm btn-primary col-sm"
            (click)="saveInputUpdate()"
            [disabled]="!inputForm.valid"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-sm btn-outline-secondary col-sm"
            (click)="cancelInputUpdate()"
        >
            Cancel
        </button>
    </form>
</div>
