import { Injectable } from '@angular/core'
import { User, UserRole } from '@app/domain/User'

export enum Ability {
    ManageBilling,
}

@Injectable({
    providedIn: 'root',
})
export class PermissionService {

    doesUserHaveAbility(user: User, ability: Ability): boolean {
        return new RoleAuthorizer(user).permits(ability)
    }
}

class RoleAuthorizer {

    private ownerPermittedAbilities: Ability[] = [
        Ability.ManageBilling,
    ]

    constructor(
        private user: User
    ) { }

    permits(ability: Ability): boolean {
        switch (this.user.role) {
        case UserRole.OWNER:
            return this.ownerPermits(ability)
        case UserRole.MANAGER:
            return false
        }
    }

    private ownerPermits(ability: Ability): boolean {
        return this.ownerPermittedAbilities.includes(ability)
    }
}
