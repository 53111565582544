export class CancellationChargeDTO {
    constructor(
        public id: string,
        public amount: number,
        public currencyCode: string,
        public partySize: number,
        public paymentIntentId: string,
        public dateCreated: string
    ) { }
}
