import { PartySizeDurationDTO } from '@services/DTO/PartySizeDurationDTO'
import { ScheduleExceptionDTO } from '@services/DTO/ScheduleExceptionDTO'
import { ServiceDTO } from '@services/DTO/ServiceDTO'

export class ScheduleDTO {

    constructor(
        public id: string,
        public displayName: string,
        public dateRanges: { first: string | null, second: string | null }[],
        public preBookingWindowMinutes: number,
        public preBookingModificationWindowMinutes: number,
        public inAdvanceBookingLimitDays: number,
        public coverVelocity: number | null,
        public bookingDurations: PartySizeDurationDTO[],
        public services: ServiceDTO[],
        public exceptions: ScheduleExceptionDTO[]
    ) { }
}
