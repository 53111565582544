<app-spinner-overlay
    [showSpinner]="isPerformingAction"
>
</app-spinner-overlay>
<form
    (ngSubmit)="action()"
>
    <div class="modal-header">
        <h4 class="modal-title">
            {{ title }}
        </h4>
        <button
            type="button"
            class="btn-close"
            (click)="dismiss()"
        >
        </button>
    </div>
    <div class="modal-body">
        <div class="vstack gap-2">
            <ng-container *ngTemplateOutlet="contentTemplate">
            </ng-container>
            <form
                *ngIf="actionConfiguration"
            >
                <div
                    *ngIf="actionConfiguration.collectText"
                    class="form-group"
                >
                    <label
                        *ngIf="actionConfiguration.textLabel"
                        class="form-label"
                    >
                        {{ actionConfiguration.textLabel }}
                    </label>
                    <textarea
                        class="form-control"
                        [placeholder]="actionConfiguration.textPlaceholder"
                        [(ngModel)]="text"
                        [required]="actionConfiguration.textRequired || false"
                        type="text"
                        name="text"
                    ></textarea>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="cancel()"
        >
            <span
                *ngIf="cancelButtonTitle"
                >
                {{ cancelButtonTitle }}
            </span>
            <span
                *ngIf="!cancelButtonTitle"
            >
                Cancel
            </span>
        </button>
        <ng-template
            [ngTemplateOutlet]="actionButton"
        >
        </ng-template>
    </div>
</form>

<ng-template #actionButton>
    <ng-container
        [ngSwitch]="actionType"
    >
        <button
            *ngSwitchCase="'delete'"
            class="btn btn-danger"
            type="submit"
        >
            <ng-template
                [ngTemplateOutlet]="actionTitle"
                [ngTemplateOutletContext]="{ defaultTitle: 'Delete' }"
            >
            </ng-template>
        </button>
        <button
            *ngSwitchCase="'create'"
            class="btn btn-primary"
            type="submit"
            data-testid="modal-create-button"
            ngbAutofocus
        >
            <ng-template
                [ngTemplateOutlet]="actionTitle"
                [ngTemplateOutletContext]="{ defaultTitle: 'Create' }"
            >
            </ng-template>
        </button>
        <button
            *ngSwitchCase="'update'"
            class="btn btn-primary"
            type="submit"
            data-testid="modal-update-button"
            ngbAutofocus
        >
            <ng-template
                [ngTemplateOutlet]="actionTitle"
                [ngTemplateOutletContext]="{ defaultTitle: 'Update' }"
            >
            </ng-template>
        </button>
        <button
            *ngSwitchCase="'confirm'"
            class="btn btn-info"
            type="submit"
            ngbAutofocus
            [disabled]="isActionDisabled"
        >
            <ng-template
                [ngTemplateOutlet]="actionTitle"
                [ngTemplateOutletContext]="{ defaultTitle: 'Confirm' }"
            >
            </ng-template>
        </button>
    </ng-container>
</ng-template>

<ng-template #actionTitle let-defaultTitle="defaultTitle">
    <span
        *ngIf="actionButtonTitle"
    >
        {{ actionButtonTitle }}
    </span>
    <span
        *ngIf="!actionButtonTitle"
    >
        {{ defaultTitle }}
    </span>
</ng-template>
