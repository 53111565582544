<app-spinner-overlay
    [showSpinner]="isLoadingBooking || isLoadingOtherBookings || isLoadingSchedule"
>
</app-spinner-overlay>
<app-booking
    *ngIf="booking"
    style="display: contents"
    [organisation]="organisation"
    [business]="business"
    [venue]="venue"
    [booking]="booking"
    [otherBookings]="otherBookings!!"
    [schedule]="schedule!!"
    (bookingBeingChanged)="bookingBeingChanged.emit($event)"
    (bookingChanged)="bookingChanged.emit($event)"
    (customerSelected)="customerSelected.emit($event)"
>
</app-booking>
@if (!booking) {
    <div class="p-5">
    </div>
}
