import DineroFactory from 'dinero.js'

export class BillingDetails {

    public price: string

    constructor(
        public inService: boolean,
        public needsToUpgradeTrial: boolean,
        public nextBillingAmount: string,
        public nextBillingAmountCurrencyCode: string,
        public nextBillingDate: Date | null
    ) {
        this.price = DineroFactory({
            amount: parseInt(this.nextBillingAmount),
            currency: this.nextBillingAmountCurrencyCode as any,
        }).toFormat()
    }
}
