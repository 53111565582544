import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, takeUntil } from 'rxjs'

export type ModalActionType =
    'update'
    | 'create'
    | 'delete'
    | 'confirm'

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {

    @Input() isPerformingAction = false
    @Input() actionConfiguration?: Partial<{
        collectText: boolean,
        textLabel?: string,
        textPlaceholder?: string,
        textRequired?: boolean,
    }>
    @Input() actionValidation?: Observable<boolean>
    @Output() actionSelected = new EventEmitter<string | void>()
    @Output() cancelSelected = new EventEmitter<void>()
    title!: string
    text?: string
    actionType!: ModalActionType
    actionButtonTitle?: string
    cancelButtonTitle?: string
    cancelPerformsAction = false
    contentTemplate!: TemplateRef<any>
    isActionDisabled = false
    private onDestroy = new EventEmitter<void>()

    constructor(
        private modal: NgbActiveModal
    ) { }

    ngOnInit() {
        this.actionValidation
            ?.pipe(takeUntil(this.onDestroy))
            .subscribe(isValid => {
                this.isActionDisabled = !isValid
            })
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    dismiss() {
        this.modal.dismiss()
    }

    cancel() {
        if (this.cancelPerformsAction) {
            this.cancelSelected.emit()
        } else {
            this.modal.dismiss()
        }
    }

    action() {
        const text = this.actionConfiguration?.collectText ? this.text : void 0
        this.actionSelected.emit(text)
    }
}
