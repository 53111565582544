import { Pipe, PipeTransform } from '@angular/core'
import { ScaleTime } from 'd3'

@Pipe({
    name: 'relativeTimeScale',
})
export class RelativeTimeScalePipe implements PipeTransform {

    transform(
        date: Date,
        scale: ScaleTime<any, any>
    ): number {
        const start = scale.range()[ 0 ]
        const startWithMinutes = new Date(start)
        startWithMinutes.setMinutes(date.getMinutes())
        return scale(start) - scale(startWithMinutes)
    }
}
