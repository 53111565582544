import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PATHS } from '../routes'
import { ReplaySubject, firstValueFrom, tap } from 'rxjs'
import { TokenRepositoryService } from './token-repository.service'
import { WindowProvider } from '../helpers/providers/window.provider'
import { environment } from '@environments/environment'

export interface TokenDTO {
  expires_in: number
  access_token: string
  refresh_token?: string
  token_type: string
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private loggedIn  = new ReplaySubject<boolean>(1)
    loggedIn$ = this.loggedIn.asObservable()

    constructor(
        private http: HttpClient,
        private tokenRepository: TokenRepositoryService,
        private windowProvider: WindowProvider
    ) { }

    checkLoggedInStatus() {
        const loggedIn = this.getToken() !== null
        this.loggedIn.next(loggedIn)
    }

    getToken(): string | null {
        return this.tokenRepository.getAccessToken()
    }

    logout() {
        this.tokenRepository.removeTokens()
        this.windowProvider.navigateToUrl(this.getCognitoUrl('logout'))
    }

    login() {
        this.windowProvider.navigateToUrl(this.getCognitoUrl('login'))
    }

    reauthorise() {
        this.windowProvider.navigateToUrl(this.getCognitoUrl('oauth2/authorize'))
    }

    async fetchToken(code: string): Promise<TokenDTO> {
        let url = `${environment.cognitoAuthUrl}/oauth2/token`
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let options = {
            headers: headers,
        }
        let body = new HttpParams()
            .set('client_id', environment.cognitoClientId)
            .set('grant_type', 'authorization_code')
            .set('code', code)
            .set('redirect_uri', this.getAuthCallbackUri())

        return firstValueFrom(
            this.http.post<TokenDTO>(url, body, options)
                .pipe(
                    tap((tokenData) => {
                        this.setToken(tokenData)
                    })
                )
        )
    }

    async refreshToken(): Promise<TokenDTO> {
        let refreshToken = this.tokenRepository.getRefreshToken()
        if (!refreshToken) {
            throw new Error('No refresh token')
        }

        let url = `${environment.cognitoAuthUrl}/oauth2/token`
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let options = {
            headers: headers,
        }

        let body = new HttpParams()
            .set('client_id', environment.cognitoClientId)
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refreshToken)
            .set('scope', environment.cognitoScopes)

        return firstValueFrom(
            this.http.post<TokenDTO>(url, body, options)
                .pipe(
                    tap((tokenData) => {
                        this.setToken(tokenData)
                    })
                )
        )
    }

    private getAuthCallbackUri() {
        return `${this.windowProvider.getLocationOrigin()}/${PATHS.authCallback}`
    }

    private getCognitoUrl(path: string) {
        let params = new HttpParams()
            .set('client_id', environment.cognitoClientId)
            .set('response_type', 'code')
            .set('state', 'STATE')
            .set('scope', environment.cognitoScopes)
            .set('redirect_uri', this.getAuthCallbackUri())

        return `${environment.cognitoAuthUrl}/${path}?${params.toString()}`
    }

    private setToken(tokenData: TokenDTO) {
        this.tokenRepository.saveAccessToken(tokenData.access_token)
        if (tokenData.refresh_token !== undefined) {
            this.tokenRepository.saveRefreshToken(tokenData.refresh_token)
        }
        this.loggedIn.next(true)
    }
}
