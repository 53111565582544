export class PaymentsAccount {
    constructor(
        public id: string,
        public depositApplicationFeePercent: number
    ) { }

    get depositApplicationFeePercentage(): number {
        return this.depositApplicationFeePercent / 100
    }

    get cancellationChargeApplicationFeePercentage(): number {
        return 0
    }
}
