import { BookingStatusType } from '@app/domain/BookingStatus'
import { Directive, ElementRef, Inject, Input, OnChanges } from '@angular/core'

@Directive({
    selector: '[appBookingStatus]',
    standalone: true,
})
export class BookingStatusDirective implements OnChanges {

    @Input() statusType!: BookingStatusType

    constructor(
        @Inject(ElementRef) private element: ElementRef
    ) { }

    ngOnChanges() {
        this.element.nativeElement.classList
            .remove('requested', 'booked', 'no-show', 'cancelled', 'seated', 'finished', 'rejected')
        this.element.nativeElement.classList
            .add(this.makePartySizeClassName())
    }

    private makePartySizeClassName() {
        switch (this.statusType) {
        case BookingStatusType.Requested:
            return 'requested'
        case BookingStatusType.PendingPayment:
            return 'pending-payment'
        case BookingStatusType.Booked:
            return 'booked'
        case BookingStatusType.NoShow:
            return 'no-show'
        case BookingStatusType.Cancelled:
            return 'cancelled'
        case BookingStatusType.Waiting:
            return 'waiting'
        case BookingStatusType.PartiallySeated:
            return 'partially-seated'
        case BookingStatusType.Seated:
            return 'seated'
        case BookingStatusType.Finished:
            return 'finished'
        case BookingStatusType.Rejected:
            return 'rejected'
        }
    }
}
