import { Address } from '@app/domain/Address'
import { PaymentsAccount } from '@app/domain/PaymentsAccount'
import { User } from '@app/domain/User'
import { Venue } from '@app/domain/Venue'

export class Business {

    constructor(
        public id: string,
        public displayName: string,
        public phoneNumber: string,
        public address: Address,
        public paymentsAccount: PaymentsAccount | null,
        public venues: Venue[]
    ) { }

    addVenue(venue: Venue) {
        this.venues.push(venue)
    }

    getVenue(venueId: string): Venue | null {
        return this.venues.find(venue => venue.id === venueId) ?? null
    }

    updateVenue(venue: Venue) {
        const index = this.venues.findIndex(existingVenue => existingVenue.id === venue.id)
        if (index === -1) {
            return
        }
        this.venues.splice(index, 1, venue)
    }

    removeVenue(venue: Venue) {
        const index = this.venues.findIndex(existingVenue => existingVenue.id === venue.id)
        if (index === -1) {
            return
        }
        this.venues.splice(index, 1)
    }

    venuesUserWorksIn(user: User): Venue[] {
        return this.venues.filter(venue => user.worksInVenue(venue))
    }
}
