import { BookingStatusType } from '@app/domain/BookingStatus'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'bookingStatusName',
})
export class BookingStatusNamePipe implements PipeTransform {

    transform(type: BookingStatusType): string {
        switch (type) {
        case BookingStatusType.Requested:
            return 'Requested'
        case BookingStatusType.PendingPayment:
            return 'Pending Payment'
        case BookingStatusType.Booked:
            return 'Booked'
        case BookingStatusType.Cancelled:
            return 'Cancelled'
        case BookingStatusType.NoShow:
            return 'No Show'
        case BookingStatusType.Waiting:
            return 'Waiting at Bar'
        case BookingStatusType.PartiallySeated:
            return 'Partially Seated'
        case BookingStatusType.Seated:
            return 'Seated'
        case BookingStatusType.Finished:
            return 'Finished'
        case BookingStatusType.Rejected:
            return 'Rejected'
        }
    }
}
