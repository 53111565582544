import { Business } from '@app/domain/Business'
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core'
import { Customer } from '@app/domain/Customer'
import { CustomerService } from '@services/customer.service'
import { FormGroup } from '@angular/forms'
import { Organisation } from '@app/domain/Organisation'
import { Venue } from '@app/domain/Venue'
import {
    combineLatest,
    debounceTime, filter,
    switchMap,
    takeUntil,
} from 'rxjs'
import { startWith } from 'rxjs/operators'

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
})
export class ContactFormComponent implements OnInit, OnDestroy {

    @Input() organisation!: Organisation
    @Input() business!: Business
    @Input() venue!: Venue
    @Input() form!: FormGroup
    @Input() offerCustomerSuggestions = true
    customerDetailsSubject = new EventEmitter<{
        firstName: string,
        lastName: string,
        emailAddress: string,
        phoneNumber: string
    }>()
    customerSearch: Customer[] = []
    focusedInput: 'firstName' | 'lastName' | 'emailAddress' | 'phoneNumber' | null = null
    showCustomerSuggestions = false
    loseFocusTimeoutId?: number
    selectedCustomerNote: string | null = null
    private minimumSearchLength = 3
    private onDestroy = new EventEmitter()

    constructor(
        private customerService: CustomerService
    ) { }

    ngOnInit() {
        this.bindContactSearchToSearch()
        this.bindContactFieldsToGettingSuggestions()
        this.bindFormInputToClearingSelectedCustomerNote()
    }

    ngOnDestroy() {
        this.onDestroy.emit()
        this.onDestroy.complete()
    }

    customerSuggestionSelected(customer: Customer) {
        this.form.patchValue({
            firstName: customer.firstName,
            lastName: customer.lastName,
            emailAddress: customer.emailAddress,
            phoneNumber: customer.phoneNumber,
        })
        setTimeout(() => {
            this.selectedCustomerNote = customer.note()
        }, 100)
    }

    focusInFirstName() {
        this.focusedInput = 'firstName'
        this.showCustomerSuggestions = true
        clearTimeout(this.loseFocusTimeoutId)
    }

    focusOutFirstName() {
        this.inputHasLostFocus()
    }

    focusInLastName() {
        this.focusedInput = 'lastName'
        this.showCustomerSuggestions = true
        clearTimeout(this.loseFocusTimeoutId)
    }

    focusOutLastName() {
        this.inputHasLostFocus()
    }

    focusInEmailAddress() {
        this.focusedInput = 'emailAddress'
        this.showCustomerSuggestions = true
        clearTimeout(this.loseFocusTimeoutId)
    }

    focusOutEmailAddress() {
        this.inputHasLostFocus()
    }

    focusInPhoneNumber() {
        this.focusedInput = 'phoneNumber'
        this.showCustomerSuggestions = true
        clearTimeout(this.loseFocusTimeoutId)
    }

    focusOutPhoneNumber() {
        this.inputHasLostFocus()
    }

    hideCustomerSuggestions() {
        this.showCustomerSuggestions = false
    }

    private inputHasLostFocus() {
        this.loseFocusTimeoutId = setTimeout(() => {
            this.focusedInput = null
            this.showCustomerSuggestions = false
        }, 200)
    }

    private bindContactFieldsToGettingSuggestions() {
        combineLatest([
            this.form.get('firstName')!.valueChanges.pipe(startWith('')),
            this.form.get('lastName')!.valueChanges.pipe(startWith('')),
            this.form.get('emailAddress')!.valueChanges.pipe(startWith('')),
            this.form.get('phoneNumber')!.valueChanges.pipe(startWith('')),
        ])
            .pipe(takeUntil(this.onDestroy))
            .subscribe((fields) => {
                this.customerDetailsSubject.next({
                    firstName: fields[0]?.trim(),
                    lastName: fields[1]?.trim(),
                    emailAddress: fields[2]?.trim(),
                    phoneNumber: fields[3]?.trim(),
                })
            })
    }

    private bindContactSearchToSearch() {
        if (!this.offerCustomerSuggestions) {
            return
        }
        this.customerDetailsSubject
            .pipe(
                takeUntil(this.onDestroy),
                debounceTime(300),
                filter((value) => {
                    const meetsMinimum = value.firstName?.length >= this.minimumSearchLength
                        || value.lastName?.length >= this.minimumSearchLength
                        || value.emailAddress?.length >= this.minimumSearchLength
                        || value.phoneNumber?.length >= this.minimumSearchLength
                    if (!meetsMinimum) {
                        this.customerSearch = []
                        this.showCustomerSuggestions = false
                    }
                    return meetsMinimum
                }),
                switchMap((value) => {
                    return this.customerService.searchCustomers(
                        this.organisation.id,
                        this.business.id,
                        this.venue.id,
                        value.firstName === '' ? null : value.firstName,
                        value.lastName === '' ? null : value.lastName,
                        value.emailAddress === '' ? null : value.emailAddress,
                        value.phoneNumber === '' ? null : value.phoneNumber
                    )
                })
            )
            .subscribe({
                next: (customers) => {
                    this.customerSearch = customers
                    if (customers.length > 0) {
                        if (this.focusedInput === null) {
                            this.showCustomerSuggestions = false
                        }
                        this.showCustomerSuggestions = true
                    }
                },
            })
    }

    private bindFormInputToClearingSelectedCustomerNote() {
        // We're not linking directly to the customer when selecting a suggestion
        // the backend decides which customer to link to when creating the booking
        // based on the contact details.
        // If the user changes the form, we should clear the selected note
        // as we cannot guarantee it is still relevant
        combineLatest([
            this.form.get('firstName')!.valueChanges,
            this.form.get('lastName')!.valueChanges,
            this.form.get('emailAddress')!.valueChanges,
            this.form.get('phoneNumber')!.valueChanges,
        ])
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.selectedCustomerNote = null
            })
    }
}
