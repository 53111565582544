import { Injectable } from '@angular/core'
import { LocalStorageService } from './local-storage-service'
import { Subject } from 'rxjs'

export class TokenPair {
    constructor(
        public accessToken: string | null,
        public refreshToken: string | null
    ) { }

    public hasValidTokens = () => {
        return this.accessToken !== null && this.refreshToken !== null
    }
}

@Injectable({
    providedIn: 'root',
})
export class TokenRepositoryService {

    private readonly ACCESS_TOKEN_KEY = 'accessToken'
    private readonly REFRESH_TOKEN_KEY = 'refreshToken'
    private tokens: Subject<TokenPair> = new Subject()

    constructor(
        private localStorageService: LocalStorageService
    ) { }

    getAccessToken(): string | null {
        let token = this.localStorageService.getItem(this.ACCESS_TOKEN_KEY) as string
        return token === '' ? null : token
    }

    saveAccessToken(token: string) {
        this.localStorageService.setItem(this.ACCESS_TOKEN_KEY, token)
        this.tokens.next(new TokenPair(
            token,
            this.getRefreshToken()
        ))
    }

    getRefreshToken(): string | null {
        let token = this.localStorageService.getItem(this.REFRESH_TOKEN_KEY) as string
        return token === '' ? null : token
    }

    saveRefreshToken(token: string) {
        this.localStorageService.setItem(this.REFRESH_TOKEN_KEY, token)
        this.tokens.next(new TokenPair(
            this.getAccessToken(),
            token
        ))
    }

    removeTokens() {
        this.localStorageService.removeItem(this.ACCESS_TOKEN_KEY)
        this.localStorageService.removeItem(this.REFRESH_TOKEN_KEY)
        this.tokens.next(new TokenPair(null, null))
    }

    hasTokens() {
        return this.getAccessToken() !== null && this.getRefreshToken() !== null
    }
}
