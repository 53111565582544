import { Ability, PermissionService } from '@services/permission.service'
import { BehaviorSubject, Observable, map, mergeMap, of, takeUntil } from 'rxjs'
import { DestroyedDirective } from '@app/features/shared/directives/destroyed.directive'
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core'
import { UserService } from '@services/user.service'

@Directive({
    selector: '[appIfUserPermission]',
    hostDirectives: [DestroyedDirective],
})
export class UserPermissionDirective implements OnInit {
    private templateRef = inject(TemplateRef<unknown>)
    private viewContainer = inject(ViewContainerRef)

    private permissionService = inject(PermissionService)
    private userService = inject(UserService)
    private destroy$ = inject(DestroyedDirective).destroyed$

    private show = new BehaviorSubject<Observable<boolean | undefined>>(
        of(undefined)
    )

    @Input() set appIfUserPermission(ability: Ability) {
        this.show.next(
            this.userService.get()
                .pipe(
                    takeUntil(this.destroy$),
                    map((user) => {
                        return this.permissionService.doesUserHaveAbility(
                            user,
                            ability
                        )
                    })
                )
        )
    }

    ngOnInit(): void {
        this.show
            .pipe(
                mergeMap((s) => s),
                takeUntil(this.destroy$)
            )
            .subscribe((showTemplate) =>
                showTemplate ? this.addTemplate() : this.clearTemplate()
            )
    }

    private addTemplate() {
        this.viewContainer.clear()
        this.viewContainer.createEmbeddedView(this.templateRef)
    }

    private clearTemplate() {
        this.viewContainer.clear()
    }
}
