import { Time } from './Time'

export class VenueVelocity {
    constructor(
        public bookingLimit: number | null,
        public coverLimit: number | null,
        public bookingSlotBookingLimits: BookingSlotVelocityLimit[],
        public bookingSlotCoverLimits: BookingSlotVelocityLimit[]
    ) { }

    coverLimitAtDateTime(dateTime: Date): number | null {
        return this.bookingSlotCoverLimits.find(limit => {
            const dayOfWeek = dateTime.getDay()
            if (limit.dayOfWeek !== dayOfWeek) {
                return false
            }
            const time = new Time(null, dateTime.getHours(), dateTime.getMinutes())
            return limit.time.equals(time)
        })
            ?.limit ?? this.coverLimit
    }
}

export class BookingSlotVelocityLimit {
    constructor(
        public dayOfWeek: number,
        public time: Time,
        public limit: number
    ) { }
}
