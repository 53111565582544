<app-spinner-overlay
    [showSpinner]="isSearching"
>
</app-spinner-overlay>
@if(venue$ | async; as venue) {
    <app-paged-booking-list
        style="display: contents;"
        [bookings$]="bookings$"
        [config]="config"
        [isSearching]="isSearching"
        [isShowingResults]="true"
        (pageChanged)="pageChanged($event)"
        (bookingSelected)="bookingSelected.emit($event)"
    >
    </app-paged-booking-list>
}
