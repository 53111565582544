import { BookingSlot } from '@app/domain/BookingSlot'
import { Helper } from '@app/helpers/utilities/helper'
import { PartySizeDuration } from '@app/domain/PartySizeDuration'
import { ScheduleException } from '@app/domain/ScheduleException'
import { ScheduleRule } from './ScheduleRule'
import { Service } from '@app/domain/Service'
import { Venue } from '@app/domain/Venue'
import { coalesceDateRanges } from '@app/domain/Date+Coalesce'
import { toDateTimeRange } from '@app/domain/Time'

export class Schedule {

    constructor(
        public id: string,
        public displayName: string,
        public dateRanges: { start: Date | null, end: Date | null }[],
        public preBookingWindowMinutes: number,
        public preBookingModificationWindowMinutes: number,
        public inAdvanceBookingLimitDays: number,
        public coverVelocity: number | null,
        public bookingDurations: PartySizeDuration[],
        public services: Service[],
        public exceptions: ScheduleException[]
    ) { }

    get openRules(): ScheduleRule[] {
        return this.services
            .flatMap(service => service.openRules)
    }

    get rules(): ScheduleRule[] {
        return this.services
            .flatMap(service => service.rules)
    }

    serviceWithId(serviceId: string): Service | null {
        const service = this.services.find(service => service.id === serviceId)
        return service ?? null
    }

    addService(service: Service) {
        this.services.push(service)
    }

    updateService(service: Service) {
        const index = this.services.findIndex(existingService => {
            return existingService.id === service.id
        })
        if (index === -1) {
            return
        }
        this.services[index] = service
    }

    deleteService(service: Service) {
        this.services = this.services.filter(existingService => {
            return existingService.id !== service.id
        })
    }

    defaultBookingDuration(): number {
        const defaultDuration = this.bookingDurations
            .filter(duration => duration.partySize === null)
            [0] || null
        if (defaultDuration !== null) {
            return defaultDuration.duration
        }
        throw new Error('Area has no default duration')
    }

    durationForPartySize(date: Date, partySize: number): number {
        const ruleDuration = this.rules
            .map(rule => {
                return rule.durationForPartySize(date, partySize)
            })
            .filter(duration => duration !== null)
            [0] || null
        if (ruleDuration !== null) {
            return ruleDuration
        }
        const largestDurationThatFits = this.bookingDurations
            .filter(duration => duration.partySize !== null)
            .filter(duration => duration.partySize! <= partySize)
            .sort((a, b) => b.partySize! - a.partySize!)
            [0] || null
        if (largestDurationThatFits !== null) {
            return largestDurationThatFits.duration
        }
        return this.defaultBookingDuration()
    }

    addException(exception: ScheduleException) {
        this.exceptions.push(exception)
    }

    updateException(exception: ScheduleException) {
        const index = this.exceptions.findIndex(existingException => {
            return existingException.id === exception.id
        })
        if (index === -1) {
            return
        }
        this.exceptions[index] = exception
    }

    removeException(exception: ScheduleException) {
        this.exceptions = this.exceptions.filter(existingException => {
            return existingException.id !== exception.id
        })
    }

    operatesOnDate(date: Date): boolean {
        const startOfDate = Helper.startOfDay(date)
        if (this.dateRanges.length === 0) {
            return true
        }
        const rangesInEffect = this.dateRanges.filter(range => {
            const startDate = range.start ? Helper.startOfDay(range.start) : null
            const endDate = range.end ? Helper.startOfDay(range.end) : null
            if (startDate === null) {
                return startOfDate <= endDate!
            }
            if (endDate === null) {
                return startOfDate >= startDate
            }
            return Helper.isBetweenDate(startOfDate, startDate, endDate)
        })
        return rangesInEffect.length > 0
    }

    servicesOperatingOnDate(date: Date): Service[] {
        return this.services
            .filter(service => service.hasRuleThatAppliesToDate(date))
    }

    serviceWithOpenRuleAtDateTime(date: Date): Service | null {
        return this.services
            .find(service => service.hasOpenRuleAtDateTime(date))
            ?? null
    }

    bookingSlotExtent(): [Date, Date] {
        const extentPerService = this.services
            .map(service => service.bookingSlotExtent())
        const earliest = extentPerService
            .map(extent => extent[0])
            .sort((a, b) => a.getTime() - b.getTime())
            [0]
        const latest = extentPerService
            .map(extent => extent[1])
            .sort((a, b) => b.getTime() - a.getTime())
            [0]
        return [earliest, latest]
    }

    bookingSlotExtentForDate(date: Date): [Date, Date] {
        let dateTimeRange = this.dateRangesExtent(
            rule => rule.appliesToDate(date),
            date,
            0
        )
        if (dateTimeRange === null) {
            return [new Date(date), new Date(date)]
        }
        const earliest = dateTimeRange[0]
        const latest = dateTimeRange[1]
        return [earliest, latest]
    }

    nextClosingDateAfterDateInVenue(date: Date, venue: Venue): Date | null {
        const openDateRanges = this.openDateRangesForDateTime(date, venue.bookingInterval)
        const nextClosingTime = openDateRanges
            .map(range => range[1])
            .filter(endTime => endTime.getTime() > date.getTime())
            .sort((a, b) => a.getTime() - b.getTime())[0]
        if (nextClosingTime === undefined) {
            return null
        }
        return nextClosingTime
    }

    nextOpeningDateAfterDateInVenue(date: Date, venue: Venue): Date | null {
        const openDateRanges = this.openDateRangesForDateTime(date, venue.bookingInterval)
        const nextOpeningTime = openDateRanges
            .map(range => range[0])
            .filter(startTime => startTime.getTime() > date.getTime())
            .sort((a, b) => a.getTime() - b.getTime())[0]
        if (nextOpeningTime === undefined) {
            return null
        }
        return nextOpeningTime
    }

    private dateRangesExtent(
        filter: (rule: ScheduleRule) => boolean,
        date: Date,
        bookingInterval: number
    ): [Date, Date] | null {
        const periods = this.rules.map(rule => {
            return {
                isOpen: rule.period.open,
                applies: filter(rule),
                start: rule.period.start,
                end: rule.period.end,
            }
        })
        const openPeriods = periods
            .filter(period => period.applies && period.isOpen)
            .map(period => [period.start, period.end])
            .map(period => toDateTimeRange(period[0], period[1], date))
            .map(period => {
                const lastBookingSlot = new BookingSlot(period[1])
                const endDateTime = lastBookingSlot.endDateTime(bookingInterval)
                return [period[0], endDateTime] as [Date, Date]
            })
        const closedPeriods = periods
            .filter(period => period.applies && !period.isOpen)
            .map(period => [period.start, period.end])
            .map(period => toDateTimeRange(period[0], period[1], date))
        const sortedDates = [...openPeriods, ...closedPeriods]
            .flat()
            .sort((a, b) => a.getTime() - b.getTime())
        if (sortedDates.length === 0) {
            return null
        }
        return [sortedDates[0], sortedDates[sortedDates.length - 1]]
    }

    hasRuleThatAppliesToDate(date: Date): boolean {
        return this.services.some(service => service.hasRuleThatAppliesToDate(date))
    }

    openDateRangesForDateTime(date: Date, bookingInterval: number): [Date, Date][] {
        const periods = this.rules.map(rule => {
            return {
                isOpen: rule.period.open,
                applies: rule.appliesToDate(date),
                start: rule.period.start,
                end: rule.period.end,
            }
        })
        const openPeriods = periods
            .filter(period => period.applies && period.isOpen)
            .map(period => [period.start, period.end])
            .map(period => toDateTimeRange(period[0], period[1], date))
            .map(period => {
                const lastBookingSlot = new BookingSlot(period[1])
                const endDateTime = lastBookingSlot.endDateTime(bookingInterval)
                return [period[0], endDateTime] as [Date, Date]
            })
        const closedPeriods = periods
            .filter(period => period.applies && !period.isOpen)
            .map(period => [period.start, period.end])
            .map(period => toDateTimeRange(period[0], period[1], date))
        return coalesceDateRanges(openPeriods, closedPeriods)
    }

    arePaymentsEnabledAtDateTime(date: Date): boolean {
        const service = this.serviceWithOpenRuleAtDateTime(date)
        if (service === null) {
            return false
        }
        return service.arePaymentsEnabledAtDateTime(date)
    }

    dateTimeExtent(date: Date, bookingInterval: number): [Date, Date] {
        const services = this.servicesOperatingOnDate(date)
        if (services.length === 0) {
            return [new Date(), new Date()]
        }
        const serviceExtents = services
            .map(service => service.dateRangesExtentForDate(date, bookingInterval))
            .filter(extent => extent !== null)
            .map(extent => extent!)
        const earliest = serviceExtents
            .map(extent => extent[0])
            .sort((a, b) => a.getTime() - b.getTime())
            [0]
        const latest = serviceExtents
            .map(extent => extent[1])
            .sort((a, b) => b.getTime() - a.getTime())
            [0]
        let bookingDuration = this.defaultBookingDuration()
        let endOfLastPossibleBooking = new BookingSlot(latest).endDateTime(bookingDuration)
        return [earliest, endOfLastPossibleBooking]
    }

    getScheduleExceptionOccurringOnDate(date: Date): ScheduleException | null {
        if (!this.operatesOnDate(date)) {
            return null
        }
        return this.exceptions
            .find(exception => exception.appliesToDate(date))
            ?? null
    }
}
