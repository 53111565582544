export class Brand {

    constructor(
        public brandColour: string | null,
        public bannerImageId: string | null,
        public facebookUrl: string | null,
        public instagramUrl: string | null,
        public twitterUrl: string | null,
        public websiteUrl: string | null
    ) { }

    hasSocialMedia(): boolean {
        return !!this.facebookUrl || !!this.instagramUrl || !!this.twitterUrl
    }
}
