import { CancellationChargeDTO } from '@services/DTO/CancellationChargeDTO'

export class CancellationDTO {
    constructor(
        public id: string,
        public paymentMethodId: string | null,
        public unitAmount: number,
        public currencyCode: string,
        public isPerCover: boolean,
        public partySize: number,
        public cutOffHours: number | null,
        public chargeAutomatically: boolean,
        public charges: CancellationChargeDTO[],
        public dateCreated: string
    ) { }
}
