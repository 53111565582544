import { AreaSchedule } from './AreaSchedule'
import { DiaryNote } from '@app/domain/DiaryNote'

export class VenueSchedule {

    constructor(
        public diaryNotes: DiaryNote[],
        public areas: AreaSchedule[]
    ) { }
}
