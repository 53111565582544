import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs'
import { Injectable } from '@angular/core'
import { ScreenSize } from '@app/domain/ScreenSize'

@Injectable({
    providedIn: 'root',
})
export class ScreenSizeService {

    get screenSize$(): Observable<ScreenSize> {
        return this.screenSize.asObservable().pipe(distinctUntilChanged())
    }

    private screenSize = new BehaviorSubject<ScreenSize>(ScreenSize.LG)

    constructor() { }

    screenSizeChanged(size: ScreenSize) {
        this.screenSize.next(size)
    }
}
