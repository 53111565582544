export class WidgetConfiguration {

    constructor(
        public phoneNumberRequired: boolean,
        public reserveWithGoogleEnabled: boolean,
        public reserveWithGoogleReasonId: string | null,
        public primaryColour: string | null,
        public pinnedEventId: string | null
    ) { }
}
