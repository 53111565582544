import { Pipe, PipeTransform } from '@angular/core'

export interface ThumborOptions {
    aspectFit?: boolean
    width?: number
    height?: number
}

@Pipe({
    name: 'thumbor',
})
export class ThumborPipe implements PipeTransform {

    transform(
        baseUrl: string,
        imagePath: string,
        options?: ThumborOptions
    ): string {
        if (!options) {
            return `${baseUrl}/${imagePath}`
        }
        const { aspectFit, width, height } = options
        return `${baseUrl}` +
            `${aspectFit ? '/fit-in' : ''}` +
            `/${width}x${height}` +
            `/${imagePath}`
    }
}
