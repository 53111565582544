import { DateRange, dateRangeEquals } from '@app/domain/date-range'
import { Helper } from '@app/helpers/utilities/helper'
import { Pipe, PipeTransform } from '@angular/core'

export enum DateRangeOption {
    Today = 'Today',
    ThisWeek = 'This Week',
    ThisMonth = 'This Month',
}

@Pipe({
    name: 'commonDateRange',
})
export class CommonDateRangePipe implements PipeTransform {

    transform(dateRange: DateRange, option?: DateRangeOption, customDateRange?: DateRange): boolean {
        if (option === undefined && customDateRange !== undefined) {
            return dateRangeEquals(dateRange, customDateRange)
        }
        switch (option) {
        case DateRangeOption.Today:
            return this.isTodaySelected(dateRange)
        case DateRangeOption.ThisWeek:
            return this.isThisWeekSelected(dateRange)
        case DateRangeOption.ThisMonth:
            return this.isThisMonthSelected(dateRange)
        }
        return false
    }

    private isTodaySelected(dateRange: DateRange): boolean {
        const todayDateRange = {
            fromDate: Helper.startOfDay(),
            toDate: Helper.endOfDay(),
        }
        return dateRangeEquals(dateRange, todayDateRange)
    }

    private isThisWeekSelected(dateRange: DateRange): boolean {
        const thisWeekDateRange = {
            fromDate: Helper.startOfWeek(),
            toDate: Helper.endOfWeek(),
        }
        return dateRangeEquals(dateRange, thisWeekDateRange)
    }

    private isThisMonthSelected(dateRange: DateRange): boolean {
        const thisMonthDateRange = {
            fromDate: Helper.startOfMonth(),
            toDate: Helper.endOfMonth(),
        }
        return dateRangeEquals(dateRange, thisMonthDateRange)
    }
}
