<app-spinner-overlay
    [showSpinner]="isAddingCharge"
    xmlns="http://www.w3.org/1999/html">
</app-spinner-overlay>
<div class="modal-header">
    <h4 class="mb-0">
        @if (chargeType === 'cancellationCharge') {
            Add Cancellation Charge
        } @else if (chargeType === 'depositCharge') {
            Add Deposit Charge
        } @else if (booking.pendingCancellationCharge !== null) {
            Edit Cancellation Charge
        } @else if (booking.pendingDepositCharge !== null) {
            Edit Deposit Charge
        }
    </h4>
    <button
        type="button"
        class="btn btn-close"
        (click)="dismiss()"
    >
    </button>
</div>
<div class="modal-body">
    <form
        [formGroup]="form"
    >
        <div class="vstack gap-3">
            <div class="bg-light rounded-3 p-3">
                <div class="vstack gap-2">
                    <span class="fw-semibold fs-6">
                        @if (charge !== null) {
                            {{ null | currencyUnit : charge.amount : 'GBP' }}
                            @if (charge.isPerCover) {
                                ({{ null | currencyUnit : charge.unitAmount : 'GBP' }}
                                x
                                {{ booking.size }})
                            }
                        } @else {
                            No Charge
                        }
                    </span>
                    @if (expiryDate !== null) {
                        <hr class="my-1" />
                        <span
                            class="text-muted px-1"
                        >
                            Expires {{ expiryDate | date: 'short' }}
                        </span>
                    }
                </div>
            </div>
            <div class="vstack gap-1">
                <label
                    for="amount"
                    class="form-label m-0 fw-semibold"
                >
                    Amount
                </label>
                <div class="vstack gap-1">
                    <div class="hstack gap-2">
                        <div class="input-group">
                            <span class="input-group-text">£</span>
                            <input
                                type="number"
                                class="form-control"
                                formControlName="amount"
                                placeholder="Amount"
                            />
                            <span class="input-group-text">.00</span>
                        </div>
                        <div
                            class="d-flex btn-group segmented-control justify-content-evenly"
                            role="group"
                        >
                            <input
                                type="radio"
                                class="btn-check"
                                id="perCover"
                                autocomplete="off"
                                [value]="true"
                                formControlName="isPerCover"
                            >
                            <label
                                class="btn btn-outline-primary text-nowrap"
                                for="perCover"
                                (click)="form.get('isPerCover')?.setValue(true)"
                            >
                                Per Cover
                            </label>
                            <input
                                type="radio"
                                class="btn-check"
                                id="perParty"
                                autocomplete="off"
                                [value]="false"
                                formControlName="isPerCover"
                            >
                            <label
                                class="btn btn-outline-primary text-nowrap"
                                for="perParty"
                                (click)="form.get('isPerCover')?.setValue(false)"
                            >
                                Flat Rate
                            </label>
                        </div>
                    </div>
                    <span class="form-text">
                        @if (chargeType === 'cancellationCharge') {
                            The amount the customer could be charged
                                if you decide to charge them for cancelling / not showing up.
                        } @else if (chargeType === 'depositCharge') {
                            The amount to charge for securing the booking.
                        }
                    </span>
                </div>
            </div>
            @if (form.get('linkDeliveryMethod') !== null) {
                <div class="vstack gap-2">
                    <label
                        class="form-label m-0 fw-semibold"
                    >
                        Send to:
                    </label>
                    <div class="vstack gap-2 align-content-stretch">
                        <label
                            class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                            [class.disabled]="booking.emailAddress === null"
                        >
                            <div class="m-1 hstack gap-2">
                                <input
                                    type="radio"
                                    [value]="'email'"
                                    formControlName="linkDeliveryMethod"
                                    class="form-check-input m-0"
                                    role="button"
                                >
                                <div class="vstack align-items-start">
                            <span
                                *ngIf="booking.emailAddress !== null"
                                class="fw-semibold"
                            >
                                Email: {{ booking.emailAddress }}
                            </span>
                            <span
                                *ngIf="booking.emailAddress === null"
                                class="fw-normal"
                            >
                                Email: Email address not provided
                            </span>
                                </div>
                            </div>
                        </label>
                        <label
                            class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                            [class.disabled]="booking.phoneNumber === null"
                        >
                            <div class="m-1 hstack gap-2">
                                <input
                                    type="radio"
                                    [value]="'sms'"
                                    formControlName="linkDeliveryMethod"
                                    class="form-check-input m-0"
                                    role="button"
                                >
                                <div class="vstack align-items-start">
                            <span
                                *ngIf="booking.phoneNumber !== null"
                                class="fw-semibold"
                            >
                                SMS: {{ booking.phoneNumber | phoneNumber }}
                            </span>
                            <span
                                *ngIf="booking.phoneNumber === null"
                                class="fw-normal"
                            >
                                SMS: Phone number not provided
                            </span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            }
            @if (form.get('expiryMinutes') !== null) {
                <div class="vstack gap-2">
                    <label
                        for="expiryMinutes"
                        class="form-label m-0 fw-semibold"
                    >
                        Link Duration
                    </label>
                    <select
                        class="form-select"
                        formControlName="expiryMinutes"
                    >
                        @for (minutes of addPaymentExpiryOptions; track minutes) {
                            @if (minutes === null) {
                                <option
                                    [value]="null"
                                >
                                    Never Expires
                                </option>
                            } @else {
                                <option
                                    [value]="minutes"
                                >
                                    {{ minutes | duration : DurationUnit.Minute | titlecase }}
                                </option>
                            }
                        }
                    </select>
                    <span class="form-text">
                        How long the link will be valid for before it expires and the booking is cancelled.
                    </span>
                </div>
            }
        </div>
    </form>
</div>
<div
    class="modal-footer"
>
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="dismiss()"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="form.invalid"
        (click)="save()"
    >
        @if (form.get('linkDeliveryMethod') !== null) {
            Send Link
        } @else {
            Update
        }
    </button>
</div>
