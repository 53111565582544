import { Area } from './Area'
import { VenueClosure } from '@app/domain/VenueClosure'

export class AreaSchedule {

    constructor(
        public area: Area,
        public closures: VenueClosure[]
    ) { }
}
