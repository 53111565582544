import { Booking } from '@app/domain/Booking'
import { Business } from '@app/domain/Business'
import { ContextService } from '@services/context.service'
import { DTOAdapter } from '@services/DTOAdapter'
import { Dinero } from 'dinero.js'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, lastValueFrom, map, mergeMap } from 'rxjs'
import { PaymentsAccount } from '@app/domain/PaymentsAccount'
import { PaymentsAccountDetails } from '@app/domain/PaymentsAccountDetails'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { environment } from '@environments/environment'
import { filterNull } from '@app/features/shared/rjxs/filter.null'

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {

    public stripe: Promise<Stripe>

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter,
        private contextService: ContextService
    ) {
        this.stripe = lastValueFrom(
            this.contextService.business$.pipe(
                map(business => business.paymentsAccount?.id ?? null),
                filterNull(),
                mergeMap(paymentsAccountId => {
                    return this.initialiseStripe(paymentsAccountId)
                })
            )
        )
    }

    private initialiseStripe(paymentsAccountId: string) {
        return new Promise<Stripe>((resolve) => {
            resolve(
                loadStripe(environment.stripePublishableKey, {
                    stripeAccount: paymentsAccountId,
                })
                    .then((stripe) => {
                        if (!stripe) {
                            throw new Error('Stripe not loaded')
                        }
                        return stripe
                    })
            )
        })
    }

    createPaymentsAccount(business: Business): Observable<PaymentsAccount> {
        let path = `organisation/business/${business.id}/payments/account`
        let url = new URL(path, environment.apiBaseURL)
        return this.http.post<PaymentsAccount>(url.toString(), null)
            .pipe(
                map(dto => this.dtoAdapter.adaptPaymentsAccountDto(dto))
            )
    }

    createPaymentsAccountLink(business: Business): Observable<URL> {
        let path = `organisation/business/${business.id}/payments/account/link`
        let url = new URL(path, environment.apiBaseURL)
        return this.http.post<URL>(url.toString(), null)
            .pipe(
                map(dto => new URL(dto))
            )
    }

    getPaymentsAccountDetails(business: Business): Observable<PaymentsAccountDetails> {
        let path = `organisation/business/${business.id}/payments/account/details`
        let url = new URL(path, environment.apiBaseURL)
        return this.http.get<PaymentsAccountDetails>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptPaymentsAccountDetailsDto(dto))
            )
    }

    chargeCancellationFee(
        business: Business,
        booking: Booking,
        amount: Dinero
    ): Observable<void> {
        let params = new HttpParams()
            .set('amount', amount.toUnit())
        let path = `organisation\
/business/${business.id}\
/payments\
/booking/${booking.id}\
/cancellation-fee\
?${params.toString()}`
        let url = new URL(path, environment.apiBaseURL)
        return this.http.post<void>(url.toString(), { })
    }
}

export class Payment {
    constructor(
        public clientSecret: string
    ) { }
}
