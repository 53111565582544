import { CurrencyPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import DineroFactory, { Currency, Dinero } from 'dinero.js'

@Pipe({
    name: 'currencyUnit',
})
export class CurrencyUnitPipe implements PipeTransform {

    constructor(
        private currencyPipe: CurrencyPipe
    ) { }

    transform(
        money: Dinero | null,
        amount?: number,
        currencyCode?: string
    ): string | null {
        const dinero = money ?? this.makeMoney(amount, currencyCode)
        if (dinero === null) {
            return null
        }
        return this.makeMoneyString(dinero)
    }

    private makeMoney(amount: number | undefined, currencyCode: string | undefined): Dinero | null {
        if (amount === undefined) {
            return null
        }
        if (currencyCode === undefined) {
            return null
        }
        return DineroFactory({
            amount: amount,
            currency: currencyCode as Currency,
        })
    }

    private makeMoneyString(money: Dinero): string | null {
        return this.currencyPipe.transform(money.toUnit(), money.getCurrency())
    }
}
