<svg:ng-container>
    <svg:foreignObject
        [attr.x]="xScale(tableViewModel.coordinates.x)"
        [attr.y]="yScale(tableViewModel.coordinates.y)"
        [attr.width]="xScale(tableViewModel.dimensions.width)"
        [attr.height]="yScale(tableViewModel.dimensions.height)"
    >
        <div class="h-100 w-100 position-relative">
            <div
                class="floor-table vstack gap-1 p-1 align-items-center justify-content-center h-100 w-100"
                [ngClass]="bookedStatus"
            >
                <div class="table-name text-center">
                    {{ tableViewModel.table.shortFormattedDisplayName }}
                </div>
                <div class="hstack gap-1 justify-content-center">
                    <img
                        class="icon-xs"
                        src="assets/people/normal.svg"
                    />
                    <span class="covers">
                        {{ tableViewModel.table.minimumSeats }} - {{ tableViewModel.table.maximumSeats }}
                    </span>
                </div>
            </div>
            <div class="resize-handle top-left"></div>
            <div class="resize-handle top"></div>
            <div class="resize-handle top-right"></div>
            <div class="resize-handle right"></div>
            <div class="resize-handle bottom-right"></div>
            <div class="resize-handle bottom"></div>
            <div class="resize-handle bottom-left"></div>
            <div class="resize-handle left"></div>
        </div>
    </svg:foreignObject>
</svg:ng-container>
