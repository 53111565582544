import { BookingReason } from '@app/domain/BookingReason'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'reasonDateRanges',
})
export class ReasonDateRangesPipe implements PipeTransform {

    transform(reason: BookingReason): string | null {
        if (reason.dateRangeFilters.length === 0) {
            return 'Always Available'
        }
        const maxDateRanges = 2
        const now = new Date()
        return reason.dateRangeFilters
            .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
            .slice(0, maxDateRanges)
            .map(dateRange => {
                return `${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`
            })
            .join('\n')
            .concat(reason.dateRangeFilters.length > maxDateRanges ? `\nand ${reason.dateRangeFilters.length - maxDateRanges} more` : '')
    }
}
