import { Booking } from '@app/domain/Booking'
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

export type Options = {
    showBookingDateTime: boolean,
    showBookingTime: boolean,
}

@Component({
    selector: 'app-bookings-list',
    templateUrl: './bookings-list.component.html',
})
export class BookingsListComponent implements OnChanges {

    @Input() bookings: Booking[] = []
    @Input() options?: Partial<Options>
    @Output() bookingSelected = new EventEmitter<Booking>()
    showBookingDateTime = true
    showBookingTime = false

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['options']) {
            this.showBookingDateTime = this.options?.showBookingDateTime ?? true
            this.showBookingTime = this.options?.showBookingTime ?? false
        }
    }

    bookingClicked(booking: Booking) {
        this.bookingSelected.emit(booking)
    }
}
