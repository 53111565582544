import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import {
    ElementChoice,
} from '@app/features/shared/components/element-choice-select/element-choice'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-element-choice-select',
    templateUrl: './element-choice-select.component.html',
    styleUrls: ['./element-choice-select.component.sass'],
})
export class ElementChoiceSelectComponent<T> implements OnChanges {

    @Input() canChangeElement!: boolean
    @Input() startingSelection?: ElementChoice<T>
    selectedElement?: ElementChoice<T>
    @Input() elementChoices?: Observable<ElementChoice<T>[]>
    @Input() dataTestId?: string

    @Output()
        loadElements: EventEmitter<T[]> = new EventEmitter<T[]>()
    @Output()
        elementChosen: EventEmitter<T> = new EventEmitter<T>()

    constructor() { }

    ngOnChanges() {
        this.selectedElement = this.startingSelection
    }

    loadElementChoices() {
        this.loadElements.emit()
    }

    selectNewElement(target: EventTarget, choices: ElementChoice<T>[]) {
        const selectTarget = target as HTMLSelectElement
        const id = selectTarget.value
        this.selectedElement = choices.find((choice) => choice.id === id)
    }

    cancelElementUpdate() {
        this.selectedElement = this.startingSelection
        this.elementChoices = undefined
    }

    finishElementUpdate() {
        this.elementChoices = undefined
    }

    saveElementUpdate() {
        this.elementChosen.emit(this.selectedElement?.value)
        this.elementChoices = undefined
    }
}
