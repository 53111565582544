
<ng-container
    *ngIf="isSavingSelection | async as isSavingSelection"
>
    <app-spinner-overlay
        [showSpinner]="isSavingSelection"
    >
    </app-spinner-overlay>
</ng-container>
<div class="modal-header d-flex justify-content-between">
    <h4 class="mb-0 hstack gap-2">
        Tables
        <span>
            {{ startDate | date:'shortTime' }}
            -
            {{ endDate | date:'shortTime' }}
        </span>
        <span
            *ngIf="partySize !== null"
            class="d-none d-md-block"
        >
            Party of {{ partySize }}
        </span>
        @if (additionalContextTitle) {
            ({{ additionalContextTitle }})
        }
    </h4>
    <button
        type="button"
        class="btn btn-close"
        (click)="cancelClicked()"
        ngbAutofocus
    >
    </button>
</div>
<div
    class="modal-body p-0"
>
    <app-venue-floors
        class="d-none d-md-block"
        [venue]="venue"
        [selectedArea]="selectedArea"
        [viewModels]="tableViewModels"
        (areaSelected)="areaSelected($event)"
    >
        <div
            class="ps-lg-4 pt-3 pb-3 pb-lg-0 col-auto col-lg-4 d-none d-md-block"
        >
            <ng-container *ngTemplateOutlet="selectedTableList">
            </ng-container>
        </div>
    </app-venue-floors>
    <div
        class="d-block d-md-none p-3 pt-md-0"
    >
        <ng-container *ngTemplateOutlet="selectedTableList">
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="cancelClicked()"
    >
        Cancel
    </button>
    <button
        *ngIf="{ value: canUseSelectedTables | async } as canUseSelectedTables"
        type="button"
        class="btn btn-primary"
        data-testid="save-tables-button"
        [disabled]="!canUseSelectedTables.value"
        (click)="saveClicked()"
    >
        Choose Tables
    </button>
</div>

<ng-template #selectedTableList>
    <ng-container
        *ngIf="selectedTables$ | async as selectedTables"
    >
        <h5
            *ngIf="selectedTables.length === 0"
            class="text-center"
        >
            No tables selected
        </h5>
        <h5
            *ngIf="selectedTableCapacity$ | async as selectedTablesCapacity"
            class="text-center"
        >
            Selected Capacity: {{ selectedTablesCapacity }}
        </h5>
        <ul class="mt-3 list-group">
            <li
                *ngFor="let table of selectedTables"
                class="list-group-item"
            >
                <div class="hstack gap-3 align-items-center">
                    <div class="flex-grow-1 fw-semibold">
                        <span class="hstack gap-3">
                            <span class="text-body me-auto">
                                {{ table.formattedDisplayName }}
                            </span>
                            <div class="hstack gap-2">
                                <img
                                    class="icon-xs"
                                    src="assets/people/normal.svg"
                                />
                                <span class="text-muted">
                                    {{ table.minimumSeats }} - {{ table.maximumSeats }}
                                </span>
                            </div>
                        </span>
                    </div>
                    <div>
                        <button
                            type="button"
                            class="btn btn-close"
                            (click)="removeTableClicked(table)"
                        >
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <div class="mt-3 d-flex gap-2">
            <div
                class="flex-fill-equally"
            >
                <button
                    type="button"
                    class="btn btn-outline-secondary w-100"
                    [disabled]="selectedTables.length === 0"
                    (click)="clearTablesClicked()"
                >
                    Clear Tables
                </button>
            </div>
            <div
                *ngIf="freeTables$ | async as freeTables"
                class="d-inline-block d-md-none flex-fill-equally position-relative"
            >
                    <button
                        type="button"
                        class="btn btn-outline-secondary compact w-100"
                    >
                        Add Table
                    </button>
                <select
                    class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                    id="tableSelect"
                    (change)="tableIdSelected(freeTables, $event.target!)"
                >
                    <option>
                        Select a Table
                    </option>
                    <option
                        *ngFor="let table of freeTables"
                        [value]="table.id"
                    >
                        {{ table.formattedDisplayName }} ({{ table.minimumSeats }} - {{ table.maximumSeats }})
                    </option>
                </select>
            </div>
        </div>
    </ng-container>
</ng-template>
