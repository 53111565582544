import { Charge } from '@app/domain/Charge'

export class CancellationCharge {
    constructor(
        public id: string,
        public amount: number,
        public currencyCode: string,
        public partySize: number,
        public paymentIntentId: string,
        public dateCreated: Date
    ) { }

    public charge(): Charge {
        return new Charge(
            this.partySize,
            null,
            null,
            this.amount,
            false
        )
    }
}
