import { CreateUserRequest } from '@app/domain/CreateUserRequest'
import { DTOAdapter } from '@services/DTOAdapter'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'
import { Organisation } from '@app/domain/Organisation'
import { User } from '@app/domain/User'
import { UserDTO } from '@services/DTO/UserDTO'
import { compare } from 'fast-json-patch'
import { environment } from '@environments/environment'

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    create(
        organisation: Organisation,
        createUserRequest: CreateUserRequest
    ): Observable<User> {
        const path = '/organisation/' + organisation.id + '/user'
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post<UserDTO>(url.toString(), createUserRequest)
            .pipe(
                map(dto => this.dtoAdapter.adaptUserDto(dto))
            )
    }

    get(): Observable<User> {
        const url = new URL('/user', environment.apiBaseURL)
        return this.http.get<UserDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptUserDto(dto))
            )
    }

    getAllInOrganisation(
        organisation: Organisation
    ): Observable<User[]> {
        const path = '/organisation/' + organisation.id + '/users'
        const url = new URL(path, environment.apiBaseURL)
        return this.http.get<UserDTO[]>(url.toString())
            .pipe(
                map(dtos => dtos.map(dto => this.dtoAdapter.adaptUserDto(dto)))
            )
    }

    update(
        organisation: Organisation,
        user: User,
        updatedUser: User
    ): Observable<void> {
        const path = '/organisation/' + organisation.id + '/user/' + user.id
        const url = new URL(path, environment.apiBaseURL)
        const dto = this.dtoAdapter.adaptUser(user)
        const updatedDto = this.dtoAdapter.adaptUser(updatedUser)
        const patch = compare(dto, updatedDto)
        return this.http.patch(url.toString(), patch)
            .pipe(map(() => { }))
    }
}
