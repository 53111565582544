import { ScheduleRuleDTO } from './ScheduleRuleDTO'

export class ServiceDTO {

    constructor(
        public id: string,
        public displayName: string,
        public rules: ScheduleRuleDTO[]
    ) { }
}
