import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'ratingName',
})
export class RatingNamePipe implements PipeTransform {

    transform(rating: number): string {
        switch (rating) {
        case 1:
            return 'Very Poor'
        case 2:
            return 'Poor'
        case 3:
            return 'Fair'
        case 4:
            return 'Good'
        case 5:
            return 'Excellent'
        default:
            return 'Unknown'
        }
    }

}
