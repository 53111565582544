import { Question, QuestionType } from './Question'

export class Answer {
    constructor(
        public question: Question,
        public answer: string
    ) { }

    isTicked() {
        if (this.question.type === QuestionType.Checkbox) {
            return this.answer === 'true'
        }
        if (this.question.type === QuestionType.AcknowledgeEndTime) {
            return this.answer === 'true'
        }
        return false
    }
}
