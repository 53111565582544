import { BehaviorSubject, Observable, finalize, map } from 'rxjs'
import { Booking } from '@app/domain/Booking'
import { BookingDTO } from '@services/DTO/BookingDTO'
import { BookingStatus, BookingStatusType } from '@app/domain/BookingStatus'
import { Business } from '@app/domain/Business'
import { Charge } from '../domain/Charge'
import { Contact } from '../domain/Contact'
import { Customer } from '../domain/Customer'
import { DTOAdapter } from '@services/DTOAdapter'
import { Helper } from '@app/helpers/utilities/helper'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ModelCloningService } from '@services/model-cloning.service'
import { Organisation } from '@app/domain/Organisation'
import { Page } from '@app/domain/Page'
import { Table } from '@app/domain/Table'
import { User } from '@app/domain/User'
import { Venue } from '@app/domain/Venue'
import { compare } from 'fast-json-patch'
import { environment } from '@environments/environment'
import { v4 as uuidv4 } from 'uuid'

export type AddBookingOptions = {
    suppressCommunication?: boolean,
    sendPendingLinkViaEmail?: boolean,
    sendPendingLinkViaSMS?: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class BookingService {

    private _loading$ = new BehaviorSubject<boolean>(true)

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter,
        private modelCloningService: ModelCloningService
    ) { }

    getBooking(bookingId: string): Observable<Booking> {
        const path =
            `/organisation/booking/${bookingId}`
        const url = new URL(path, environment.apiBaseURL)
        this._loading$.next(true)
        return this.http.get<BookingDTO>(url.toString())
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    getBookings(
        businessId: string,
        venueId: string,
        startDateTime: Date,
        endDateTime: Date,
        statuses?: BookingStatusType[]
    ): Observable<Booking[]> {
        let params = new HttpParams()
            .set('startTime', Helper.makeLocalISOFormattedDateTimeString(startDateTime))
            .set('endTime', Helper.makeLocalISOFormattedDateTimeString(endDateTime))
        if (statuses) {
            params = params.set('statuses', statuses.join(','))
        }
        let path = `/organisation
/business/${businessId}
/venue/${venueId}
/booking`
        const url = new URL(path, environment.apiBaseURL)
        this._loading$.next(true)
        return this.http.get<BookingDTO[]>(url.toString(), { params })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dtos => dtos.map(dto => this.dtoAdapter.adaptBookingDto(dto)))
            )
    }

    getBookingsByDateBooked(
        organisationId: string,
        businessId: string,
        venueId: string,
        startDateTime: Date,
        endDateTime: Date,
        statuses?: BookingStatusType[]
    ): Observable<Booking[]> {
        let params = new HttpParams()
            .set('startTime', Helper.makeLocalISOFormattedDateTimeString(startDateTime))
            .set('endTime', Helper.makeLocalISOFormattedDateTimeString(endDateTime))
        if (statuses) {
            params = params.set('statuses', statuses.join(','))
        }
        let path = `/organisation/${organisationId}` +
        `/business/${businessId}` +
        `/venue/${venueId}` +
        '/booking?byDateBooked=true'
        const url = new URL(path, environment.apiBaseURL)
        this._loading$.next(true)
        return this.http.get<BookingDTO[]>(url.toString(), { params })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dtos => dtos.map(dto => this.dtoAdapter.adaptBookingDto(dto)))
            )
    }

    searchBookings(
        businessId: string,
        venueId: string,
        isInFuture: boolean,
        searchTerm: string,
        page: number,
        pageSize: number,
        direction: 'asc' | 'desc'
    ): Observable<Page<Booking>> {
        let path = `/organisation
/business/${businessId}
/venue/${venueId}
/booking
/search`
        const url = new URL(path, environment.apiBaseURL)
        const params = {
            isInFuture: isInFuture.toString(),
            searchTerm: searchTerm,
            page: page.toString(),
            pageSize: pageSize.toString(),
            direction: direction,
        }
        this._loading$.next(true)
        return this.http.get<Page<BookingDTO>>(url.toString(), { params })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(page => {
                    return {
                        ...page,
                        items: page.items.map(dto => this.dtoAdapter.adaptBookingDto(dto)),
                    }
                })
            )
    }

    getCustomerBookings(
        organisationId: string,
        businessId: string,
        customer: Customer,
        page: number,
        pageSize: number,
        direction: 'asc' | 'desc'
    ): Observable<Page<Booking>> {
        let path = `/organisation/${organisationId}
/business/${businessId}
/venue/${customer.venueId}
/booking`
        const url = new URL(path, environment.apiBaseURL)
        const params = {
            customerId: customer.id,
            page: page.toString(),
            pageSize: pageSize.toString(),
            direction: direction,
        }
        this._loading$.next(true)
        return this.http.get<Page<BookingDTO>>(url.toString(), { params })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(page => {
                    return {
                        ...page,
                        items: page.items.map(dto => this.dtoAdapter.adaptBookingDto(dto)),
                    }
                })
            )
    }

    addBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        booking: Booking,
        idempotencyKey: string,
        options?: Partial<AddBookingOptions>
    ): Observable<Booking> {
        let path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/area/${areaId}` +
            '/booking'
        const dto = this.dtoAdapter.adaptBooking(booking)
        const url = new URL(path, environment.apiBaseURL)
        if (options?.suppressCommunication) {
            url.searchParams.append('suppressCommunication', 'true')
        }
        if (options?.sendPendingLinkViaEmail) {
            url.searchParams.append('sendPendingLinkViaEmail', 'true')
        }
        if (options?.sendPendingLinkViaSMS) {
            url.searchParams.append('sendPendingLinkViaSMS', 'true')
        }
        const headers = {
            'Idempotency-Key': idempotencyKey,
        }
        this._loading$.next(true)
        return this.http.post<BookingDTO>(url.toString(), dto, { headers })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    addBookings(
        businessId: string,
        venueId: string,
        bookings: Booking[],
        idempotencyKey: string
    ): Observable<Booking[]> {
        let path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            '/booking' +
            '/multiple'
        const dtos = bookings.map(booking => this.dtoAdapter.adaptBooking(booking))
        const url = new URL(path, environment.apiBaseURL)
        const headers = {
            'Idempotency-Key': idempotencyKey,
        }
        this._loading$.next(true)
        return this.http.post<BookingDTO[]>(url.toString(), dtos, { headers })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dtos => dtos.map(dto => this.dtoAdapter.adaptBookingDto(dto)))
            )
    }

    acceptBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.Booked
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    cancelBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string,
        user: User,
        paymentOptions: Partial<{
            mechanism: 'cancellationCharge' | 'depositRefund',
        }>
    ): Observable<Booking> {
        const metadata = new Map<string, any | null>()
        metadata.set('cancelledByCustomer', false)
        metadata.set('cancelledByUserId', user.id)
        metadata.set('cancelledByUserEmailAddress', user.emailAddress)
        const status = new BookingStatus(
            uuidv4(),
            metadata,
            new Date(),
            BookingStatusType.Cancelled
        )
        if (paymentOptions.mechanism === 'cancellationCharge') {
            return this.chargeCancellationFee(
                businessId,
                venueId,
                areaId,
                bookingId,
                status
            )
        } else if (paymentOptions.mechanism === 'depositRefund') {
            return this.refundDeposit(
                businessId,
                venueId,
                areaId,
                bookingId,
                status
            )
        }
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    noShowBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string,
        paymentOptions: Partial<{
            mechanism: 'cancellationCharge' | 'depositRefund',
        }>
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.NoShow
        )
        if (paymentOptions.mechanism === 'cancellationCharge') {
            return this.chargeCancellationFee(
                businessId,
                venueId,
                areaId,
                bookingId,
                status
            )
        } else if (paymentOptions.mechanism === 'depositRefund') {
            return this.refundDeposit(
                businessId,
                venueId,
                areaId,
                bookingId,
                status
            )
        }
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    setPartyToWaiting(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.Waiting
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    partiallySeatBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.PartiallySeated
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    seatBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        booking: Booking
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.Seated
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            booking.id,
            status
        )
    }

    unseatBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.Booked
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    finishBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.Finished
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    finishBookingEarly(
        businessId: string,
        venueId: string,
        bookingId: string,
        finishFromNowMinutes?: number
    ): Observable<Booking> {
        const path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/booking/${bookingId}` +
            '/finish'
        const url = new URL(path, environment.apiBaseURL)
        const queryParameters = {
            finishFromNowMinutes: finishFromNowMinutes?.toString() || '0',
        }
        return this.http.post<BookingDTO>(url.toString(), null, { params: queryParameters })
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    finishAllInProgressBookings(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        date: Date
    ) {
        const path = `/organisation/${organisation.id}` +
            `/business/${business.id}` +
            `/venue/${venue.id}` +
            '/booking/finish'
        const url = new URL(path, environment.apiBaseURL)
        const queryParameters = {
            date: Helper.makeLocalISOFormattedDateString(date),
        }
        return this.http.post<BookingDTO[]>(url.toString(), null, { params: queryParameters })
    }

    rejectBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string,
        reason: string | null
    ): Observable<Booking> {
        const metadata = new Map<string, any | null>()
        if (reason) {
            metadata.set('reason', reason)
        }
        const status = new BookingStatus(
            uuidv4(),
            metadata,
            new Date(),
            BookingStatusType.Rejected
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    reseatBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string
    ): Observable<Booking> {
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            BookingStatusType.Seated
        )
        return this.addStatusToBooking(
            businessId,
            venueId,
            areaId,
            bookingId,
            status
        )
    }

    voidBooking(
        businessId: string,
        venueId: string,
        booking: Booking
    ): Observable<Booking> {
        let path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/booking/${booking.id}` +
            '/void'
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post<BookingDTO>(url.toString(), null)
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    patchBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string,
        booking: Booking,
        updatedBooking: Booking
    ): Observable<Booking> {
        let bookingDTO = this.dtoAdapter.adaptBooking(booking)
        let updatedBookingDTO = this.dtoAdapter.adaptBooking(updatedBooking)
        let patch = compare(bookingDTO, updatedBookingDTO)
        let body = Helper.rewriteUndefinedValuesAsNull(patch)
        body = Helper.rewriteEmptyValuesAsNull(body)
        const path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/area/${areaId}` +
            `/booking/${bookingId}`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.patch<BookingDTO>(url.toString(),body)
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    moveBookingsToTables(
        businessId: string,
        venueId: string,
        bookingsOnTablesAtNewDates: {
            booking: Booking,
            tables: Table[],
            start?: Date,
        }[]
    ): Observable<Booking[]> {
        const path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            '/booking/move'
        const url = new URL(path, environment.apiBaseURL)
        const dtos = bookingsOnTablesAtNewDates.map(({ booking, tables, start }) => {
            return this.dtoAdapter.adaptMoveBookingRequest(booking, tables, start)
        })
        return this.http.post<BookingDTO[]>(url.toString(), dtos)
            .pipe(
                map(dtos => dtos.map(dto => this.dtoAdapter.adaptBookingDto(dto)))
            )
    }

    changeBookingDate(
        organisationId: string,
        businessId: string,
        venueId: string,
        bookingId: string,
        newStartDateTime: Date,
        newDuration?: number,
        newTableIds?: string[],
        newStatus?: BookingStatus
    ): Observable<Booking> {
        const path = `/organisation/${organisationId}` +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/booking/${bookingId}` +
            '/start'
        const url = new URL(path, environment.apiBaseURL)
        let params = new HttpParams()
            .set('start', Helper.makeLocalISOFormattedDateTimeString(newStartDateTime))
        if (newDuration) {
            params = params.set('duration', newDuration.toString())
        }
        if (newTableIds) {
            params = params.set('tableIds', newTableIds.join(','))
        }
        const body = newStatus ? this.dtoAdapter.adaptBookingStatus(newStatus) : null
        return this.http.post<BookingDTO>(url.toString(), body, { params })
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    createNewCustomerAttachedToBooking(
        organisationId: string,
        businessId: string,
        venueId: string,
        bookingId: string,
        contact: Contact
    ): Observable<Booking> {
        const path = `/organisation/${organisationId}` +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/booking/${bookingId}` +
            '/customer'
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post<BookingDTO>(url.toString(), contact)
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    attachCustomerToBooking(
        organisationId: string,
        businessId: string,
        venueId: string,
        bookingId: string,
        customerId: string
    ): Observable<Booking> {
        const path = `/organisation/${organisationId}` +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/booking/${bookingId}` +
            '/customer'
        const queryParameters = {
            customerId: customerId,
        }
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post<BookingDTO>(url.toString(), null, { params: queryParameters })
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    detachCustomerFromBooking(
        organisationId: string,
        businessId: string,
        venueId: string,
        bookingId: string
    ): Observable<Booking> {
        const path = `/organisation/${organisationId}` +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/booking/${bookingId}` +
            '/customer'
        const url = new URL(path, environment.apiBaseURL)
        return this.http.delete<BookingDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    getVenueActiveTableCount(
        businessId: string,
        venueId: string,
        startDateTime: Date,
        endDateTime: Date
    ): Observable<number> {
        const path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            '/booking/count'
        const url = new URL(path, environment.apiBaseURL)
        let params = new HttpParams()
            .set('startTime', Helper.makeLocalISOFormattedDateTimeString(startDateTime))
            .set('endTime', Helper.makeLocalISOFormattedDateTimeString(endDateTime))
        return this.http.get<number>(url.toString(), { params })
    }


    bookingConfirmationResendRequested(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        booking: Booking
    ) {
        const path = `/organisation/${organisation.id}` +
            `/business/${business.id}` +
            `/venue/${venue.id}` +
            `/booking/${booking.id}` +
            '/booking-confirmation-resend'
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post(url.toString(), null)
    }

    addCancellationCharge(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        booking: Booking,
        charge: Charge,
        sendViaEmail: boolean,
        sendViaSMS: boolean
    ): Observable<Booking> {
        const path = `/organisation/${organisation.id}` +
            `/business/${business.id}` +
            `/venue/${venue.id}` +
            `/booking/${booking.id}` +
            '/cancellation' +
            '?sendViaEmail=' + sendViaEmail +
            '&sendViaSMS=' + sendViaSMS
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post<BookingDTO>(url.toString(), charge)
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    addDepositCharge(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        booking: Booking,
        charge: Charge,
        sendViaEmail: boolean,
        sendViaSMS: boolean
    ): Observable<Booking> {
        const path = `/organisation/${organisation.id}` +
            `/business/${business.id}` +
            `/venue/${venue.id}` +
            `/booking/${booking.id}` +
            '/deposit' +
            '?sendViaEmail=' + sendViaEmail +
            '&sendViaSMS=' + sendViaSMS
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post<BookingDTO>(url.toString(), charge)
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    updateCharge(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        booking: Booking,
        charge: Charge
    ): Observable<Booking> {
        const path = `/organisation/${organisation.id}` +
            `/business/${business.id}` +
            `/venue/${venue.id}` +
            `/booking/${booking.id}` +
            '/charge'
        const url = new URL(path, environment.apiBaseURL)
        return this.http.put<BookingDTO>(url.toString(), charge)
            .pipe(
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    bookingPendingPaymentResendRequested(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        booking: Booking,
        sendViaEmail: boolean,
        sendViaSMS: boolean
    ) {
        const path = `/organisation/${organisation.id}` +
            `/business/${business.id}` +
            `/venue/${venue.id}` +
            `/booking/${booking.id}` +
            '/pending-payment-resend' +
            '?sendViaEmail=' + sendViaEmail +
            '&sendViaSMS=' + sendViaSMS
        const url = new URL(path, environment.apiBaseURL)
        return this.http.post(url.toString(), null)
    }

    private addStatusToBooking(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string,
        status: BookingStatus
    ): Observable<Booking> {
        const dto = this.dtoAdapter.adaptBookingStatus(status)
        const path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/area/${areaId}` +
            `/booking/${bookingId}` +
            '/status'
        const url = new URL(path, environment.apiBaseURL)
        this._loading$.next(true)
        return this.http.put<BookingDTO>(url.toString(), dto)
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    chargeCancellationFee(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string,
        status: BookingStatus | null
    ): Observable<Booking> {
        const dto = status ? this.dtoAdapter.adaptBookingStatus(status) : undefined
        const path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/area/${areaId}` +
            `/booking/${bookingId}` +
            '/cancellation-charge'
        const url = new URL(path, environment.apiBaseURL)
        const headers = {
            'Idempotency-Key': uuidv4(),
        }
        this._loading$.next(true)
        return this.http.put<BookingDTO>(url.toString(), dto, { headers })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }

    getBookingDetailsUrl(booking: Booking): string {
        return `${environment.widgetBaseURL}/booking/${booking.id}`
    }

    private refundDeposit(
        businessId: string,
        venueId: string,
        areaId: string,
        bookingId: string,
        status: BookingStatus
    ): Observable<Booking> {
        const dto = this.dtoAdapter.adaptBookingStatus(status)
        const path = '/organisation' +
            `/business/${businessId}` +
            `/venue/${venueId}` +
            `/area/${areaId}` +
            `/booking/${bookingId}` +
            '/deposit-refund'
        const url = new URL(path, environment.apiBaseURL)
        const headers = {
            'Idempotency-Key': uuidv4(),
        }
        this._loading$.next(true)
        return this.http.put<BookingDTO>(url.toString(), dto, { headers })
            .pipe(
                finalize(() => this._loading$.next(false)),
                map(dto => this.dtoAdapter.adaptBookingDto(dto))
            )
    }
}
