import { Pipe, PipeTransform } from '@angular/core'
import { Venue } from '@app/domain/Venue'

@Pipe({
    name: 'reasonName',
})
export class ReasonNamePipe implements PipeTransform {

    transform(reasonId: string, venue: Venue): string | null {
        const reason = venue.reasonWithId(reasonId)
        return reason ? reason.displayName : null
    }
}
