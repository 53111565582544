<h4>
    {{ date | date: 'EEEE, MMMM dd, yyyy' }}
    @if (serviceDescription) {
        - {{ serviceDescription.name }}
        ({{ serviceDescription.start | date: 'shortTime' }} - {{ serviceDescription.end | date: 'shortTime' }})
    }
</h4>
<div>
    <table class="table mb-5 pb-5 bookings-print-out">
        <colgroup>
            <col>
            <col>
            <col>
            <col>
            <col *ngIf="venue.reasons.length > 0">
            <col>
            <col>
        </colgroup>
        <thead>
            <tr class="text-uppercase">
                <th scope="col">
                    Time In - Out
                </th>
                <th scope="col">
                    Name
                </th>
                <th scope="col">
                    Phone
                </th>
                <th scope="col">
                    Covers
                </th>
                <th
                    *ngIf="venue.reasons.length > 0 || venue.events.length > 0"
                    scope="col">
                    Reason
                </th>
                <th scope="col">
                    Customer Notes
                </th>
                <th scope="col">
                    Table
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let entry of bookingsOnTables">
                <td>
                    {{entry.booking.start | date: 'shortTime' }}
                    -
                    {{entry.booking.end | date: 'shortTime' }}
                </td>
                <td>{{entry.booking.firstName}} {{entry.booking.lastName}}</td>
                <td>{{ entry.booking.phoneNumber | phoneNumber }}</td>
                <td>{{entry.booking.size}}</td>
                <td
                    *ngIf="venue.reasons.length > 0 || venue.events.length > 0"
                >
                    <span
                        *ngIf="entry.reason"
                    >
                        {{entry.reason.displayName}}
                    </span>
                    <span
                        *ngIf="entry.event"
                    >
                        {{entry.event.displayName}}
                    </span>
                </td>
                @if (printType === 'large-parties') {
                    <td>
                        {{entry.booking.notes}}
                    </td>
                } @else {
                    <td>
                        {{entry.booking.notes | slice:0:180}}
                        <ng-container
                            *ngIf="entry.booking.notes"
                        >
                            <span *ngIf="entry.booking.notes.length > 180">…</span>
                        </ng-container>
                    </td>
                }
                <td>{{ entry.booking | tableName: entry.area: 'short' }}</td>
            </tr>
        </tbody>
    </table>
</div>
