import { Observable, of } from 'rxjs'
import { Pipe, PipeTransform } from '@angular/core'
import { catchError, map, startWith } from 'rxjs/operators'

@Pipe({
    name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
    transform<T>(val?: Observable<T>) {
        return val?.pipe(
            map((value) => ({
                loading: false,
                value,
                error: null,
            })),
            startWith({
                loading: true,
                value: null,
                error: null,
            }),
            catchError(error => of({
                loading: false,
                value: null,
                error,
            }))
        )
    }
}
