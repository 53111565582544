
export function coalesceDateRanges(
    openPeriods: [Date, Date][],
    closedPeriods: [Date, Date][]
): [Date, Date][] {
    let coalescedPeriods = [...coalesceOpenPeriods(openPeriods)]
    for (const closedPeriod of closedPeriods) {
        coalescedPeriods = coalescedPeriods.flatMap(openPeriod => {
            return mergeClosedPeriodIntoOpenPeriod(openPeriod, closedPeriod)
        })
    }
    return coalescedPeriods
}

function mergeClosedPeriodIntoOpenPeriod(
    openPeriod: [Date, Date],
    closedPeriod: [Date, Date]
): [Date, Date][] {
    const [openStart, openEnd] = openPeriod
    const [closedStart, closedEnd] = closedPeriod
    if (closedStart.getTime() <= openStart.getTime() && closedEnd.getTime() >= openEnd.getTime()) {
        return []
    }
    if (closedStart.getTime() <= openStart.getTime() && closedEnd.getTime() > openStart.getTime()) {
        return [[closedEnd, openEnd]]
    }
    if (closedStart.getTime() < openEnd.getTime() && closedEnd.getTime() >= openEnd.getTime()) {
        return [[openStart, closedStart]]
    }
    if (closedStart.getTime() > openStart.getTime() && closedEnd.getTime() < openEnd.getTime()) {
        return [[openStart, closedStart], [closedEnd, openEnd]]
    }
    return [openPeriod]
}

export function coalesceOpenPeriods(dateRanges: [Date, Date][]): [Date, Date][] {
    let coalescedPeriods = [...dateRanges]
    for (let i = 0; i < coalescedPeriods.length; i++) {
        for (let j = i + 1; j < coalescedPeriods.length; j++) {
            const coalescedPeriod = mergeDateRanges(coalescedPeriods[i], coalescedPeriods[j])
            if (coalescedPeriod) {
                coalescedPeriods[i] = coalescedPeriod
                coalescedPeriods.splice(j, 1)
                i = -1
                break
            }
        }
    }
    return coalescedPeriods
}

function mergeDateRanges(
    period1: [Date, Date],
    period2: [Date, Date]
): [Date, Date] | null {
    const [start1, end1] = period1
    const [start2, end2] = period2
    if (end1.getTime() < start2.getTime() || end2.getTime() < start1.getTime()) {
        return null
    }
    const mergedStart = new Date(Math.min(start1.getTime(), start2.getTime()))
    const mergedEnd = new Date(Math.max(end1.getTime(), end2.getTime()))
    return [mergedStart, mergedEnd]
}
