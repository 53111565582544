import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.sass'],
})
export class SpinnerOverlayComponent implements OnInit {

    @Input() showSpinner!: boolean

    constructor() { }

    ngOnInit() { }
}
