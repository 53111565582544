import * as d3 from 'd3'
import { Area } from '@app/domain/Area'
import {
    Component,
    ElementRef,
    Input, OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core'
import {
    FloorTableViewModel,
} from '@app/features/shared/components/area-floor/floor-table.view-model'
import { ResizeObservable } from '@app/features/shared/rjxs/resize-observable'
import {
    Subject, takeUntil,
} from 'rxjs'

@Component({
    selector: 'app-floor-plan',
    templateUrl: './area-floor.component.html',
    styleUrls: ['./area-floor.component.sass'],
})
export class AreaFloorComponent implements OnInit, OnDestroy {

    @Input() area!: Area
    @Input() tableViewModels!: FloorTableViewModel[]
    private mouseEvents = new Subject<{ event: 'down' | 'up' | 'move', x: number, y: number }>()
    mouseEvents$ = this.mouseEvents.asObservable()
    xScale!: d3.ScaleLinear<number, number>
    yScale!: d3.ScaleLinear<number, number>
    @ViewChild('svg', {
        static: true,
    }) svg!: ElementRef
    margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
    height!: number
    floorDimensions = {
        width: 60,
        height: 40,
    }
    private onDestroy = new Subject<void>()

    constructor() { }

    ngOnInit() {
        this.updateFloorDimensions()
        this.bindWidthToFloorDimensions()
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    updateFloorDimensions() {
        let width = this.svg.nativeElement.getBoundingClientRect().width
            - this.margin.left
            - this.margin.right
        if (width <= 0) {
            width = 0
        }
        this.height = width * (this.floorDimensions.height / this.floorDimensions.width)
        this.xScale = d3.scaleLinear()
            .domain([0, this.floorDimensions.width])
            .range([0, width])
        this.yScale = d3.scaleLinear()
            .domain([0, this.floorDimensions.height])
            .range([0, this.height])
    }

    onMouseDown(event: MouseEvent) {
        event.preventDefault()
        const [x, y] = this.translateToSvgPosition(event.clientX, event.clientY)
        this.mouseEvents.next({
            event: 'down',
            x,
            y,
        })
    }

    onTouchStart(event: TouchEvent) {
        event.preventDefault()
        const touch = event.touches[0]
        const [x, y] = this.translateToSvgPosition(touch.clientX, touch.clientY)
        this.mouseEvents.next({
            event: 'down',
            x,
            y,
        })
    }

    onMouseMove(event: MouseEvent) {
        const [x, y] = this.translateToSvgPosition(event.clientX, event.clientY)
        this.mouseEvents.next({
            event: 'move',
            x,
            y,
        })
    }

    onTouchMove(event: TouchEvent) {
        event.preventDefault()
        const touch = event.touches[0]
        const [x, y] = this.translateToSvgPosition(touch.clientX, touch.clientY)
        this.mouseEvents.next({
            event: 'move',
            x,
            y,
        })
    }

    onMouseUp(event: MouseEvent) {
        event.preventDefault()
        const [x, y] = this.translateToSvgPosition(event.clientX, event.clientY)
        this.mouseEvents.next({
            event: 'up',
            x,
            y,
        })
    }

    onTouchEnd(event: TouchEvent) {
        event.preventDefault()
        const touch = event.changedTouches[0]
        const [x, y] = this.translateToSvgPosition(touch.clientX, touch.clientY)
        this.mouseEvents.next({
            event: 'up',
            x: x,
            y: y,
        })
    }

    translateToSvgPosition(x: number, y: number) {
        const svg = this.svg.nativeElement
        const point = svg.createSVGPoint()
        point.x = x
        point.y = y
        const cursorpt = point.matrixTransform(svg.getScreenCTM()?.inverse())
        return [cursorpt.x, cursorpt.y]
    }

    private bindWidthToFloorDimensions() {
        const resizeObservable = new ResizeObservable(this.svg.nativeElement)
        resizeObservable
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.updateFloorDimensions()
            })
    }
}
