export class AddressDTO {
    constructor(
        public addressLineOne: string,
        public addressLineTwo: string | null,
        public city: string,
        public county: string | null,
        public postCode: string,
        public country: string
    ) { }
}
