import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe
    ) { }

    transform(date: Date | null, format?: string): string  | null {
        if (!date) {
            return null
        }
        const dateIsToday = date.toDateString() === new Date().toDateString()
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        const dateIsTomorrow = date.toDateString() === tomorrow.toDateString()
        const formatsIndicatingTime = [
            'shortTime',
            'mediumTime',
            'longTime',
            'fullTime',
            'short',
            'medium',
            'long',
            'full',
        ]
        const shouldIncludeTime = format && formatsIndicatingTime.includes(format)
        const timeFormat = shouldIncludeTime ? format + 'Time' : null
        if (dateIsToday) {
            return 'Today' + (timeFormat ? ', ' + this.datePipe.transform(date, timeFormat) : '')
        }
        if (dateIsTomorrow) {
            return 'Tomorrow' + (timeFormat ? ', ' + this.datePipe.transform(date, timeFormat) : '')
        }
        return this.datePipe.transform(date, format)
    }
}
