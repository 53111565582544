import { Component, HostBinding, Input, OnInit } from '@angular/core'

@Component({
    selector: '[app-form-list-item]',
    templateUrl: './form-list-item.component.html',
    styleUrls: ['./form-list-item.component.sass'],
})
export class FormListItemComponent implements OnInit {

    @Input() title!: string
    @Input() subtitle!: string
    @Input() contentClass = 'flex-grow-1'
    @HostBinding('class') classes = 'list-group-item'

    constructor() { }

    ngOnInit() { }
}
