import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'

@Directive({
    selector: '[appEditableIcon]',
})
export class EditableIconDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    @HostListener('mouseenter') onMouseEnter() {
        const img = this.renderer.createElement('img')
        this.renderer.setAttribute(img, 'src', '/assets/edit/normal.svg')
        this.renderer.setAttribute(img, 'alt', 'Edit')
        this.renderer.appendChild(this.el.nativeElement, img)
    }

    @HostListener('mouseleave') onMouseLeave() {
        const img = this.el.nativeElement.querySelector('img')
        if (img) {
            this.renderer.removeChild(this.el.nativeElement, img)
        }
    }
}
