import { PartySizeDuration } from '@app/domain/PartySizeDuration'
import { Period } from '@app/domain/Period'

export class ReasonScheduleRule {
    constructor(
        public id: string,
        public daysOfWeek: number[],
        public period: Period | null,
        public bookingDurations: PartySizeDuration[]
    ) { }

    appliesToDate(date: Date): boolean {
        const dayOfWeekStartingOnSunday = date.getDay()
        const startingOnMonday = dayOfWeekStartingOnSunday === 0 ? 7 : dayOfWeekStartingOnSunday
        if (!this.daysOfWeek.includes(startingOnMonday)) {
            return false
        }
        if (this.period && !this.period.isOpenAnyTimeOnDate(date)) {
            return false
        }
        return true
    }
}
