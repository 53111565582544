import { Pipe, PipeTransform } from '@angular/core'
import { ScaleBand, ScaleOrdinal } from 'd3'

@Pipe({
    name: 'groupedScale',
})
export class GroupedScalePipe implements PipeTransform {

    transform(
        group: any,
        domainValue: any | null,
        scaleStack: Map<any, ScaleBand<any> | ScaleOrdinal<any, any>>,
        groupTopMargin?: number
    ): number {
        const scale = scaleStack.get(group)
        if (!scale) {
            return 0
        }
        const scaleIndex = Array.from(scaleStack.keys()).indexOf(group)
        const previousScales = Array.from(scaleStack.values())
            .slice(0, scaleIndex)
        const startOfGroup = previousScales.reduce((acc, scale) => {
            const groupHeight = scale.range()[1] || 0
            return acc + groupHeight + groupTopMargin
        }, 0)
        const insideGroupRangeValue = domainValue ? scale(domainValue) : 0
        return startOfGroup + insideGroupRangeValue + groupTopMargin
    }
}
