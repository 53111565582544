<div
    class="vstack flex-grow-1"
>
    <button
        *ngIf="!isEditing"
        [style.white-space]="'pre-wrap'"
        [style.text-align]="'left'"
        class="btn btn-sm btn-outline-secondary me-auto"
        [ngClass]="{
            'redacted': redacted !== undefined && redacted,
            'editable': isEditable,
            'disabled': !isEditable
        }"
        appEditableIcon
        (click)="startEditing()"
    >{{ value || placeholder || "" }}
    </button>
    <div
        *ngIf="isEditing"
        class="hstack gap-2 align-items-baseline flex-grow-1"
    >
        <textarea
            class="form-control flex-grow-1"
            [rows]="rows"
            [placeholder]="placeholder || ''"
            [value]="value"
            (input)="onInputChange($event)"
        >
        </textarea>
        <button
            class="btn btn-sm btn-primary col-sm"
            (click)="saveTextUpdate()"
        >
            Save
        </button>
        <button
            class="btn btn-sm btn-outline-secondary col-sm"
            (click)="cancelTextUpdate()"
        >
            Cancel
        </button>
    </div>
</div>
