import { BookingStatusDirective } from '@app/features/shared/directives/booking-status.directive'
import { BookingStatusType } from '@app/domain/BookingStatus'
import { Component, Input } from '@angular/core'
import { DecimalPipe, NgClass } from '@angular/common'

@Component({
    selector: 'app-party-size-badge',
    standalone: true,
    templateUrl: './party-size-badge.component.html',
    styleUrls: ['./party-size-badge.component.sass'],
    imports: [
        BookingStatusDirective,
        DecimalPipe,
        NgClass,
    ],
})
export class PartySizeBadgeComponent {

    @Input() partySize!: number
    @Input() statusType: BookingStatusType = BookingStatusType.Finished
    @Input() isLate: boolean = false
    @Input() tableHeight?: 'extra-small' | 'small' | 'medium' | 'large'
}
