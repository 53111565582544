import * as d3 from 'd3'
import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingSlot } from '@app/domain/BookingSlot'
import { Business } from '@app/domain/Business'
import { Schedule } from '@app/domain/Schedule'
import { Service } from '@app/domain/Service'
import { Table } from '@app/domain/Table'
import { Venue } from '@app/domain/Venue'
import { VenueClosure } from '@app/domain/VenueClosure'

export class Organisation {

    constructor(
        public id: string,
        public displayName: string,
        public businesses: Business[],
        public schedules: Schedule[]
    ) { }

    getBusiness(businessId: string): Business | null {
        return this.businesses.find(business => business.id === businessId) ?? null
    }

    scheduleWithId(scheduleId: string): Schedule | null {
        const index = this.schedules.findIndex(
            schedule => schedule.id === scheduleId
        )
        if (index === -1) {
            return null
        }
        return this.schedules[index]
    }

    scheduleUsedInAreaOnDate(area: Area, date: Date): Schedule | null {
        return area.scheduleIds
            .map(scheduleId => this.scheduleWithId(scheduleId))
            .find(schedule => schedule?.operatesOnDate(date) ?? false) ?? null
    }

    schedulesUsedInVenue(venue: Venue): Schedule[] {
        return venue.areas
            .flatMap(area => area.scheduleIds)
            .map(scheduleId => this.scheduleWithId(scheduleId))
            .filter(schedule => schedule !== null) as Schedule[]
    }

    schedulesUsedInVenueOnDate(venue: Venue, date: Date): Schedule[] {
        return venue.areas
            .map(area => this.scheduleUsedInAreaOnDate(area, date))
            .filter(schedule => schedule !== null) as Schedule[]
    }

    servicesUsedInVenueOnDate(venue: Venue, date: Date): Service[] {
        const services = this.schedulesUsedInVenueOnDate(venue, date)
            .flatMap(schedule => schedule.servicesOperatingOnDate(date))
        return services.filter((service, index, self) => {
            return index === self.findIndex(s => s.id === service.id)
        })
    }

    coalescedServicesUsedInVenueOnDate(
        venue: Venue,
        date: Date
    ): { service: Service, timeRange: [Date, Date] }[] {
        const servicesWithTimeRanges = this.servicesUsedInVenueOnDate(venue, date)
            .map(service => {
                const timeRange = service.bookingSlotExtentForDate(date)
                return {
                    service,
                    timeRange,
                }
            })
            .filter(service => {
                return service.timeRange !== null
            }) as { service: Service, timeRange: [Date, Date] }[]
        return servicesWithTimeRanges
            .reduce((acc, service) => {
                const existing = acc.find(s => {
                    return s.service.displayName === service.service.displayName
                })
                if (existing) {
                    const mergedTimeRange = this.extentOfDateRanges(
                        [existing.timeRange, service.timeRange]
                    )
                    if (mergedTimeRange === null) {
                        return acc
                    }
                    existing.timeRange = mergedTimeRange
                    return acc
                }
                return acc.concat(service)
            }, [] as { service: Service, timeRange: [Date, Date] }[])
            .sort((a, b) => {
                return a.timeRange[0].getTime() - b.timeRange[0].getTime()
            })
    }

    addSchedule(schedule: Schedule) {
        this.schedules.push(schedule)
    }

    updateSchedule(schedule: Schedule) {
        const index = this.schedules.findIndex(
            existingSchedule => existingSchedule.id === schedule.id
        )
        if (index === -1) {
            return
        }
        this.schedules.splice(index, 1, schedule)
    }

    isScheduleInUse(schedule: Schedule): boolean {
        return this.businesses.some(business => {
            return business.venues.some(venue => {
                return venue.areas.some(area => {
                    return area.scheduleIds.includes(schedule.id)
                })
            })
        })
    }

    deleteSchedule(schedule: Schedule) {
        const index = this.schedules.findIndex(
            existingSchedule => existingSchedule.id === schedule.id
        )
        if (index === -1) {
            return
        }
        this.schedules.splice(index, 1)
    }

    maximumInAdvanceDaysInVenue(venue: Venue): number | null {
        const schedulesUsedInVenue = this.schedulesUsedInVenue(venue)
        if (schedulesUsedInVenue.length === 0) {
            return null
        }
        return schedulesUsedInVenue
            .map(schedule => schedule.inAdvanceBookingLimitDays)
            .reduce((a, b) => Math.max(a, b), 0)
    }

    isVenueInOperationOnDate(venue: Venue, date: Date): boolean {
        const schedulesUsedInVenue = this.schedulesUsedInVenueOnDate(venue, date)
        if (schedulesUsedInVenue.length === 0) {
            return false
        }
        return schedulesUsedInVenue
            .some(schedule => {
                return schedule.hasRuleThatAppliesToDate(date)
            })
    }

    bookingSlotExtentOfAllSchedulesInVenue(venue: Venue): [Date, Date] | null {
        const schedulesUsedInVenue = this.schedulesUsedInVenue(venue)
        const dateRanges = schedulesUsedInVenue
            .map(schedule => schedule.bookingSlotExtent())
        return this.extentOfDateRanges(dateRanges)
    }

    dateTimeExtentOfSchedulesInVenueOnDate(venue: Venue, date: Date): [Date, Date] | null {
        const schedulesUsedInVenue = this.schedulesUsedInVenueOnDate(venue, date)
        const dateRanges = schedulesUsedInVenue
            .map(schedule => schedule.dateTimeExtent(date, venue.bookingInterval))
        return this.extentOfDateRanges(dateRanges)
    }

    bookingSlotExtentOfSchedulesInVenueOnDate(venue: Venue, date: Date, closures: VenueClosure[]): [Date, Date] | null {
        const schedulesUsedInVenue = this.schedulesUsedInVenueOnDate(venue, date)
        const dateRanges = schedulesUsedInVenue
            .map(schedule => schedule.bookingSlotExtentForDate(date))
            .concat(closures
                .filter(closure => !closure.closes)
                .map(closure => [closure.start, closure.end] as [Date, Date])
            )
        return this.extentOfDateRanges(dateRanges)
    }

    nextClosingDateAfterDateOnTables(date: Date, venue: Venue, tables?: Table[]): Date | null {
        const areasTablesAreIn = venue.areas
            .filter(area => tables === undefined || tables.some(table => area.getTableWithId(table.id) !== null)) as Area[]
        const schedulesUsedInAreas = areasTablesAreIn
            .flatMap(area => this.scheduleUsedInAreaOnDate(area, date))
            .filter(schedule => schedule !== null) as Schedule[]
        const closingDates = schedulesUsedInAreas
            .flatMap(schedule => schedule.nextClosingDateAfterDateInVenue(date, venue))
            .filter(date => date !== null) as Date[]
        if (closingDates.length === 0) {
            return null
        }
        return closingDates
            .sort((a, b) => a.getTime() - b.getTime())[0]
    }

    nextOpeningDateAfterDateOnTables(date: Date, venue: Venue, tables?: Table[]): Date | null {
        const areasTablesAreIn = venue.areas
            .filter(area => tables === undefined || tables.some(table => area.getTableWithId(table.id) !== null)) as Area[]
        const schedulesUsedInAreas = areasTablesAreIn
            .flatMap(area => this.scheduleUsedInAreaOnDate(area, date))
            .filter(schedule => schedule !== null) as Schedule[]
        const openingDates = schedulesUsedInAreas
            .flatMap(schedule => schedule.nextOpeningDateAfterDateInVenue(date, venue))
            .filter(date => date !== null) as Date[]
        if (openingDates.length === 0) {
            return null
        }
        return openingDates
            .sort((a, b) => a.getTime() - b.getTime())[0]
    }

    durationTableIsNotOccupiedForOrConfiguredDuration(
        table: Table,
        bookings: Booking[],
        startDateTime: Date,
        partySize: number,
        maximumDurationTrimPercentage?: number
    ): number | null {
        const area = this.businesses
            .flatMap(business => business.venues)
            .flatMap(venue => venue.areas)
            .find(area => area.tables.includes(table))
        if (!area) {
            return null
        }
        const schedule = this.scheduleUsedInAreaOnDate(area, startDateTime)
        if (!schedule) {
            return null
        }
        const configuredDuration = schedule.durationForPartySize(startDateTime, partySize)
        const nextStartOfBookingOnTable = bookings
            .filter(booking => booking.isActive())
            .filter(booking => booking.occupiesAnyTable([table]))
            .filter(booking => booking.end > startDateTime)
            .map(booking => booking.start)
            .sort((a, b) => a.getTime() - b.getTime())[0] ?? null
        if (nextStartOfBookingOnTable === null) {
            return configuredDuration
        }
        const untilNextBooking = nextStartOfBookingOnTable.getTime() - startDateTime.getTime()
        if (untilNextBooking <= 0) {
            return null
        }
        const minutesUntilNextBooking = Math.floor(untilNextBooking / 1000 / 60)
        const differenceInDuration = configuredDuration - minutesUntilNextBooking
        const durationTrimPercent = Math.ceil((differenceInDuration / configuredDuration) * 100)
        if (maximumDurationTrimPercentage != undefined && durationTrimPercent > maximumDurationTrimPercentage) {
            return null
        }
        return Math.min(configuredDuration, minutesUntilNextBooking)
    }

    unoccupiedBookingSlotsForBookingInVenueOnDate(
        venue: Venue,
        booking: Booking,
        otherBookings: Booking[],
        date: Date
    ): BookingSlot[] {
        const area = venue.areaBookingIsIn(booking)
        if (!area) {
            return []
        }
        const schedule = this.scheduleUsedInAreaOnDate(area, date)
        if (!schedule) {
            return []
        }
        const bookingSlotRange = this.bookingSlotExtentOfSchedulesInVenueOnDate(venue, date, [])
        if (bookingSlotRange === null) {
            return []
        }
        const tables = area.tablesUsedForBooking(booking)
        return venue.bookingSlotsBetweenDates(
            bookingSlotRange[0],
            bookingSlotRange[1],
            (startDate, endDate, interval) => {
                return d3.timeMinute
                    .every(interval)!
                    .range(startDate, endDate)
                    .concat(endDate)
            }
        )
            .filter(slot => {
                return !otherBookings.some(booking => {
                    return booking.occupiesTableBetweenDates(
                        slot.dateTime,
                        slot.endDateTime(venue.bookingInterval),
                        tables
                    )
                })
            })
    }

    unoccupiedEndTimesForBookingInVenue(
        venue: Venue,
        booking: Booking,
        otherBookings: Booking[]
    ): Date[] {
        const area = venue.areaBookingIsIn(booking)
        if (!area) {
            return []
        }
        const schedule = this.scheduleUsedInAreaOnDate(area, booking.start)
        if (!schedule) {
            return []
        }
        const bookingSlotRange = this.dateTimeExtentOfSchedulesInVenueOnDate(venue, booking.start)
        if (bookingSlotRange === null) {
            return []
        }
        const tables = area.tablesUsedForBooking(booking)
        const bookingSlots = venue.bookingSlotsBetweenDates(
            bookingSlotRange[0],
            bookingSlotRange[1],
            (startDate, endDate, interval) => {
                return d3.timeMinute
                    .every(interval)!
                    .range(startDate, endDate)
            }
        )
        return bookingSlots
            .filter(slot => {
                return !otherBookings.some(otherBooking => {
                    return otherBooking.occupiesTableBetweenDates(
                        booking.start,
                        slot.endDateTime(venue.bookingInterval),
                        tables
                    )
                })
            })
            .map(slot => slot.endDateTime(venue.bookingInterval))
            .filter(endTime => endTime > booking.start)
    }

    unoccupiedBookingSlotsInAreaOnDate(
        venue: Venue,
        area: Area,
        date: Date,
        bookings: Booking[],
        closures: VenueClosure[]
    ): BookingSlot[] {
        const schedule = this.scheduleUsedInAreaOnDate(area, date)
        if (!schedule) {
            return []
        }
        const openDateRanges = schedule.openDateRangesForDateTime(date, venue.bookingInterval)
        const openClosures = closures.filter(closure => !closure.closes)
            .map(closure => [closure.start, closure.end] as [Date, Date])
        const extent = this.extentOfDateRanges(openDateRanges.concat(openClosures))
        if (!extent) {
            return []
        }
        const startDateTime = extent[0]
        const endDateTime = extent[1]
        return venue.bookingSlotsBetweenDates(
            startDateTime,
            endDateTime,
            (startDate, endDate, interval) => {
                return d3.timeMinute
                    .every(interval)!
                    .range(startDate, endDate)
                    .concat(endDate)
            }
        )
            .filter(slot => {
                return area.tables.some(table => {
                    return !bookings.some(booking => {
                        return booking.occupiesTableBetweenDates(
                            slot.dateTime,
                            slot.endDateTime(venue.bookingInterval),
                            [table]
                        )
                    })
                })
            })
            .filter(slot => {
                const inOpenSlot = openDateRanges.some(range => {
                    return slot.dateTime >= range[0] &&
                        slot.endDateTime(venue.bookingInterval) <= range[1]
                })
                const inOpenClosure = closures.some(closure => {
                    return !closure.closes &&
                        area.tables.some(table => {
                            return closure.occursBetweenDatesOnTable(
                                slot.dateTime,
                                slot.endDateTime(venue.bookingInterval),
                                table
                            )
                        })
                })
                return inOpenSlot || inOpenClosure
            })
    }

    arePaymentsEnabledForAreaAtDateTime(
        area: Area,
        dateTime: Date
    ): boolean {
        const business = this.businesses.find(business => {
            return business.venues.some(venue => venue.areaWithId(area.id) !== null)
        }) ?? null
        if (!business) {
            return false
        }
        if (!business.paymentsAccount) {
            return false
        }
        const schedule = this.scheduleUsedInAreaOnDate(area, dateTime)
        if (!schedule) {
            return false
        }
        return schedule.arePaymentsEnabledAtDateTime(dateTime)
    }

    private extentOfDateRanges(
        dateRanges: [Date, Date][]
    ): [Date, Date] | null {
        if (dateRanges.length === 0) {
            return null
        }
        const earliestTime = dateRanges
            .map(range => range[0])
            .sort((a, b) => a.getTime() - b.getTime())[0]
        const latestTime = dateRanges
            .map(range => range[1])
            .sort((a, b) => b.getTime() - a.getTime())[0]
        return [earliestTime, latestTime]
    }
}
