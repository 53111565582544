import { DateComponent } from '@app/domain/DateComponent'
import { DateComponentUnit } from '@app/domain/DateComponentUnit'
import { Helper } from '@app/helpers/utilities/helper'
import { PartySizeDuration } from '@app/domain/PartySizeDuration'
import { Period } from '@app/domain/Period'

export class ScheduleRule {

    constructor(
        public id: string,
        public dateComponents: DateComponent[],
        public period: Period,
        public bookingDurations: PartySizeDuration[],
        public paymentsEnabled: boolean,
        public preBookingWindowMinutes: number | null,
        public coverLimit: number | null,
        public manualAcceptance: boolean
    ) { }

    durationForPartySize(date: Date, partySize: number): number | null {
        if (!this.appliesToDate(date)) {
            return null
        }
        const periodDateRange = this.period.dateRangeOnDate(date)
        const appliesToPeriod = Helper.isBetweenDate(date, periodDateRange[0], periodDateRange[1])
        if (!appliesToPeriod) {
            return null
        }
        const largestDurationThatFits = this.bookingDurations
            .filter(duration => duration.partySize !== null)
            .filter(duration => duration.partySize! <= partySize)
            .sort((a, b) => b.partySize! - a.partySize!)
            [0] || null
        return largestDurationThatFits?.duration ?? null
    }

    appliesToDayOfWeek(dayOfWeek: number): boolean {
        if (this.dateComponents.length === 0) {
            return true
        }
        return this.dateComponents.filter(dateComponent => {
            return dateComponent.unit === DateComponentUnit.DAY_OF_WEEK
        })
            .filter(dateComponent => {
                return dateComponent.value === dayOfWeek
            })
            .length > 0
    }

    appliesToDateTime(date: Date): boolean {
        if (!this.appliesToDate(date)) {
            return false
        }
        return this.period.containsTime(date)
    }

    appliesToDate(date: Date): boolean {
        const dayOfWeekStartingOnSunday = date.getDay()
        const startingOnMonday = dayOfWeekStartingOnSunday === 0 ? 7 : dayOfWeekStartingOnSunday
        return this.appliesToDayOfWeek(startingOnMonday)
    }
}
