import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'

export class NGBDateTimeAdapter implements NgbTimeAdapter<Date> {

    fromModel(value: Date | null): NgbTimeStruct | null {
        if (!value) {
            return null
        }
        return {
            hour: value.getHours(),
            minute: value.getMinutes(),
            second: value.getSeconds(),
        }
    }

    toModel(time: NgbTimeStruct | null): Date | null {
        if (!time) {
            return null
        }
        const date = new Date()
        date.setHours(time.hour)
        date.setMinutes(time.minute)
        date.setSeconds(time.second)
        return date
    }
}
