import { EmailMarketingListDTO } from '@services/DTO/email-marketing/EmailMarketingListDTO'
import { EmailMarketingListFieldDTO } from './EmailMarketingListFieldDTO'
import { EmailMarketingSourceDTO } from '@services/DTO/email-marketing/EmailMarketingSourceDTO'
import { EmailMarketingTagDTO } from '@services/DTO/email-marketing/EmailMarketingTagDTO'

export enum EmailMarketingStatusDTO {
    SUBSCRIBED = 'SUBSCRIBED',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
    PENDING = 'PENDING'
}

export class EmailMarketingConfigurationDTO {
    constructor(
        public id: string,
        public isEnabled: boolean,
        public source: EmailMarketingSourceDTO,
        public apiKey: string,
        public list: EmailMarketingListDTO,
        public fields: EmailMarketingListFieldDTO[],
        public tags: EmailMarketingTagDTO[] | null,
        public status: EmailMarketingStatusDTO
    ) { }
}
