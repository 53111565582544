import { Booking } from '@app/domain/Booking'
import { BookingChangedMetadata } from '@app/features/shared/components/booking/booking.component'
import { BookingService } from '@services/booking.service'
import { Business } from '@app/domain/Business'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Customer } from '../../../../domain/Customer'
import { Helper } from '@app/helpers/utilities/helper'
import { Organisation } from '@app/domain/Organisation'
import { ScheduleService } from '@services/schedule.service'
import { Subject, finalize, takeUntil } from 'rxjs'
import { Venue } from '@app/domain/Venue'
import { VenueSchedule } from '@app/domain/VenueSchedule'
import { activeBookingStatusTypes } from '@app/domain/BookingStatus'

@Component({
    selector: 'app-load-booking',
    templateUrl: './load-booking.component.html',
})
export class LoadBookingComponent implements OnInit, OnDestroy {

    isLoadingBooking = false
    isLoadingOtherBookings = false
    isLoadingSchedule = false

    @Input() organisation!: Organisation
    @Input() business!: Business
    @Input() venue!: Venue
    bookingId?: string
    booking?: Booking
    otherBookings?: Booking[]
    schedule?: VenueSchedule | null
    @Output() bookingBeingChanged = new EventEmitter<boolean>()
    @Output() bookingChanged = new EventEmitter<[Booking, Partial<BookingChangedMetadata>]>()
    @Output() customerSelected = new EventEmitter<Customer>()
    private onDestroy = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private scheduleService: ScheduleService
    ) { }

    ngOnInit() {
        this.loadRequiredData()
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    private loadRequiredData() {
        this.loadBookingIfRequired()
        this.loadOtherBookingsIfRequired()
        this.loadScheduleIfRequired()
    }

    private loadBookingIfRequired() {
        if (this.booking) {
            return
        }
        if (!this.bookingId) {
            return
        }
        this.isLoadingBooking = true
        this.bookingService.getBooking(this.bookingId)
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.isLoadingBooking = false)
            )
            .subscribe(booking => {
                this.booking = booking
                this.loadRequiredData()
            })
    }

    private loadOtherBookingsIfRequired() {
        if (!this.booking) {
            return
        }
        if (this.otherBookings) {
            return
        }
        this.isLoadingOtherBookings = true
        this.bookingService.getBookings(
            this.business.id,
            this.venue.id,
            Helper.startOfDay(this.booking.start),
            Helper.endOfDay(this.booking.end),
            activeBookingStatusTypes()
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.isLoadingOtherBookings = false)
            )
            .subscribe((bookings: Booking[]) => {
                this.otherBookings = bookings.filter(b => b.id !== this.booking?.id)
                this.isLoadingOtherBookings = false
            })
    }

    private loadScheduleIfRequired() {
        if (!this.booking) {
            return
        }
        if (this.schedule) {
            return
        }
        this.isLoadingSchedule = true
        this.scheduleService.getVenueSchedule(
            this.organisation,
            this.business,
            this.venue,
            Helper.startOfDay(this.booking.start),
            Helper.endOfDay(this.booking.end)
        )
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe(schedule => {
                this.schedule = schedule
                this.isLoadingSchedule = false
            })
    }
}
