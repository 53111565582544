import { Pipe, PipeTransform } from '@angular/core'
import { QuestionType } from '@app/domain/Question'

@Pipe({
    name: 'questionTypeName',
})
export class QuestionTypeNamePipe implements PipeTransform {

    transform(type: QuestionType): string {
        switch (type) {
        case QuestionType.Checkbox:
            return 'Yes/No'
        case QuestionType.Dropdown:
            return 'Multiple Choice'
        case QuestionType.AcknowledgeEndTime:
            return 'Acknowledge End Time'
        case QuestionType.DOB:
            return 'Ask for Birthday'
        case QuestionType.ReasonsForVisit:
            return 'Reason for visit'
        }
    }
}
