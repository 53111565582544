import { Booking } from '../domain/Booking'
import { BookingAuditEntryDTO } from './DTO/BookingAuditEntryDTO'
import { DTOAdapter } from './DTOAdapter'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Organisation } from '@app/domain/Organisation'
import { environment } from '@environments/environment'
import { map } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class BookingAuditService {
    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    getAuditLogForBooking(organisation: Organisation, booking: Booking) {
        const path = `/organisation/${organisation.id}/booking/${booking.id}/audit-log`
        const url = new URL(path, environment.apiBaseURL)
        return this.http.get<BookingAuditEntryDTO[]>(url.toString())
            .pipe(
                map((response) => {
                    return response.map((dto) => this.dtoAdapter.adaptBookingAuditEntryDto(dto))
                })
            )
    }
}
