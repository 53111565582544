export class DepositsReportGraph {
    constructor(
        public date: Date,
        public grossCount: number,
        public netCount: number,
        public grossAmount: number,
        public netAmount: number,
        public currencyCode: string | null
    ) { }
}
