export class BrandDTO {
    constructor(
        public brandColour: string | null,
        public bannerImageId: string | null,
        public facebookUrl: string | null,
        public instagramUrl: string | null,
        public twitterUrl: string | null,
        public websiteUrl: string | null
    ) { }
}
