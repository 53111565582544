export class BookingsReportGraph {
    public bookingsDate: Date
    public totalBookings: number

    constructor(
        bookingsDate: Date,
        totalBookings: number
    ) {
        this.bookingsDate = bookingsDate
        this.totalBookings = totalBookings
    }
}
