import { Directive, ElementRef, Inject, Input, OnChanges } from '@angular/core'

@Directive({
    selector: '[appRatingStatus]',
})
export class RatingStatusDirective implements OnChanges {

    @Input() rating!: number
    constructor(
        @Inject(ElementRef) private element: ElementRef
    ) { }

    ngOnChanges() {
        this.element.nativeElement.classList
            .remove('badge-very-poor', 'badge-poor', 'badge-fair', 'badge-good', 'badge-excellent')
        this.element.nativeElement.classList
            .add(this.makeRatingClassName())
    }

    private makeRatingClassName() {
        switch (this.rating) {
        case 1:
            return 'badge-very-poor'
        case 2:
            return 'badge-poor'
        case 3:
            return 'badge-fair'
        case 4:
            return 'badge-good'
        case 5:
            return 'badge-excellent'
        default:
            return 'unknown'
        }
    }

}
