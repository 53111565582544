export enum DepositStatus {
    PendingPayment,
    Paid,
    Failed,
}

export class Deposit {
    constructor(
        public id: string,
        public paymentMethodId: string | null,
        public amount: number,
        public currencyCode: string,
        public dateCreated: Date,
        public datePaid: Date | null,
        public dateRefunded: Date | null
    ) { }

    get status(): DepositStatus {
        if (this.datePaid !== null && this.dateRefunded === null) {
            return DepositStatus.Paid
        }
        if (this.datePaid === null && this.dateRefunded === null) {
            return DepositStatus.PendingPayment
        }
        return DepositStatus.Failed
    }
}
