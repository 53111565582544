import { Business } from '@app/domain/Business'
import { Pipe, PipeTransform } from '@angular/core'
import { User } from '@app/domain/User'

@Pipe({
    name: 'userVenueNames',
})
export class UserVenueNamesPipe implements PipeTransform {

    transform(user: User, business: Business): string {
        const venueIds = user.venueIds
        if (venueIds === null) {
            return 'All venues'
        }
        if (venueIds.length === 0) {
            return 'No venues'
        }
        const venues = business.venues.filter(venue => venueIds.includes(venue.id))
        return venues.map(venue => venue.displayName).join(', ')
    }
}
