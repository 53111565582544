export class Toast {

    public message: string
    public header: string = ''
    public type: ToastType = ToastType.Standard
    public autohide: boolean = true
    public delay: number = 1500

    constructor(message: string) {
        this.message = message
    }

    getClass() {
        switch (this.type) {
        case ToastType.Success:
            return 'toast-success'
        case ToastType.Danger:
            return 'toast-danger'
        case ToastType.Warning:
            return 'toast-warning'
        default:
            return ''
        }
    }
}

export enum ToastType {
    Standard,
    Success,
    Warning,
    Danger,
}
