<app-spinner-overlay
    [showSpinner]="viewModel.isEditingBooking"
>
</app-spinner-overlay>
<div class="p-3 vstack gap-3">
    <app-requested-booking-details
        *ngIf="viewModel.canAcceptBooking"
        [booking]="booking"
    >
    </app-requested-booking-details>
    <div
        class="vstack gap-2 gap-lg-3 px-1 mx-1"
    >
        <div
            *ngIf="viewModel.bookingName as bookingName"
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Booked by
            </span>
            <div
                class="col-lg-9"
            >
                <div class="vstack gap-2">
                    <button
                        class="btn btn-sm btn-outline-secondary me-auto hstack gap-1"
                        [ngClass]="{
                            'disabled': !viewModel.canGoToCustomer
                        }"
                        (click)="viewModel.goToCustomer()"
                    >
                        {{ viewModel.bookingName }}
                        <img
                            ngSrc="assets/right/normal.svg"
                            alt="Go to Customer"
                            width="16"
                            height="16"
                        />
                    </button>
                    @if (booking.customer?.note(); as note) {
                        <div class="hstack gap-2">
                            <img
                                ngSrc="assets/person/normal.svg"
                                alt="Customer Note"
                                width="18"
                                height="18"
                            />
                            <span
                                class="text-muted"
                            >
                                {{ note }}
                            </span>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Time
            </span>
            <div class="col-lg-9 vstack gap-2">
                <div class="hstack center gap-2">
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        data-testid="booking-start-time"
                        (click)="showChangeBookingStartTimeOptions()"
                    >
                        {{ booking.start | date: "shortTime" }}
                    </button>
                    <span class="text-secondary">
                        –
                    </span>
                    <app-element-choice-select
                        #changeEndTime
                        [startingSelection]="bookingTimeViewStartingSelection"
                        [canChangeElement]="true"
                        [dataTestId]="'booking-end-time'"
                        (loadElements)="viewModel.showChangeBookingTimeOptions()"
                        (elementChosen)="viewModel.updateBookingTime($event)"
                        class="flex-grow-1"
                    >
                    </app-element-choice-select>
                </div>
                <span
                    *ngIf="area.tableTurnaroundInterval > 0"
                    class="text-muted"
                    data-testid="table-turnaround-prompt"
                >
                    Turn around table at {{ booking.occupiedEndDate(area.tableTurnaroundInterval) | date: "shortTime" }}
                </span>
            </div>
        </div>
        <div
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Party Size
            </span>
            <div
                class="col-lg-9"
            >
                <app-input
                    #partySize
                    [isEditable]="true"
                    [placeholder]="booking.size.toString()"
                    [readonlyValue]="booking.size | i18nPlural: viewModel.partySizePluralMapping"
                    [initialValue]="booking.size.toString()"
                    [inputType]="InputType.Number"
                    [validators]="viewModel.partySizeValidators"
                    (inputSubmitted)="viewModel.updatePartySize($event)"
                >
                </app-input>
            </div>
        </div>
        <hr class="m-0"/>
        <div
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Area
            </span>
            <div
                class="col-lg-9"
            >
                <button
                    class="btn btn-sm btn-outline-secondary disabled me-auto"
                    data-testid="booking-area"
                >
                    {{ area.displayName }}
                </button>
            </div>
        </div>
        <div
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Table
            </span>
            <div class="col-lg-9 hstack center gap-2">
                <button
                    class="btn btn-sm btn-outline-secondary editable"
                    data-testid="booking-table"
                    [disabled]="booking.lockedToTables"
                    [ngbTooltip]="booking.lockedToTables ? 'Locked to ' + (booking | tableName : area) : undefined"
                    (click)="showChangeBookingTableOptions()"
                >
                    {{ booking | tableName: area }}
                </button>
                @if (booking.lockedToTables) {
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        (click)="toggleBookingLockedToTables()"
                        [ngbTooltip]="'Unlock from ' + (booking | tableName : area)"
                    >
                        <img
                            ngSrc="assets/locked/normal.svg"
                            alt="Lock to Tables"
                            width="24"
                            height="24"
                        />
                    </button>
                } @else {
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        (click)="toggleBookingLockedToTables()"
                        [ngbTooltip]="'Lock to ' + (booking | tableName : area)"
                    >
                        <img
                            ngSrc="assets/unlocked/normal.svg"
                            alt="Unlock from Tables"
                            width="24"
                            height="24"
                        />
                    </button>
                }
            </div>
        </div>
        <div
            *ngIf="booking.cancellation?.charge(); let charge"
            class="row align-items-baseline"
        >
            <div
                class="col-lg-3 py-2"
            >
                <span
                    class="text-secondary"
                >Cancel. Charge</span>
                <span>
                    <img
                        src="../../../../../assets/credit-card/normal.svg"
                        class="icon-sm icon-leading"
                    >
                </span>
                <span
                    class="text-muted"
                >Card on file</span>
            </div>
            <div class="col-lg-9 vstack gap-2">
                <div class="hstack center gap-2">
                    <button
                        class="btn btn-sm btn-outline-secondary disabled me-auto"
                    >
                        <ng-container
                            *ngIf="charge.isPerCover"
                        >
                            {{ null | currencyUnit: charge.unitAmount: 'GBP' }} per person
                        </ng-container>
                        <ng-container
                            *ngIf="!charge.isPerCover"
                        >
                            {{ null | currencyUnit: charge.unitAmount: 'GBP' }}
                        </ng-container>
                    </button>
                </div>
                @if (booking.cancellationCutOffDate(); as cutOffDate) {
                    <span
                        class="text-muted"
                        data-testid="cancellation-cut-off"
                    >
                        @if (booking.cancellation?.chargeAutomatically) {
                            Customer will be charged if they cancel after {{ cutOffDate | date: 'short' }}
                        } @else {
                            Chargable if customer cancels after {{ cutOffDate | date: 'short' }}
                        }
                    </span>
                }
            </div>
        </div>
        <div
            *ngIf="booking.pendingCancellationCharge as charge"
            class="row align-items-baseline"
        >
            <div
                class="col-lg-3 py-2"
            >
                <span
                    class="text-secondary"
                >Cancel. Charge</span>
                <span>
                    <img
                        src="../../../../../assets/credit-card/normal.svg"
                        class="icon-sm icon-leading"
                    >
                </span>
                <span
                    class="text-muted"
                >Waiting for card details</span>
            </div>
            <div class="col-lg-9 hstack center gap-1">
                <button
                    class="btn btn-sm btn-outline-secondary disabled me-auto"
                >
                    <ng-container
                        *ngIf="charge.isPerCover"
                    >
                        {{ null | currencyUnit: charge.unitAmount: 'GBP' }} per person
                    </ng-container>
                    <ng-container
                        *ngIf="!charge.isPerCover"
                    >
                        {{ null | currencyUnit: charge.unitAmount: 'GBP' }}
                    </ng-container>
                </button>
                @if (viewModel.expiryDate; as expiryDate) {
                    <span
                        class="text-muted px-1"
                    >
                        Expires {{ expiryDate | date: 'short' }}
                    </span>
                }
                <button
                    class="btn btn-sm btn-outline-secondary"
                    (click)="copyPendingPaymentLinkToClipboard()"
                    placement="start"
                    ngbTooltip="Copy pending payment link to clipboard"
                >
                    <img
                        ngSrc="../../../../../assets/copy/normal.svg"
                        alt="Copy Booking Link"
                        width="24"
                        height="24"
                    >
                </button>
                <button
                    class="btn btn-sm btn-outline-secondary"
                    (click)="resendPendingPaymentRequested(resendPendingPaymentModalContent)"
                    placement="start"
                    ngbTooltip="Resend pending payment link"
                >
                    <img
                        ngSrc="../../../../../assets/refresh/normal.svg"
                        alt="Resend Booking Confirmation"
                        width="24"
                        height="24"
                    >
                </button>
            </div>
        </div>
        <div
            *ngIf="viewModel.depositAmount"
        >
            <div
                class="row align-items-baseline"
            >
                <div
                    class="col-lg-3 py-2"
                >
                    <div
                        class="vstack text-secondary"
                    >
                        Deposit
                    </div>
                    <span class="text-muted hstack align-items-center">
                        <img
                            src="../../../../../assets/cash/normal_dark.svg"
                            class="icon-sm icon-leading"
                        >
                        Payment taken
                    </span>
                </div>
                <div class="col-lg-9 vstack center gap-2">
                    <button
                        class="btn btn-sm btn-outline-secondary disabled me-auto"
                        [ngClass]="{
                            'text-decoration-line-through': viewModel.depositRefundDate !== null
                        }"
                    >
                        {{ viewModel.depositAmount }}
                    </button>
                    @if (viewModel.depositRefundDate !== null) {
                        <span
                            class="text-muted"
                        >
                            Refunded on {{ viewModel.depositRefundDate | date: 'short' }}
                        </span>
                    }
                </div>
            </div>
        </div>
        <div
            *ngIf="booking.pendingDepositCharge as charge"
        >
            <div
                class="row align-items-baseline"
            >
                <div
                    class="col-lg-3 py-2"
                >
                    <div
                        class="vstack text-secondary"
                    >
                        Deposit
                    </div>
                    <span class="text-muted hstack align-items-center">
                        <img
                            ngSrc="../../../../../assets/cash/normal_dark.svg"
                            class="icon-sm icon-leading"
                            height="16"
                            width="16"
                        >
                        Payment Due
                    </span>
                </div>
                <div class="col-lg-9 hstack center gap-1">
                    <button
                        class="btn btn-sm btn-outline-secondary disabled me-auto"
                    >
                        <ng-container
                            *ngIf="charge.isPerCover"
                        >
                            {{ null | currencyUnit: charge.unitAmount: 'GBP' }} per person
                        </ng-container>
                        <ng-container
                            *ngIf="!charge.isPerCover"
                        >
                            {{ null | currencyUnit: charge.unitAmount: 'GBP' }}
                        </ng-container>
                    </button>
                    @if (viewModel.expiryDate; as expiryDate) {
                        <span
                            class="text-muted px-1"
                        >
                            Expires {{ expiryDate | date: 'short' }}
                        </span>
                    }
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        (click)="copyPendingPaymentLinkToClipboard()"
                        placement="start"
                        ngbTooltip="Copy pending payment link to clipboard"
                    >
                        <img
                            ngSrc="../../../../../assets/copy/normal.svg"
                            alt="Copy Booking Link"
                            width="24"
                            height="24"
                        >
                    </button>
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        (click)="resendPendingPaymentRequested(resendPendingPaymentModalContent)"
                        placement="start"
                        ngbTooltip="Resend pending payment link"
                    >
                        <img
                            ngSrc="../../../../../assets/refresh/normal.svg"
                            alt="Resend Booking Confirmation"
                            width="24"
                            height="24"
                        >
                    </button>
                </div>
            </div>
        </div>
        <div
            *ngIf="viewModel.bookingReason as bookingReason"
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Reason
            </span>
            <div
                class="col-lg-9"
            >
                <button
                    class="btn btn-sm btn-outline-secondary disabled me-auto"
                >
                    {{ bookingReason }}
                </button>
            </div>
        </div>
        <div
            *ngIf="viewModel.bookingEvent as bookingEvent"
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Event
            </span>
            <div
                class="col-lg-9"
            >
                <button
                    class="btn btn-sm btn-outline-secondary disabled me-auto"
                >
                    {{ bookingEvent }}
                </button>
            </div>
        </div>
        <div
            *ngFor="let question of viewModel.checkboxQuestions"
            >
            <div
                class="row align-items-baseline"
            >
                <span
                    class="col-lg-3 text-secondary py-2"
                >
                    Question
                </span>
                <div
                    class="col-lg-9 hstack gap-2"
                >
                    {{ question.question }}
                    <button
                        class="btn btn-sm btn-outline-secondary disabled"
                    >
                        <span
                            *ngIf="question.ticked"
                        >
                            Yes
                        </span>
                        <span
                            *ngIf="!question.ticked"
                        >
                            No
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div
            *ngFor="let question of viewModel.dropdownQuestions"
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Question
            </span>
            <div
                class="col-lg-9 hstack gap-2"
            >
                {{ question.question }}
                <button
                    class="btn btn-sm btn-outline-secondary disabled"
                >
                    <span>
                        {{ question.answer }}
                    </span>
                </button>
            </div>
        </div>
        <hr class="m-0"/>
        <div
            class="row align-items-baseline"
        >
            <div class="py-2 col-lg-3 hstack align-items-start gap-2">
                <span class="text-secondary">
                    Booking Notes
                </span>
                <i
                    class="bi bi-info-circle text-muted"
                    [ngbTooltip]="'Notes added by the customer about the booking'"
                >
                </i>
            </div>
            <div
                class="col-lg-9 hstack gap-2 align-items-start"
            >
                <app-textarea
                    #customerMessage
                    [style.display]="'contents'"
                    [placeholder]="'Add Customer\'s Booking Notes'"
                    [isEditable]="true"
                    [initialValue]="booking.notes"
                    [rows]="3"
                    (textSubmitted)="viewModel.updateCustomerMessage($event)">
                </app-textarea>
            </div>
        </div>
        <div
            class="row align-items-baseline"
        >
            <div class="py-2 col-lg-3 hstack align-items-start gap-2">
                <span class="text-secondary">
                    Your Notes
                </span>
                <i
                    class="bi bi-info-circle text-muted"
                    [ngbTooltip]="'Private notes about the booking, only visible to staff'"
                >
                </i>
            </div>
            <div
                class="col-lg-9 hstack gap-2 align-items-start"
            >
                <button
                    *ngIf="viewModel.showHidePrivateNotesButton"
                    class="btn btn-sm btn-outline-secondary"
                    (click)="viewModel.togglePrivateNotesVisibility()"
                >
                    <img
                        ngSrc="../../../../../assets/visible/normal.svg"
                        width="24"
                        height="24"
                        alt="Hide Private Notes"
                    >
                </button>
                <button
                    *ngIf="viewModel.showShowPrivateNotesButton"
                    class="btn btn-sm btn-outline-secondary"
                    (click)="viewModel.togglePrivateNotesVisibility()"
                >
                    <img
                        ngSrc="../../../../../assets/hidden/normal.svg"
                        width="24"
                        height="24"
                        alt="Show Private Notes"
                    >
                </button>
                <app-textarea
                    #merchantNotes
                    [style.display]="'contents'"
                    [isEditable]="!viewModel.hidePrivateNotes"
                    [redacted]="viewModel.hidePrivateNotes"
                    [placeholder]="'Add Your Booking Notes'"
                    [initialValue]="booking.merchantNotes"
                    [rows]="3"
                    (textSubmitted)="viewModel.updateMerchantNotes($event)">
                </app-textarea>
            </div>
        </div>
    </div>
</div>

<ng-template
    #resendPendingPaymentModalContent
>
    <div class="vstack gap-2">
        <span>
            How would you like to resend the link?
        </span>
        <label class="text-secondary">
            Send to:
        </label>
        <div class="vstack gap-2 align-content-stretch">
            <label
                class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                [class.disabled]="booking.emailAddress === null"
            >
                <div class="m-1 hstack gap-2">
                    <input
                        type="radio"
                        [value]="'email'"
                        [checked]="pendingPaymentDeliveryMethod.value === 'email'"
                        (change)="pendingPaymentDeliveryMethod.setValue('email')"
                        class="form-check-input m-0"
                        role="button"
                    >
                    <div class="vstack align-items-start">
                        <span
                            *ngIf="booking.emailAddress !== null"
                            class="fw-semibold"
                        >
                            Email: {{ booking.emailAddress }}
                        </span>
                        <span
                            *ngIf="booking.emailAddress === null"
                            class="fw-normal"
                        >
                            Email: Email address not provided
                        </span>
                    </div>
                </div>
            </label>
            <label
                class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                [class.disabled]="booking.phoneNumber === null"
            >
                <div class="m-1 hstack gap-2">
                    <input
                        type="radio"
                        [value]="'sms'"
                        [checked]="pendingPaymentDeliveryMethod.value === 'sms'"
                        (change)="pendingPaymentDeliveryMethod.setValue('sms')"
                        class="form-check-input m-0"
                        role="button"
                    >
                    <div class="vstack align-items-start">
                        <span
                            *ngIf="booking.phoneNumber !== null"
                            class="fw-semibold"
                        >
                            SMS: {{ booking.phoneNumber | phoneNumber }}
                        </span>
                        <span
                            *ngIf="booking.phoneNumber === null"
                            class="fw-normal"
                        >
                            SMS: Phone number not provided
                        </span>
                    </div>
                </div>
            </label>
        </div>
    </div>
</ng-template>
