import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingStatus, BookingStatusType } from '@app/domain/BookingStatus'
import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-booking-list-item',
    templateUrl: './booking-list-item.component.html',
})
export class BookingListItemComponent implements OnInit {

    @Input() booking!: Booking
    @Input() area!: Area
    @Input() showDate = false
    @Input() showLocation = false
    @Input() showDetails = false

    partySizePluralMapping = {
        '=0': 'No covers',
        '=1': '1 cover',
        other: '# covers',
    }

    constructor() { }

    ngOnInit() { }

    protected readonly BookingStatus = BookingStatus
    protected readonly BookingStatusType = BookingStatusType
}
