import { Injectable } from '@angular/core'
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'

@Injectable()
export class LocaleDateParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDate | null {
        const nativeDate = new Date(value)
        if (isNaN(nativeDate.getTime())) {
            return null
        }
        return this.makeNgbDate(nativeDate)
    }

    format(date: NgbDate | null): string {
        if (date === null) {
            return ''
        }
        const nativeDate = this.makeDate(date)
        return nativeDate.toLocaleDateString()
    }

    private makeDate(date: NgbDate): Date {
        return new Date(date.year, date.month - 1, date.day)
    }

    private makeNgbDate(date: Date): NgbDate {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }
}
