import { Area } from '@app/domain/Area'
import { BehaviorSubject, Observable, map } from 'rxjs'
import { ContextService } from '@services/context.service'
import { Injectable } from '@angular/core'
import { LocalStorageService } from '@services/local-storage-service'

@Injectable({
    providedIn: 'root',
})
export class DiaryPreferencesService {

    _showFinishedBookings: BehaviorSubject<boolean>
    showFinishedBookings$!: Observable<boolean>
    _hidePrivateNotes: BehaviorSubject<boolean>
    hidePrivateNotes$!: Observable<boolean>
    _showBookingSource: BehaviorSubject<boolean>
    showBookingSource$!: Observable<boolean>
    _calendarTableHeight: BehaviorSubject<'extra-small' | 'small' | 'medium' | 'large'>
    calendarTableHeight$!: Observable<'extra-small' | 'small' | 'medium' | 'large'>
    _calendarWidth: BehaviorSubject<'small' | 'medium' | 'large'>
    calendarWidth$!: Observable<'small' | 'medium' | 'large'>
    _calendarFitToScreen: BehaviorSubject<boolean>
    calendarFitToScreen$!: Observable<boolean>
    _defaultReasonId: BehaviorSubject<string | null>
    defaultReasonId$!: Observable<string | null>
    confirmAddBooking$!: Observable<boolean>
    requireChargeByDefault$!: Observable<boolean>
    _confirmMoveBooking: BehaviorSubject<boolean>
    confirmMoveBooking$!: Observable<boolean>
    _walkInDurationTrimPercentage: BehaviorSubject<number>
    walkInDurationTrimPercentage$!: Observable<number>
    _collapsedAreaIds: BehaviorSubject<string[]>
    collapsedAreaIds$!: Observable<string[]>
    pendingPaymentExpiryMinutes$!: Observable<number | null>

    constructor(
        private contextService: ContextService,
        private localStorageService: LocalStorageService
    ) {
        this._showFinishedBookings = new BehaviorSubject<boolean>(this.getShowFinishedBookings())
        this.showFinishedBookings$ = this._showFinishedBookings.asObservable()
        this._hidePrivateNotes = new BehaviorSubject<boolean>(this.getHidePrivateNotes())
        this.hidePrivateNotes$ = this._hidePrivateNotes.asObservable()
        this._showBookingSource = new BehaviorSubject<boolean>(this.getShowBookingSource())
        this.showBookingSource$ = this._showBookingSource.asObservable()
        this._calendarTableHeight = new BehaviorSubject<'extra-small' | 'small' | 'medium' | 'large'>(this.getCalendarTableHeight())
        this.calendarTableHeight$ = this._calendarTableHeight.asObservable()
        this._calendarWidth = new BehaviorSubject<'small' | 'medium' | 'large'>(this.getCalendarWidth())
        this.calendarWidth$ = this._calendarWidth.asObservable()
        this._calendarFitToScreen = new BehaviorSubject<boolean>(this.getCalendarFitToScreen())
        this.calendarFitToScreen$ = this._calendarFitToScreen.asObservable()
        this._defaultReasonId = new BehaviorSubject<string | null>(this.getDefaultReasonId())
        this.defaultReasonId$ = this._defaultReasonId.asObservable()
        this.confirmAddBooking$ = this.contextService.venue$.pipe(map(venue => venue.diaryPreferences.confirmAddBooking))
        this.requireChargeByDefault$ = this.contextService.venue$.pipe(map(venue => venue.diaryPreferences.merchantBookingsRequireChargeByDefault))
        this._confirmMoveBooking = new BehaviorSubject<boolean>(this.getConfirmMoveBooking())
        this.confirmMoveBooking$ = this._confirmMoveBooking.asObservable()
        this._walkInDurationTrimPercentage = new BehaviorSubject<number>(this.getWalkInDurationTrimPercentage())
        this.walkInDurationTrimPercentage$ = this._walkInDurationTrimPercentage.asObservable()
        this._collapsedAreaIds = new BehaviorSubject<string[]>(this.getCollapsedAreaIds())
        this.collapsedAreaIds$ = this._collapsedAreaIds.asObservable()
        this.pendingPaymentExpiryMinutes$ = this.contextService.venue$
            .pipe(map(venue => venue.diaryPreferences.pendingPaymentExpiryMinutes))
    }

    getShowFinishedBookings(): boolean {
        const value = this.localStorageService.getItem('showFinishedBookings')
        if (value === null) {
            return true
        }
        return value === 'true'
    }

    setShowFinishedBookings(showFinishedBookings: boolean) {
        this.localStorageService.setItem('showFinishedBookings', showFinishedBookings.toString())
        this._showFinishedBookings.next(showFinishedBookings)
    }

    getHidePrivateNotes(): boolean {
        const value = this.localStorageService.getItem('hidePrivateNotes')
        if (value === null) {
            return false
        }
        return value === 'true'
    }

    setHidePrivateNotes(hidePrivateNotes: boolean) {
        this.localStorageService.setItem('hidePrivateNotes', hidePrivateNotes.toString())
        this._hidePrivateNotes.next(hidePrivateNotes)
    }

    getShowBookingSource(): boolean {
        const value = this.localStorageService.getItem('showBookingSource')
        if (value === null) {
            return true
        }
        return value === 'true'
    }

    setShowBookingSource(showBookingSource: boolean) {
        this.localStorageService.setItem('showBookingSource', showBookingSource.toString())
        this._showBookingSource.next(showBookingSource)
    }

    getCalendarTableHeight(): 'extra-small' | 'small' | 'medium' | 'large' {
        const value = this.localStorageService.getItem('calendarTableHeight')
        if (value === null) {
            return 'large'
        }
        return value as 'small' | 'medium' | 'large'
    }

    setCalendarTableHeight(calendarTableHeight: 'extra-small' | 'small' | 'medium' | 'large') {
        this.localStorageService.setItem('calendarTableHeight', calendarTableHeight)
        this._calendarTableHeight.next(calendarTableHeight)
    }

    getCalendarWidth(): 'small' | 'medium' | 'large' {
        const value = this.localStorageService.getItem('calendarWidth')
        if (value === null) {
            return 'medium'
        }
        return value as 'small' | 'medium' | 'large'
    }

    setCalendarWidth(calendarWidth: 'small' | 'medium' | 'large') {
        this.localStorageService.setItem('calendarWidth', calendarWidth)
        this._calendarWidth.next(calendarWidth)
    }

    getCalendarFitToScreen(): boolean {
        const value = this.localStorageService.getItem('calendarFitToScreen')
        if (value === null) {
            return false
        }
        return value === 'true'
    }

    setCalendarFitToScreen(calendarFitToScreen: boolean) {
        this.localStorageService.setItem('calendarFitToScreen', calendarFitToScreen.toString())
        this._calendarFitToScreen.next(calendarFitToScreen)
    }

    getDefaultReasonId(): string | null {
        return this.localStorageService.getItem('defaultReasonId') as string | null
    }

    setDefaultReasonId(defaultReasonId: string | null) {
        if (defaultReasonId === null) {
            this.localStorageService.removeItem('defaultReasonId')
        } else {
            this.localStorageService.setItem('defaultReasonId', defaultReasonId)
        }
        this._defaultReasonId.next(defaultReasonId)
    }

    getConfirmMoveBooking(): boolean {
        const value = this.localStorageService.getItem('confirmMoveBooking')
        if (value === null) {
            return true
        }
        return value === 'true'
    }

    setConfirmMoveBooking(confirmMoveBooking: boolean) {
        this.localStorageService.setItem('confirmMoveBooking', confirmMoveBooking.toString())
        this._confirmMoveBooking.next(confirmMoveBooking)
    }

    getWalkInDurationTrimPercentage(): number {
        const value = this.localStorageService.getItem('walkInDurationTrimPercentage')
        if (value === null) {
            return 50
        }
        return value as number
    }

    setWalkInDurationTrimPercentage(walkInDurationTrimPercentage: number) {
        this.localStorageService.setItem('walkInDurationTrimPercentage', walkInDurationTrimPercentage)
        this._walkInDurationTrimPercentage.next(walkInDurationTrimPercentage)
    }

    collapseArea(area: Area) {
        const collapsedAreaIds = this.getCollapsedAreaIds()
        if (!collapsedAreaIds.includes(area.id)) {
            collapsedAreaIds.push(area.id)
            this.setCollapsedAreaIds(collapsedAreaIds)
        }
    }

    expandArea(area: Area) {
        const collapsedAreaIds = this.getCollapsedAreaIds()
        const index = collapsedAreaIds.indexOf(area.id)
        if (index !== -1) {
            collapsedAreaIds.splice(index, 1)
            this.setCollapsedAreaIds(collapsedAreaIds)
        }
    }

    getCollapsedAreaIds(): string[] {
        const value = this.localStorageService.getItem('collapsedAreaIds')
        if (value === null) {
            return []
        }
        return value as string[]
    }

    setCollapsedAreaIds(collapsedAreaIds: string[]) {
        this.localStorageService.setItem('collapsedAreaIds', collapsedAreaIds)
        this._collapsedAreaIds.next(collapsedAreaIds)
    }
}
