import { UserNotificationPreferences } from '@app/domain/UserNotificationPreferences'
import { Venue } from '@app/domain/Venue'

export enum UserRole {
    OWNER,
    MANAGER,
}

export class User {

    constructor(
        public id: string,
        public name: string,
        public emailAddress: string,
        public role: UserRole,
        public venueIds: string[] | null,
        public notificationPreferences: UserNotificationPreferences[]
    ) { }

    get isOwner(): boolean {
        return this.role === UserRole.OWNER
    }

    get isManager(): boolean {
        return this.role === UserRole.MANAGER
    }

    worksInVenue(venue: Venue): boolean {
        if (this.venueIds === null) {
            return true
        }
        return this.venueIds.includes(venue.id)
    }

    canEditUser(user: User): boolean {
        if (this.role === UserRole.OWNER) {
            return true
        }
        return this.id === user.id
    }
}
