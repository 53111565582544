import { PartySizeDurationDTO } from '@services/DTO/PartySizeDurationDTO'
import { PeriodDTO } from '@services/DTO/PeriodDTO'

export class ReasonScheduleRuleDTO {
    constructor(
        public id: string,
        public daysOfWeek: number[],
        public period: PeriodDTO | null,
        public bookingDurations: PartySizeDurationDTO[]
    ) { }
}
