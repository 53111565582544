<div>
    <div>
        <div
            *ngIf="observable | withLoading | async as observable"
        >
            <ng-template
                [ngIf]="{ value: observable.value }"
            >
                <ng-content></ng-content>
            </ng-template>
            <ng-template
                [ngIf]="observable.loading"
            >
                <div class="d-flex align-content-center justify-content-center">
                    <div class="spinner-border text-secondary" role="status">
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="observable.error">
                Error
            </ng-template>
        </div>
    </div>
</div>
