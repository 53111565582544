import { BookingService } from '../../../../services/booking.service'
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core'
import { ContextService } from '../../../../services/context.service'
import { map, Observable, Subject, takeUntil, tap } from 'rxjs'
import { Venue } from '../../../../domain/Venue'
import { Booking } from '../../../../domain/Booking'
import { Area } from '../../../../domain/Area'
import { Customer } from '../../../../domain/Customer'
import { Page } from '../../../../domain/Page'

@Component({
    selector: 'app-customer-booking-list',
    templateUrl: './customer-booking-list.component.html',
})
export class CustomerBookingListComponent implements OnInit, OnDestroy {

    @Input() customer!: Customer
    @Output() bookingSelected = new EventEmitter<Booking>()
    venue$!: Observable<Venue>
    isSearching = false
    bookings$?: Observable<{ booking: Booking, area: Area }[]>
    config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 0,
    }
    private onDestroy = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private contextService: ContextService
    ) {
        this.venue$ = this.contextService.venue$
    }

    ngOnInit() {
        this.searchBookings()
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    pageChanged(page: number) {
        this.config.currentPage = page
        this.searchBookings()
    }

    searchBookings() {
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            return
        }
        const business = this.contextService.getBusiness()
        if (!business) {
            return
        }
        const venue = this.contextService.getVenue()
        if (!venue) {
            return
        }
        this.isSearching = true
        this.bookings$ = this.bookingService.getCustomerBookings(
            organisation.id,
            business.id,
            this.customer,
            this.config.currentPage - 1,
            this.config.itemsPerPage,
            'desc'
        )
            .pipe(
                takeUntil(this.onDestroy),
                tap((page: Page<Booking>) => {
                    this.config.totalItems = page.totalItems
                    this.config.itemsPerPage = page.pageSize
                }),
                map((page: Page<Booking>) => {
                    return page.items.flatMap((booking: Booking) => {
                        const area = venue.areaBookingIsIn(booking)
                        if (!area) {
                            return []
                        }
                        return {
                            booking,
                            area,
                        }
                    })
                })
            )
        this.bookings$?.subscribe(() => {
            this.isSearching = false
        })
    }
}
