import { Answer } from './Answer'
import { Bookable } from '@app/domain/Bookable'
import { BookingFeedback } from './BookingFeedback'
import { BookingFeedbackRequest } from './BookingFeedbackRequest'
import { BookingReminder } from './BookingReminder'
import { BookingSource } from './BookingSource'
import {
    activeBookingStatusTypes,
    BookingStatus,
    BookingStatusType,
    statusTypesIncurringCancellationCharges,
} from './BookingStatus'
import { Cancellation } from '@app/domain/Cancellation'
import { Charge } from '@app/domain/Charge'
import { Contact } from './Contact'
import { Deposit } from '@app/domain/Deposit'
import { HasBookingStatus } from '@app/features/bookings/pipes/booking-status-filter.pipe'
import { Helper } from '@app/helpers/utilities/helper'
import { QuestionType } from '@app/domain/Question'
import { Customer } from '@app/domain/Customer'

export class Booking implements HasBookingStatus {
    constructor(
        public id: string,
        public organisationId: string,
        public tableIds: string[],
        public contact: Contact,
        public customer: Customer | null,
        public size: number,
        public start: Date,
        public duration: number,
        public notes: string | null,
        public merchantNotes: string | null,
        public agreedToMarketing: boolean,
        public agreedToTerms: boolean,
        public paymentCustomerId: string | null,
        public source: BookingSource,
        public reminders: BookingReminder[],
        public statusEvents: BookingStatus[],
        public feedbackRequests: BookingFeedbackRequest[],
        public feedback: BookingFeedback[],
        public reasonId: string | null,
        public eventId: string | null,
        public answers: Answer[],
        public cancellation: Cancellation | null,
        public deposit: Deposit | null,
        public sendFeedbackRequest: boolean,
        public requiresWheelchairAccess: boolean,
        public lockedToTables: boolean,
        public dateVoided: Date | null
    ) { }

    get end(): Date {
        let date = new Date(this.start)
        date.setMinutes(this.start.getMinutes() + this.duration)
        return date
    }

    get pendingCancellationCharge(): Charge | null {
        const status = this.status()
        const isPendingCancellation = status.type === BookingStatusType.PendingPayment
        if (!isPendingCancellation) {
            return null
        }
        return status.cancellationCharge()
    }

    get pendingDepositCharge(): Charge | null {
        const status = this.status()
        const isPendingPayment = status.type === BookingStatusType.PendingPayment
        if (!isPendingPayment) {
            return null
        }
        return status.depositCharge()
    }

    get dateCreated(): Date {
        const firstStatus = this.statusEvents[0]
        return new Date(firstStatus.dateTime)
    }

    get statusType(): BookingStatusType {
        return this.status().type
    }

    get firstName(): string | null {
        return this.customer?.firstName ?? this.contact.firstName
    }

    get lastName(): string | null {
        return this.customer?.lastName ?? this.contact.lastName
    }

    get emailAddress(): string | null {
        return this.customer?.emailAddress ?? this.contact.emailAddress
    }

    get phoneNumber(): string | null {
        return this.customer?.phoneNumber ?? this.contact.phoneNumber
    }

    get name(): string {
        return this.customer?.name() ?? this.contact.name
    }

    occupiedEndDate(turnaroundInterval: number): Date {
        const turnaroundMilliseconds = turnaroundInterval * 60 * 1000
        return new Date(this.end.getTime() - turnaroundMilliseconds)
    }

    isInProgressAtDate(date: Date): boolean {
        if (this.start > date) {
            return false
        }
        if (this.end < date) {
            return false
        }
        return true
    }

    occupiesTableBetweenDates(startDate: Date, endDate: Date, onTables?: Bookable[]): boolean {
        if (onTables) {
            if (!this.occupiesAnyTable(onTables)) {
                return false
            }
        }
        return !(this.end <= startDate || this.start >= endDate)
    }

    occupiesAnyTable(tables: Bookable[]): boolean {
        return this.tableIds.some(tableId => {
            return tables.some(table => table.tableIds.includes(tableId))
        })
    }

    occursBetweenDates(startDate: Date, endDate: Date): boolean {
        if (startDate <= this.start && endDate >= this.start) {
            return true
        }
        if (startDate >= this.start && startDate <= this.end) {
            return true
        }
        return false
    }

    isActive(): boolean {
        return activeBookingStatusTypes().includes(this.status().type)
    }

    startsBetweenDates(startDate: Date, endDate: Date): boolean {
        return Helper.startsBetweenDate(this.start, startDate, endDate)
    }

    isWalkIn(): boolean {
        if (this.customer !== null) {
            return false
        }
        return this.source === BookingSource.Merchant && this.contact.name.length === 0
    }

    hasBeenConfirmed(): boolean {
        return this.reminders.some(reminder => reminder.confirmedDateTime !== null)
    }

    hasAcknowledgedEndTime(): boolean {
        const acknowledgeAnswer = this.answers.find(answer => {
            return answer.question.type === QuestionType.AcknowledgeEndTime
        })
        if (!acknowledgeAnswer) {
            return false
        }
        return acknowledgeAnswer.isTicked()
    }

    status(): BookingStatus {
        if (this.statusEvents.length === 0) {
            throw Error('Unknown booking status')
        }
        return this.statusEvents[this.statusEvents.length - 1]
    }

    isRunningLate(currentDateTime: Date): boolean {
        if (this.status().type !== BookingStatusType.Booked) {
            return false
        }
        if (this.start > currentDateTime) {
            return false
        }
        if (this.end < currentDateTime) {
            return false
        }
        const minutesToBeLate = 15
        const minutesLate = Math.floor(
            (currentDateTime.getTime() - this.start.getTime()) / 1000 / 60
        )
        return minutesLate >= minutesToBeLate
    }

    updateContact(contact: Contact) {
        this.contact = contact
    }

    statusDateTime(statusType: BookingStatusType): Date | null {
        const status = this.statusEvents.find(status => {
            return status.type === statusType
        })
        if (status === undefined) {
            return null
        }
        return new Date(status.dateTime)
    }

    cancellationCutOffDate(): Date | null {
        if (this.cancellation === null) {
            return null
        }
        if (this.cancellation.cutOffHours === null) {
            return null
        }
        return new Date(this.start.getTime() - this.cancellation.cutOffHours * 60 * 60 * 1000)
    }

    activeCancellationChargeForBooking(): Charge | null {
        if (this.cancellation === null) {
            return null
        }
        if (this.cancellation.amountRemaining() <= 0) {
            return null
        }
        // assume we do not partially charge for cancellations
        const charge = this.cancellation.charge()
        if (!this.isCancellationChargeActive()) {
            return null
        }
        return charge
    }

    canChargeCancellationFee(): boolean {
        const wouldCharge = this.activeCancellationChargeForBooking() !== null
        const shouldCharge = this.shouldChargeCancellationFee()
        const couldCharge = this.cancellation?.paymentMethodId !== null
        return wouldCharge && shouldCharge && couldCharge
    }

    shouldChargeCancellationFee(): boolean {
        return statusTypesIncurringCancellationCharges().includes(this.status().type)
    }

    expiryDate(): Date | null {
        const status = this.status()
        if (status.type !== BookingStatusType.PendingPayment) {
            return null
        }
        return status.expiryDate()
    }

    updateCustomer(customer: Customer) {
        this.customer = customer
    }

    private isCancellationChargeActive(): boolean {
        const cutOffDate = this.cancellationCutOffDate()
        if (cutOffDate === null) {
            return false
        }
        const status = this.status()
        if (status.type !== BookingStatusType.Cancelled) {
            return false
        }
        const cancelledDate = status.dateTime
        const isAfterCutOff = cancelledDate >= cutOffDate
        return isAfterCutOff
    }
}
