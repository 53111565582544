<div class="flex-column d-flex flex-sm-row gap-2 gap-sm-4 align-items-sm-center">
    <div class="vstack">
        <span class="fw-semibold">
            {{ title }}
        </span>
        <span class="form-text">
            <span style="white-space: pre-line">{{subtitle}}</span>
        </span>
    </div>
    <span
        class="text-start text-sm-end flex-shrink-0"
        [ngClass]="contentClass"
    >
        <ng-content></ng-content>
    </span>
</div>
