export enum DateComponentUnit {
    DAY_OF_WEEK = 'DAY_OF_WEEK',
    DAY_OF_MONTH = 'DAY_OF_MONTH',
    DAY_OF_YEAR = 'DAY_OF_YEAR',
    MONTH_OF_YEAR = 'MONTH_OF_YEAR'
}

export namespace DateComponentUnit {

    export function name(unit: DateComponentUnit): string {
        switch (unit) {
        case DateComponentUnit.DAY_OF_WEEK: return 'Day Of Week'
        case DateComponentUnit.DAY_OF_MONTH: return 'Day Of Month'
        case DateComponentUnit.DAY_OF_YEAR: return 'Day Of Year'
        case DateComponentUnit.MONTH_OF_YEAR: return 'Month Of Month'
        }
    }
}
