import { NgbDateNativeAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'ngbDate',
})
export class NgbDatePipe implements PipeTransform {

    private ngbDateAdapter = new NgbDateNativeAdapter()

    transform(ngbDate: NgbDateStruct | null): Date | null {
        if (!ngbDate) {
            return null
        }
        return this.ngbDateAdapter.toModel(ngbDate)
    }
}
