import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { Pipe, PipeTransform } from '@angular/core'
import { Table } from '@app/domain/Table'

type TableNameStyle = 'short' | 'long'

@Pipe({
    name: 'tableName',
})
export class TableNamePipe implements PipeTransform {

    transform(booking?: Booking, area?: Area, style: TableNameStyle = 'long', tables?: Table[]): string {
        const bookingTables = booking ? area?.tablesUsedForBooking(booking) : []
        const tablesToName = tables ?? bookingTables ?? []
        switch (style) {
        case 'short':
            return tablesToName.map(table => table.shortFormattedDisplayName).join(', ')
        case 'long':
            return tablesToName.map(table => table.formattedDisplayName).join(', ')
        }
    }
}
