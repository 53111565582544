export class VenueVelocityDTO {
    constructor(
        public bookingLimit: number | null,
        public coverLimit: number | null,
        public bookingSlotBookingLimits: BookingSlotVelocityLimitDTO[],
        public bookingSlotCoverLimits: BookingSlotVelocityLimitDTO[]
    ) { }
}

export class BookingSlotVelocityLimitDTO {
    constructor(
        public dayOfWeek: number,
        public time: string,
        public limit: number
    ) { }
}
