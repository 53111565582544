import { Bookable } from '@app/domain/Bookable'
import { BookingReason } from '@app/domain/BookingReason'
import { Helper } from '@app/helpers/utilities/helper'

export class Table implements Bookable {
    public id: string
    public displayName: string
    public reservable: boolean
    public minimumSeats: number
    public maximumSeats: number
    public bookingOrder: number
    public displayOrder: number
    public floorCoordinates: { x: number, y: number }
    public dimensions: { width: number, height: number }
    public reasonIds: string[]
    public wheelchairAccessible: boolean | null
    public dateCreated: Date
    public dateUpdated: Date

    constructor(
        id: string,
        displayName: string,
        reservable: boolean,
        minimumSeats: number,
        maximumSeats: number,
        bookingOrder: number,
        displayOrder: number,
        floorCoordinates: { x: number, y: number },
        dimensions: { width: number, height: number },
        reasonIds: string[],
        wheelchairAccessible: boolean | null,
        dateCreated: Date,
        dateUpdated: Date
    ) {
        this.id = id
        this.displayName = displayName
        this.reservable = reservable
        this.minimumSeats = minimumSeats
        this.maximumSeats = maximumSeats
        this.bookingOrder = bookingOrder
        this.displayOrder = displayOrder
        this.floorCoordinates = floorCoordinates
        this.dimensions = dimensions
        this.reasonIds = reasonIds
        this.wheelchairAccessible = wheelchairAccessible
        this.dateCreated = dateCreated
        this.dateUpdated = dateUpdated
    }

    get formattedDisplayName(): string {
        return Helper.getDisplayNameFromName(this.displayName, 'Table')
    }

    get shortFormattedDisplayName(): string {
        return Helper.getDisplayNameFromName(this.displayName, 'T', false)
    }

    get tableIds(): string[] {
        return [this.id]
    }

    get tableBookingOrder(): number | null {
        return this.bookingOrder
    }

    get combinationBookingOrder(): number | null {
        return null
    }

    fitsPartySize(partySize: number): boolean {
        return partySize >= this.minimumSeats && partySize <= this.maximumSeats
    }

    doesNotExceedPartySize(partySize: number): boolean {
        return partySize <= this.maximumSeats
    }

    addReason(reason: BookingReason) {
        if (this.reasonIds.includes(reason.id)) {
            return
        }
        this.reasonIds.push(reason.id)
    }

    removeReason(reason: BookingReason) {
        const index = this.reasonIds.findIndex(id => id === reason.id)
        if (index === -1) {
            return
        }
        this.reasonIds.splice(index, 1)
    }

    isReservableForReasonWithId(reasonId: string): boolean {
        return this.reasonIds.includes(reasonId)
    }
}
