import * as d3 from 'd3'
import { Component, Input, OnInit } from '@angular/core'
import {
    FloorTableViewModel,
} from '@app/features/shared/components/area-floor/floor-table.view-model'


@Component({
    selector: '[app-floor-table]',
    templateUrl: './floor-table.component.html',
    styleUrls: ['./floor-table.component.sass'],
})
export class FloorTableComponent implements OnInit {

    @Input() tableViewModel!: FloorTableViewModel
    @Input() xScale!: d3.ScaleLinear<number, number>
    @Input() yScale!: d3.ScaleLinear<number, number>

    bookedStatus = ''

    ngOnInit() {
        if (this.tableViewModel.isBooked) {
            this.bookedStatus += ' booked'
        }
        if (this.tableViewModel.isSeated) {
            this.bookedStatus += ' seated'
        }
        if (this.tableViewModel.isDisabled) {
            this.bookedStatus += ' disabled'
        }
        if (this.tableViewModel.isSelected) {
            this.bookedStatus += ' selected'
        }
    }
}
