import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.sass'],
})
export class TextareaComponent implements OnInit {

    isEditing: boolean = false
    value?: string | null
    @Input() isEditable!: boolean
    @Input() redacted?: boolean
    @Input() placeholder?: string | null
    @Input() rows?: number
    @Input() initialValue?: string | null
    @Output() textSubmitted: EventEmitter<string | null> = new EventEmitter<string | null>()

    ngOnInit(): void {
        this.value = this.initialValue || ''
    }

    startEditing() {
        this.isEditing = true
    }

    onInputChange(event: Event) {
        this.value = (event.target as any).value
    }

    cancelTextUpdate() {
        this.value = this.initialValue || ''
        this.isEditing = false
    }

    finishTextUpdate() {
        this.isEditing = false
    }

    saveTextUpdate() {
        this.textSubmitted.emit(this.value || null)
    }
}
