export class Address {
    constructor(
        public addressLineOne: string,
        public addressLineTwo: string | null,
        public city: string,
        public county: string | null,
        public postCode: string,
        public country: string
    ) { }

    get fullAddress() {
        return [
            this.addressLineOne,
            this.addressLineTwo,
            this.city,
            this.county,
            this.postCode,
            this.country,
        ]
            .filter(Boolean)
            .join(', ')
    }
}
