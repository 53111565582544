import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import { formatNumber } from '@angular/common'

@Pipe({
    name: 'notificationCount',
})
export class NotificationCountPipe implements PipeTransform {

    constructor(
        @Inject(LOCALE_ID) private locale: string
    ) { }

    transform(count: any): string {
        if (count === 0) {
            return ''
        }
        if (count > 9) {
            return '9+'
        }
        return formatNumber(count, this.locale)
    }
}
