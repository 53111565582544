<app-spinner-overlay
    [showSpinner]="isEditingBooking"
>
</app-spinner-overlay>

<div class="p-3 vstack gap-2">
    @if (noPaymentsInvolved) {
        <h5 class="fw-semibold my-0">
            Charges
        </h5>
        <div class="vstack gap-2">
            <div class="hstack gap-2 justify-content-between">
                <label
                    class="form-text"
                >
                    Cancellation Charges require a card on file with an
                    <br/>
                    optional cancellation / no-show policy.
                </label>
                <button
                    class="btn btn-sm btn-outline-secondary ms-auto hstack gap-1 text-nowrap"
                    (click)="addCancellationCharge()"
                >
                    Add Cancellation Charge
                    <img
                        ngSrc="assets/plus/normal.svg"
                        alt="Add"
                        height="16"
                        width="16"
                    />
                </button>
            </div>
            <div class="hstack gap-2 justify-content-between">
                <label
                    class="form-text"
                >
                    Deposit Charges require a payment to be made
                    <br/>
                    before the booking can be confirmed..
                </label>
                <button
                    class="btn btn-sm btn-outline-secondary ms-auto hstack gap-1 text-nowrap"
                    (click)="addDepositCharge()"
                >
                    Add Deposit Charge
                    <img
                        ngSrc="assets/plus/normal.svg"
                        alt="Add"
                        height="16"
                        width="16"
                    />
                </button>
            </div>
        </div>
    }
    @if (booking.pendingCancellationCharge) {
        <div class="bg-light rounded-3 p-3">
            <div class="vstack gap-2">
                <div class="hstack gap-2 justify-content-between">
                    <span class="text-muted">
                        Cancellation Charge
                    </span>
                    <div class="hstack gap-1">
                        <img
                            ngSrc="assets/credit-card/normal.svg"
                            alt="Card"
                            height="16"
                            width="16"
                        />
                        <span class="text-muted">
                            No Card on File
                        </span>
                    </div>
                </div>
                <div class="hstack gap-2 justify-content-between">
                    <span class="fw-semibold fs-6">
                        {{ null | currencyUnit : booking.pendingCancellationCharge.amount : 'GBP' }}
                        @if (booking.pendingCancellationCharge.isPerCover) {
                            ({{ null | currencyUnit : booking.pendingCancellationCharge.unitAmount : 'GBP' }}
                            x
                            {{ booking.size }})
                        }
                    </span>
                    <button
                        class="btn btn-sm btn-outline-secondary hstack gap-1 align-self-center"
                        (click)="editPendingPayment()"
                    >
                        Edit Charge
                        <img
                            ngSrc="assets/right/normal.svg"
                            alt="Right"
                            height="16"
                            width="16"
                        />
                    </button>
                </div>
                <hr class="my-1" />
                <div class="vstack">
                    <div class="hstack gap-1">
                        @if (venue.cancellationChargeAutomatically) {
                            <span class="text-muted">
                                Customer will be charged if they cancel less than
                            </span>
                        } @else {
                            <span class="text-muted">
                                Customer can be charged if they cancel less than
                            </span>
                        }
                        <span class="fw-semibold">
                            {{ venue.cancellationChargeCutOffHours }} hours
                        </span>
                        before the start time
                    </div>
                </div>
            </div>
        </div>
    }

    @if (booking.cancellation) {
        <div class="bg-light rounded-3 p-3">
            <div class="vstack gap-2">
                <div class="hstack gap-2 justify-content-between">
                    <span class="text-muted">
                        Cancellation Charge
                    </span>
                    @if (booking.cancellation.paymentMethodId) {
                        <div class="hstack gap-1">
                            <img
                                ngSrc="assets/credit-card/normal.svg"
                                alt="Card"
                                height="16"
                                width="16"
                            />
                            <span class="text-muted">
                                Card on File
                            </span>
                        </div>
                    } @else {
                        <span class="text-muted">
                            Card on File
                        </span>
                    }
                </div>
                <span class="fw-semibold fs-6">
                    {{ null | currencyUnit : booking.cancellation.charge().amount : 'GBP' }}
                    @if (booking.cancellation.charge().isPerCover) {
                        ({{ null | currencyUnit : booking.cancellation.charge().unitAmount : 'GBP' }}
                        x
                        {{ booking.size }})
                    }
                </span>
                <hr class="my-1" />
                <div class="vstack">
                    <div class="hstack gap-1">
                        @if (booking.canChargeCancellationFee()) {
                            <div class="hstack gap-2">
                                <div
                                    class="rounded bg-warning top-0 hstack justify-content-center"
                                    [style.width.px]="20"
                                    [style.height.px]="20"
                                >
                                    <img
                                        ngSrc="assets/credit-card/normal_white.svg"
                                        width="12"
                                        height="12"
                                        alt="Notification"
                                    />
                                </div>
                                <span class="text-muted">
                                    @switch (booking.statusType) {
                                        @case (BookingStatusType.Cancelled) {
                                            Cancelled
                                        }
                                        @case (BookingStatusType.NoShow) {
                                            No Show-ed
                                        }
                                        @default {
                                            Cancelled
                                        }
                                    }
                                    on
                                    <span class="fw-semibold">
                                        {{ booking.status().dateTime | date: 'short' }},
                                    </span>
                                    after the cut-off time of
                                    <span class="fw-semibold">
                                        {{ booking.cancellationCutOffDate() | date: 'short' }}
                                    </span>
                                </span>
                            </div>
                        } @else {
                            @if (booking.cancellation.chargeAutomatically) {
                                <span class="text-muted">
                                    Customer will be charged if they cancel after
                                </span>
                            } @else {
                                <span class="text-muted">
                                    Customer can be charged if they cancel after
                                </span>
                            }
                            <span class="fw-semibold">
                                {{ booking.cancellationCutOffDate() | date: 'short' }}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    @if (booking.pendingDepositCharge) {
        <ng-container
            [ngTemplateOutlet]="depositDetails"
            [ngTemplateOutletContext]="{
                amount: booking.pendingDepositCharge.amount,
                unitAmount: booking.pendingDepositCharge.unitAmount,
                isPerCover: booking.pendingDepositCharge.isPerCover,
                currencyCode: 'GBP',
                isPaid: false
            }"
        >
        </ng-container>
    }

    @if (booking.deposit) {
        <ng-container
            [ngTemplateOutlet]="depositDetails"
            [ngTemplateOutletContext]="{
                amount: booking.deposit.amount,
                unitAmount: undefined,
                isPerCover: false,
                currencyCode: booking.deposit.currencyCode,
                isPaid: booking.deposit.datePaid !== null
            }"
        >
        </ng-container>
    }

    <hr class="my-1" />
    <h5 class="fw-semibold my-0">
        Payments
    </h5>
    @if ((booking.cancellation?.charges ?? []).length > 0) {
        <ul class="list-group">
            @for (charge of booking.cancellation?.charges; track charge.id) {
                <li class="list-group-item py-2">
                    <div class="hstack gap-2 justify-content-between">
                        <div class="vstack">
                            <div class="hstack gap-2">
                                <span class="fw-semibold">
                                    {{ null | currencyUnit : charge.charge().amount : charge.currencyCode }}
                                </span>
                                <span class="badge bg-success-subtle text-success-emphasis lh-sm">
                                    Paid
                                </span>
                            </div>
                        </div>
                        <div class="hstack gap-1">
                            <span class="text-muted">
                                {{ charge.dateCreated | date: 'short' }}
                            </span>
                        </div>
                    </div>
                </li>
            }
        </ul>
    } @else if (booking.deposit?.datePaid !== null) {
        <ul class="list-group">
            <li class="list-group-item py-2">
                <div class="hstack gap-2 justify-content-between">
                    <div class="vstack">
                        <div class="hstack gap-2">
                            <span class="fw-semibold">
                                {{ null | currencyUnit : booking.deposit?.amount : booking.deposit?.currencyCode }}
                            </span>
                            @if (booking.deposit?.dateRefunded !== null) {
                                <span class="badge bg-danger-subtle text-danger-emphasis lh-sm">
                                    Refunded
                                </span>
                            } @else {
                                <span class="badge bg-success-subtle text-success-emphasis lh-sm">
                                    Paid
                                </span>
                            }
                        </div>
                    </div>
                    <div class="hstack gap-1">
                        <span class="text-muted">
                            {{ booking.deposit?.datePaid | date: 'short' }}
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    } @else {
        <h5 class="pt-4 pb-5 text-center text-muted">
            No Payments
        </h5>
    }

    <hr class="my-1" />

    <div class="hstack gap-2">
        @if (booking.status().canWaivePendingPayment()) {
            <button
                class="btn btn-info me-auto"
                (click)="waivePendingPayment()"
            >
                Waive Pending Payment
            </button>
        }
        @if (booking.pendingCancellationCharge || booking.cancellation) {
            <div class="hstack gap-2 ms-auto">
                @if (!booking.canChargeCancellationFee()) {
                    @if (!booking.cancellation?.paymentMethodId) {
                        <i
                            class="bi bi-info-circle text-muted"
                            [ngbTooltip]="'No card on file to charge the customer.'"
                        >
                        </i>
                    } @else if (!booking.activeCancellationChargeForBooking()) {
                        <i
                            class="bi bi-info-circle text-muted"
                            [ngbTooltip]="'There is no charge to be made for this booking.'"
                        >
                        </i>
                    } @else if (!booking.shouldChargeCancellationFee()) {
                        <i
                            class="bi bi-info-circle text-muted"
                            [ngbTooltip]="'The booking is not eligible for a cancellation charge.'"
                        >
                        </i>
                    }
                }
                <button
                    class="btn btn-sm btn-info"
                    [disabled]="!booking.canChargeCancellationFee()"
                    (click)="chargeCancellationFeeClicked(confirmChargeCancellationFeeModalContent)"
                >
                    Charge Customer
                </button>
            </div>
        }
    </div>
</div>

<ng-template
    #depositDetails
    let-amount="amount"
    let-unitAmount="unitAmount"
    let-isPerCover="isPerCover"
    let-currencyCode="currencyCode"
    let-isPaid="isPaid"
>
    <div class="bg-light rounded-3 p-3">
        <div class="vstack gap-2">
            <div class="hstack gap-2 justify-content-between">
                <span class="text-muted">
                    Deposit
                </span>
                <div class="hstack gap-1">
                    <img
                        ngSrc="assets/cash/normal_dark.svg"
                        alt="Card"
                        height="16"
                        width="16"
                    />
                    @if (isPaid) {
                        <span class="text-muted">
                            Paid
                        </span>
                    } @else {
                        <span class="text-muted">
                            Unpaid
                        </span>
                    }
                </div>
            </div>
            <div class="hstack gap-2 justify-content-between">
                <span class="fw-semibold fs-6">
                    {{ null | currencyUnit : amount : currencyCode }}
                    @if (isPerCover) {
                        ({{ null | currencyUnit : unitAmount : currencyCode }}
                        x
                        {{ booking.size }})
                    }
                </span>
                @if (!isPaid) {
                    <button
                        class="btn btn-sm btn-outline-secondary hstack gap-1 align-self-center"
                        (click)="editPendingPayment()"
                    >
                        Edit Charge
                        <img
                            ngSrc="assets/right/normal.svg"
                            alt="Right"
                            height="16"
                            width="16"
                        />
                    </button>
                }
            </div>
            <hr class="my-1" />
            <div class="vstack">
                <div class="hstack gap-1">
                        <span class="text-muted">
                        </span>
                    @if (venue.depositRefundCutOffDate(booking); as depositRefundCutOffDate) {
                        <span class="text-muted">
                            Refundable until
                        </span>
                        <span class="fw-semibold">
                            {{ depositRefundCutOffDate | date: 'short' }}
                        </span>
                    } @else {
                        <span class="text-muted">
                            Non-refundable
                        </span>
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #waivePendingPaymentModal>
    <div class="vstack gap-1">
    <span>
        @if (booking.pendingDepositCharge; as charge) {
            The customer has not yet paid the
            <span>
                {{ null | currencyUnit: charge.amount : 'GBP' }}
            </span>
            deposit charge.
        }
        @if (booking.pendingCancellationCharge; as charge) {
            The customer has not yet saved their card details for the
            <span>
                {{ null | currencyUnit: charge.amount : 'GBP' }}
            </span>
            cancellation charge.
        }
    </span>
        Are you sure you want to waive this?
        <p class="fw-semibold m-0">
            This action cannot be undone.
        </p>
    </div>
</ng-template>

<ng-template
    #confirmChargeCancellationFeeModalContent
>
    @if (booking.activeCancellationChargeForBooking()) {
        <ng-container
            [ngTemplateOutlet]="confirmChargeCancellationFeeModalBody"
            [ngTemplateOutletContext]="{ charge: booking.activeCancellationChargeForBooking() }"
        >
        </ng-container>
    }
</ng-template>

<ng-template
    #confirmChargeCancellationFeeModalBody
    let-charge="charge"
>
    <span class="p-2">
        Are you sure you like to charge the
        <span class="fw-bold">
            {{ null | currencyUnit: charge.unitAmount : 'GBP' }}
            <ng-container
                *ngIf="charge.isPerCover"
            >
                per person
            </ng-container>
        </span>
        cancellation fee?
    </span>
</ng-template>
