import { Area } from '../../../../../domain/Area'
import { Booking } from '../../../../../domain/Booking'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-paged-booking-list',
    templateUrl: './paged-booking-list.component.html',
})
export class PagedBookingListComponent {

    @Input() bookings$?: Observable<{ booking: Booking; area: Area }[]>
    @Input() isSearching!: boolean
    @Input() isShowingResults!: boolean
    @Input() config!: {
        itemsPerPage: number,
        currentPage: number,
        totalItems: number,
    }
    @Output() bookingSelected = new EventEmitter<Booking>()
    @Output() pageChanged = new EventEmitter<number>()
}
