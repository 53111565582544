<div
    class="vstack"
>
    <button
        *ngIf="!elementChoices"
        class="btn btn-sm btn-outline-secondary me-auto"
        (click)="loadElementChoices()"
        appEditableIcon
        [ngClass]="canChangeElement ? 'editable' : 'disabled'"
        [attr.data-testid]="dataTestId"
    >
        {{ selectedElement?.displayName || "" }}
    </button>
    <ng-container
        *ngIf="elementChoices"
    >
        <ng-container
            *ngIf="elementChoices | withLoading | async as elementChoices"
        >
            <ng-template [ngIf]="{ value: elementChoices.value }">
                <div class="hstack gap-2">

                <select
                    class="form-select"
                    (change)="selectNewElement($event.target!, elementChoices.value ?? [])"
                >
                    <option
                        *ngFor="let choice of elementChoices.value"
                        [value]="choice.id"
                        [selected]="selectedElement?.id === choice.id"
                    >
                        {{ choice.displayName }}
                    </option>
                </select>
                <div
                    class="hstack gap-1"
                >
                    <button
                        class="btn btn-sm btn-primary col-sm"
                        (click)="saveElementUpdate()"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn-sm btn-outline-secondary col-sm"
                        (click)="cancelElementUpdate()"
                    >
                        Cancel
                    </button>
                </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="elementChoices.loading">
                <div class="hstack gap-2">
                    <button
                        class="btn btn-sm btn-outline-secondary disabled"
                    >
                        {{ selectedElement?.displayName || "" }}
                    </button>
                    <div class="d-flex">
                        <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</div>
