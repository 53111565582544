export class DiaryPreferences {

    constructor(
        public confirmAddBooking: boolean,
        public merchantBookingsRequireChargeByDefault: boolean,
        public pendingPaymentExpiryMinutes: number | null
    ) { }

    pendingPaymentExpiryDateBookedAtDate(bookedAtDate: Date): Date | null {
        if (this.pendingPaymentExpiryMinutes === null) {
            return null
        }
        const expiryDate = new Date(bookedAtDate)
        const expiryMilliseconds = this.pendingPaymentExpiryMinutes * 60 * 1000
        expiryDate.setTime(expiryDate.getTime() + expiryMilliseconds)
        return expiryDate
    }
}
