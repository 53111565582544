import { DatePipe } from '@angular/common'
import { ElementChoice } from '@app/features/shared/components/element-choice-select/element-choice'

export class TimeElementChoice implements  ElementChoice<Date> {

    constructor(
        private date: Date,
        private datePipe: DatePipe
    ) { }

    get id(): string {
        return this.date.toISOString()
    }

    get displayName(): string {
        return this.datePipe.transform(this.date, 'shortTime') ?? ''
    }

    get value(): Date {
        return this.date
    }
}
