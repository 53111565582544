<div class="hstack gap-1">
    <button
        type="button"
        class="btn btn-outline-secondary flex-grow-0"
        [ngClass]="disabled ? 'disabled' : ''"
        (click)="decrement()"
    >
        −
    </button>
    @if (iconPath) {
        <div class="position-relative flex-grow-1 flex-md-grow-0">
            <div class="btn-group dropdown w-100">
                <button
                    class="btn btn-outline-secondary dropdown-toggle hstack gap-2"
                    [ngClass]="disabled ? 'disabled' : ''"
                    (click)="select.click()"
                >
                    <img
                        *ngIf="iconPath"
                        [ngSrc]="iconPath"
                        width="20"
                        height="20"
                        alt="number"
                    >
                    <ng-container
                        *ngIf="pluralMapping"
                    >
                        {{ number | i18nPlural: pluralMapping }}
                    </ng-container>
                    <ng-container
                        *ngIf="!pluralMapping"
                    >
                        {{ number }}
                    </ng-container>
                </button>
            </div>
            <select
                #select
                class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                data-testid="number-input-select"
                [ngModel]="number"
                [disabled]="disabled"
                (ngModelChange)="changeValue($event)"
            >
                <option
                    *ngFor="let i of inputRange"
                    [ngValue]="i"
                >
                    <ng-container
                        *ngIf="pluralMapping"
                    >
                        {{ i | i18nPlural: pluralMapping }}
                    </ng-container>
                    <ng-container
                        *ngIf="!pluralMapping"
                    >
                        {{ i }}
                    </ng-container>
                </option>
            </select>
        </div>
    } @else {
        <label class="text-body fw-semibold fs-5 px-2">
            {{ number | number }}
        </label>
    }
    <button
        type="button"
        class="btn btn-outline-secondary flex-grow-0"
        [ngClass]="disabled ? 'disabled' : ''"
        (click)="increment()"
    >
        +
    </button>
</div>
