<svg:g
        *ngFor="let viewModel of tableViewModels"
>
    <g
        [tableViewModel]="viewModel"
        [xScale]="xScale"
        [yScale]="yScale"
        [ngClass]="hasMovedTableDuringDrag ? 'pointer-events-none' : ''"
        app-floor-table
        [attr.data-testid]="'table-element'"
    >
    </g>
</svg:g>
