<span
    appBookingStatus
    [statusType]="statusType"
    [className]="tableHeight ? 'badge-' + tableHeight : 'badge-medium'"
    [ngClass]="{
        'late': isLate,
    }"
>
    {{ partySize | number }}
</span>
