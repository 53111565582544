import { DateComponent } from '@app/domain/DateComponent'
import { DateComponentUnit } from '@app/domain/DateComponentUnit'
import { DayNamePipe } from '@app/features/shared/pipes/day-name.pipe'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'dateComponentDescription',
})
export class DateComponentDescriptionPipe implements PipeTransform {

    constructor(
        private dayNamePipe: DayNamePipe
    ) { }

    transform(dateComponents: DateComponent[] | number[]): string {
        if (dateComponents.length === 0) {
            return 'Never'
        }
        const appliesToEveryDay = dateComponents.length === 7
        if (appliesToEveryDay) {
            return 'Daily'
        }
        let dayNames: string[]
        if (typeof dateComponents[0] === 'number') {
            dayNames = dateComponents.map(dayOfWeek => {
                return this.dayName(dayOfWeek as number)
            })
        } else {
            dayNames = dateComponents.map(component => {
                return this.dateComponentDescription(component as DateComponent)
            })
        }
        return `Every ${dayNames.join(', ')}`
    }

    dateComponentDescription(component: DateComponent): string {
        switch (component.unit) {
        case DateComponentUnit.DAY_OF_WEEK:
            return this.dayNamePipe.transform(component.value, 'short')
        }
        // TODO: Add descriptions for other date component unit types
        return ''
    }

    private dayName(dayOfWeek: number): string {
        return this.dayNamePipe.transform(dayOfWeek, 'short')
    }
}
