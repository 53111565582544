import { Pipe, PipeTransform } from '@angular/core';
import { BookingSource } from '@app/domain/BookingSource'

@Pipe({
  name: 'bookingSourceName'
})
export class BookingSourceNamePipe implements PipeTransform {

    transform(source: BookingSource): string {
        switch (source) {
        case BookingSource.Merchant:
            return 'Merchant'
        case BookingSource.Customer:
            return 'Customer'
        case BookingSource.Google:
            return 'Google'
        }
    }
}
