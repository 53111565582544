import {
    AnimationBuilder,
    AnimationMetadata,
    AnimationPlayer,
    animate,
    style,
} from '@angular/animations'
import { Directive, ElementRef, Inject, Input } from '@angular/core'

@Directive({
    selector: '[appFadeIn]',
})
export class FadeInDirective {

    @Input() speed = 0.125
    @Input() fadeInDelay = 0
    @Input() fadeOutDelay = 0
    private easing = 'ease-in-out'
    private currentAnimation?: AnimationPlayer

    constructor(
        @Inject(ElementRef) private element: ElementRef,
        private animationBuilder: AnimationBuilder
    ) { }

    @Input()
    set fadedIn(fadedIn: boolean) {
        this.currentAnimation?.finish()
        const animation = this.animationBuilder
            .build(fadedIn ? this.fadeInAnimation() : this.fadeOutAnimation())
        this.currentAnimation = animation.create(this.element.nativeElement)
        this.currentAnimation.play()
    }

    private fadeInAnimation(): AnimationMetadata[] {
        const timeLeft = this.speed * (1 - this.opacity)
        return [
            style({ opacity: '*' }),
            animate(`${timeLeft}s ${this.fadeInDelay}s ${this.easing}`, style({ opacity: 1 })),
        ]
    }

    private fadeOutAnimation(): AnimationMetadata[] {
        const timeLeft = this.speed * this.opacity
        return [
            style({ opacity: '*' }),
            animate(`${timeLeft}s ${this.fadeOutDelay}s ${this.easing}`, style({ opacity: 0 })),
        ]
    }

    private get opacity(): number {
        const opacity = window.getComputedStyle(this.element.nativeElement).opacity
        return Number(opacity)
    }
}
