export class TableCombinationDTO {

    constructor(
        public id: string,
        public tableIds: string[],
        public minimumSeats: number,
        public maximumSeats: number,
        public bookingOrder: number,
        public independentReservable: boolean
    ) { }
}
