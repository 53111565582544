import {
    AfterViewInit,
    Directive,
    ElementRef,
    NgZone, OnDestroy, Output,
} from '@angular/core'
import { CursorMovement } from '@app/features/shared/directives/cursor-movement'
import {
    Subject,
    Subscription,
    delay, filter, fromEvent, skipUntil, takeUntil, tap,
} from 'rxjs'

@Directive({
    selector: '[appDragMovement]',
})
export class DragMovementDirective implements AfterViewInit, OnDestroy {

    @Output() dragChanged = new Subject<CursorMovement>()
    private mouseEvents = new Subject<CursorMovement>()
    private onDestroy = new Subject<void>()
    private dragsStarted = new Subject<void>()
    private dragEnded = new Subject<void>()
    private longPressDelay = 450

    constructor(
        private svg: ElementRef,
        private zone: NgZone
    ) {
        this.startListeningForDragPattern()
    }

    ngAfterViewInit() {
        this.bindMouseEventsToSubject()
        this.bindTouchEventsToSubject()
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    private bindMouseEventsToSubject() {
        this.zone.runOutsideAngular(() => {
            const dragEnd$ = fromEvent<MouseEvent>(this.svg.nativeElement, 'mouseup', { passive: false })
            const drag$ = fromEvent<MouseEvent>(this.svg.nativeElement, 'mousemove', { passive: false })
            const dragStart$ = fromEvent<MouseEvent>(this.svg.nativeElement, 'mousedown', { passive: false })
            dragStart$
                .pipe(
                    takeUntil(this.onDestroy)
                )
                .subscribe(event => {
                    this.onMouseDown(event)
                    drag$
                        .pipe(
                            takeUntil(dragEnd$)
                        )
                        .subscribe(event => this.onMouseMove(event))
                })
            dragEnd$
                .pipe(
                    takeUntil(this.onDestroy)
                )
                .subscribe(event => this.onMouseUp(event))
        })
    }

    private bindTouchEventsToSubject() {
        this.zone.runOutsideAngular(() => {
            const touchDrag$ = fromEvent<TouchEvent>(this.svg.nativeElement, 'touchmove', { passive: false })
            const touchStart$ = fromEvent<TouchEvent>(this.svg.nativeElement, 'touchstart', { passive: false })
            const touchEnd$ = fromEvent<TouchEvent>(this.svg.nativeElement, 'touchend', { passive: false })
            let hasMovedOrEndedBeforeLongPressDelay = false
            touchStart$
                .pipe(
                    takeUntil(this.onDestroy),
                    tap(() => {
                        hasMovedOrEndedBeforeLongPressDelay = false
                        touchDrag$
                            .pipe(
                                takeUntil(touchEnd$)
                            )
                            .subscribe(() => {
                                hasMovedOrEndedBeforeLongPressDelay = true
                            })
                    }),
                    delay(this.longPressDelay),
                    filter(() => !hasMovedOrEndedBeforeLongPressDelay)
                )
                .subscribe(event => {
                    this.onTouchStart(event)
                    touchDrag$
                        .pipe(
                            takeUntil(touchEnd$)
                        )
                        .subscribe(event => this.onTouchMove(event))
                })
            touchEnd$
                .pipe(
                    takeUntil(this.onDestroy)
                )
                .subscribe(event => {
                    hasMovedOrEndedBeforeLongPressDelay = true
                    this.onTouchEnd(event)
                })
        })
    }

    private startListeningForDragPattern() {
        this.listenForDragToStart()
        this.listenForDragToEnd()
        this.dragEnded.asObservable()
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe(() => {
                this.listenForDragToStart()
                this.listenForDragToEnd()
            })
    }

    private listenForDragToStart(): Subscription {
        return this.mouseEvents.pipe(
            takeUntil(this.dragEnded),
            tap((event) => {
                if (event.event === 'down') {
                    this.dragsStarted.next()
                }
            }),
            skipUntil(this.dragsStarted)
        )
            .subscribe((event) => {
                this.dragChanged.next(event)
            })
    }

    private listenForDragToEnd() {
        this.mouseEvents
            .pipe(
                takeUntil(this.dragEnded),
                filter((event) => event.event === 'up')
            )
            .subscribe(() => {
                this.dragEnded.next()
            })
    }

    private onMouseDown(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        const [x, y] = this.translateToPosition(event.clientX, event.clientY)
        this.mouseEvents.next({
            event: 'down',
            x,
            y,
        })
    }

    private onMouseMove(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        const [x, y] = this.translateToPosition(event.clientX, event.clientY)
        this.mouseEvents.next({
            event: 'move',
            x,
            y,
        })
    }

    private onMouseUp(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        const [x, y] = this.translateToPosition(event.clientX, event.clientY)
        this.mouseEvents.next({
            event: 'up',
            x,
            y,
        })
    }

    private onTouchStart(event: TouchEvent) {
        event.stopPropagation()
        event.preventDefault()
        const touch = event.touches[0]
        const [x, y] = this.translateToPosition(touch.clientX, touch.clientY)
        this.mouseEvents.next({
            event: 'down',
            x,
            y,
        })
    }

    private onTouchMove(event: TouchEvent) {
        event.stopPropagation()
        event.preventDefault()
        const touch = event.touches[0]
        const [x, y] = this.translateToPosition(touch.clientX, touch.clientY)
        this.mouseEvents.next({
            event: 'move',
            x,
            y,
        })
    }

    private onTouchEnd(event: TouchEvent) {
        const touch = event.changedTouches[0]
        const [x, y] = this.translateToPosition(touch.clientX, touch.clientY)
        this.mouseEvents.next({
            event: 'up',
            x: x,
            y: y,
        })
    }

    private translateToPosition(x: number, y: number) {
        const element = this.svg.nativeElement
        if (!(element instanceof SVGSVGElement)) {
            const rect = element.getBoundingClientRect()
            return [x - rect.left, y - rect.top]
        }
        const point = element.createSVGPoint()
        point.x = x
        point.y = y
        const screenPoint = point.matrixTransform(element.getScreenCTM()?.inverse())
        return [screenPoint.x, screenPoint.y]
    }
}
