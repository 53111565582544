import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

type BannerType = 'info' | 'warning' | 'danger'

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.sass'],
})
export class BannerComponent implements OnInit {

    @Input() text!: string
    @Input() buttonTitle!: string
    @Input() type!: BannerType
    @Input() tooltipText?: string
    @Output() buttonClicked = new EventEmitter<void>()
    colourClass!: string

    constructor() { }

    ngOnInit() {
        switch (this.type) {
        case 'info':
            this.colourClass = 'info-banner'
            break
        case 'warning':
            this.colourClass = 'warning-banner'
            break
        case 'danger':
            this.colourClass = 'danger-banner'
            break
        }
    }
}
