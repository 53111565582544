import { Toast, ToastType } from './Toast'


export class ToastBuilder {
    private readonly toast: Toast

    constructor(message: string) {
        this.toast = new Toast(message)
    }

    withHeader(header: string) {
        this.toast.header = header
        return this
    }

    withType(type: ToastType) {
        this.toast.type = type
        return this
    }

    autohide(autohide: boolean) {
        this.toast.autohide = autohide
        return this
    }

    delay(delay: number) {
        this.toast.delay = delay
        return this
    }

    build() {
        return this.toast
    }
}
