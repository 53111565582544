import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'dates',
    standalone: true,
})
export class DateArrayPipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe
    ) { }

    transform(array: Date[], dateFormat: string, separator: string): string {
        return array
            .map(date => this.datePipe.transform(date, dateFormat))
            .join(separator)
    }
}
