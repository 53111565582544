export enum QuestionTypeDTO {
    Checkbox = 'CHECKBOX',
    Dropdown = 'DROPDOWN',
    AcknowledgeEndTime = 'ACKNOWLEDGE_END_TIME',
    DOB = 'DOB',
    ReasonsForVisit = 'REASONS_FOR_VISIT',
}

export class QuestionDTO {
    constructor(
        public id: string,
        public text: string,
        public required: boolean,
        public type: QuestionTypeDTO,
        public options: string[],
        public reasonIds: string[]
    ) { }
}
