import { Time } from '@app/domain/Time'

export class BookingSlot {
    dateTime: Date

    constructor(dateTime: Date) {
        this.dateTime = dateTime
    }

    get time(): Time {
        return new Time(null, this.dateTime.getHours(), this.dateTime.getMinutes())
    }

    endDateTime(bookingInterval: number): Date {
        const endDateTime = new Date(this.dateTime)
        endDateTime.setMinutes(endDateTime.getMinutes() + bookingInterval)
        return endDateTime
    }

    toLocalISOFormattedDateTimeString(): string {
        let offsetMs = this.dateTime.getTimezoneOffset() * 60 * 1000
        let msLocal =  this.dateTime.getTime() - offsetMs
        let dateLocal = new Date(msLocal)
        let iso = dateLocal.toISOString()
        return iso
    }

    isOnTheHour(): boolean {
        return this.dateTime.getMinutes() === 0
    }

    closestEdgeToDateTime(dateTime: Date, bookingInterval: number): Date {
        const start = this.dateTime
        const end = this.endDateTime(bookingInterval)
        const startDiff = Math.abs(start.getTime() - dateTime.getTime())
        const endDiff = Math.abs(end.getTime() - dateTime.getTime())
        return startDiff < endDiff ? start : end
    }
}
