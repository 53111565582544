import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'stringMask',
})
export class StringMaskPipe implements PipeTransform {

    transform(maskString: String, maskStringLength: number = 4 ): string {
        return maskString.substring(0, maskString.length - maskStringLength)
            .replace(/[-a-z\d]/gi,'*')
            .concat(maskString.substring(maskString.length - maskStringLength))
    }
}
