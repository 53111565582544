import { CodeHighlightService } from '@services/code-highlight.service'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'highlightCode',
})
export class HighlightCodePipe implements PipeTransform {

    constructor(
        private codeHighlightService: CodeHighlightService
    ) { }

    transform(value: string): string {
        return this.codeHighlightService.html(value)
    }
}
