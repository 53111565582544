import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms'

export enum InputType {
    Number = 'number',
    Email = 'email',
    Tel = 'tel',
    Text = 'text',
}

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.sass'],
})
export class InputComponent implements OnInit {

    isEditing: boolean = false
    value?: string | null
    inputForm!: FormGroup

    @Input() isEditable!: boolean
    @Input() placeholder?: string | null
    @Input() readonlyValue?: string | null
    @Input() inputType?: InputType
    @Input() initialValue?: string | null
    @Input() validators?: ValidatorFn[]
    @Output() inputSubmitted: EventEmitter<string | null> = new EventEmitter<string | null>()

    constructor(
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.value = this.initialValue || ''
        this.inputForm = this.fb.group({
            input: [
                this.value,
                Validators.compose([
                    ...this.validators || [],
                ]),
            ],
        })
    }

    startEditing() {
        this.isEditing = true
    }

    onInputChange(event: Event) {
        this.value = (event.target as any).value
    }

    cancelInputUpdate() {
        this.inputForm.get('input')?.setValue(this.initialValue || '')
        this.isEditing = false
    }

    finishInputUpdate() {
        this.isEditing = false
    }

    saveInputUpdate() {
        this.inputSubmitted.emit(this.value || null)
    }
}
