import { Charge } from '@app/domain/Charge'

export enum BookingStatusType {
    Requested = 'Requested',
    PendingPayment = 'PendingPayment',
    Booked = 'Booked',
    Cancelled = 'Cancelled',
    NoShow = 'NoShow',
    Waiting = 'Waiting',
    PartiallySeated = 'PartiallySeated',
    Seated = 'Seated',
    Finished = 'Finished',
    Rejected = 'Rejected',
}

export enum CancellationReason {
    ChargeExpired,
}

export function activeBookingStatusTypes(): BookingStatusType[] {
    return [
        BookingStatusType.Requested,
        BookingStatusType.PendingPayment,
        BookingStatusType.Booked,
        BookingStatusType.Waiting,
        BookingStatusType.PartiallySeated,
        BookingStatusType.Seated,
    ]
}

export function statusTypesIncurringCancellationCharges(): BookingStatusType[] {
    return [
        BookingStatusType.Cancelled,
        BookingStatusType.NoShow,
    ]
}

export class BookingStatus {
    constructor(
        public id: string,
        public metadata: Map<string, any | null>,
        public dateTime: Date,
        public type: BookingStatusType
    ) { }

    canAcceptBooking(): boolean {
        return this.type === BookingStatusType.Requested
    }

    canRejectBooking(): boolean {
        return this.type === BookingStatusType.Requested
    }

    canCancelBooking(): boolean {
        const acceptableTypes = [
            BookingStatusType.PendingPayment,
            BookingStatusType.Booked,
        ]
        return acceptableTypes.includes(this.type)
    }

    canNoShowBooking(): boolean {
        const acceptableTypes = [
            BookingStatusType.Booked,
        ]
        return acceptableTypes.includes(this.type)
    }

    canModifyBooking(): boolean {
        return activeBookingStatusTypes().includes(this.type)
    }

    canFinishBooking(): boolean {
        return this.type === BookingStatusType.Seated
    }

    canSetPartyToWaiting(): boolean {
        return [
            BookingStatusType.Booked,
            BookingStatusType.PartiallySeated,
            BookingStatusType.Seated,
        ].includes(this.type)
    }

    canPartiallySeatParty(): boolean {
        return [
            BookingStatusType.Waiting,
            BookingStatusType.Booked,
        ].includes(this.type)
    }

    canSeatParty(): boolean {
        return [
            BookingStatusType.Waiting,
            BookingStatusType.Booked,
            BookingStatusType.PartiallySeated,
        ].includes(this.type)
    }

    canUnseatParty(): boolean {
        return [
            BookingStatusType.Waiting,
            BookingStatusType.PartiallySeated,
            BookingStatusType.Seated,
        ].includes(this.type)
    }

    canReseatParty(): boolean {
        return this.type === BookingStatusType.Finished
    }

    canVoidBooking(): boolean {
        return true
    }

    canWaivePendingPayment(): boolean {
        return this.depositCharge() !== null || this.cancellationCharge() !== null
    }

    cancellationCharge(): Charge | null {
        const data = this.metadata.get('cancellationCharge')
        if (!data) {
            return null
        }
        return this.chargeFromMetadata('cancellationCharge')
    }

    depositCharge(): Charge | null {
        const data = this.metadata.get('depositCharge')
        if (!data) {
            return null
        }
        return this.chargeFromMetadata('depositCharge')
    }

    cancellationInformation(): {
        cancelledByCustomer: boolean,
        cancelledByUserId: string | null,
        cancelledByUserEmailAddress: string | null,
        reason: CancellationReason | null,
    } | null {
        if (this.type !== BookingStatusType.Cancelled) {
            return null
        }
        let reason: CancellationReason | null = null
        const reasonString = this.metadata.get('reason')
        switch (reasonString) {
        case 'ChargeExpired':
            reason = CancellationReason.ChargeExpired
            break
        default:
            break
        }
        return {
            cancelledByCustomer: this.metadata.get('cancelledByCustomer') || false,
            cancelledByUserId: this.metadata.get('cancelledByUserId') || null,
            cancelledByUserEmailAddress: this.metadata.get('cancelledByUserEmailAddress') || null,
            reason: reason,
        }
    }

    expiryDate(): Date | null {
        const date = this.metadata.get('expiresAt')
        if (!date) {
            return null
        }
        return new Date(date)
    }

    private chargeFromMetadata(key: string): Charge | null {
        const data = this.metadata.get(key)
        if (!data) {
            return null
        }
        const partySize = data['partySize']
        const unitAmount = data['unitAmount']
        const isPerCover = data['isPerCover']
        return new Charge(partySize, partySize, partySize, unitAmount, isPerCover)
    }
}
