<ng-container
    *ngIf="bookings$ | async as bookings"
>
    <ng-container
        *ngIf="this.config.totalItems === 0 && !isSearching && isShowingResults"
    >
        <p
            class="position-absolute top-0 bottom-0 start-0 end-0 pointer-events-none d-flex align-items-center justify-content-center"
        >
            No bookings found
        </p>
    </ng-container>
    <ng-container
        *ngIf="this.config.totalItems > 0"
        [ngTemplateOutlet]="results"
        [ngTemplateOutletContext]="{ $implicit: bookings }"
    >
    </ng-container>
</ng-container>
<ng-container
    *ngTemplateOutlet="paginationToolbar"
>
</ng-container>

<ng-template #results let-bookings>
    <ul
        class="list-group overflow-auto flex-grow-1"
    >
        <li
            *ngFor="let entry of bookings | paginate: config"
            class="list-group-item list-group-item-action"
            role="button"
            (click)="bookingSelected.emit(entry.booking)"
        >
            <app-booking-list-item
                [booking]="entry.booking"
                [area]="entry.area"
                [showDate]="true"
                [showDetails]="true"
            >

            </app-booking-list-item>
        </li>
    </ul>
</ng-template>


<ng-template #paginationToolbar>
    <div
        *ngIf="config.itemsPerPage < config.totalItems"
        class="bg-white mt-auto"
    >
        <hr class="m-0"/>
        <div
            class="px-2 px-lg-3 py-2 hstack gap-3 justify-content-between"
            pagination-template
            #p="paginationApi"
            (pageChange)="this.pageChanged.emit($event)"
        >
            <button
                class="btn btn-sm btn-outline-secondary"
                [class.disabled]="p.isFirstPage()"
                (click)="p.previous()"
            >
                Previous
            </button>
            <div class="hstack flex-grow-1 flex-wrap justify-content-center">
                <div
                    *ngFor="let page of p.pages"
                    [class.current]="p.getCurrent() === page.value"
                >
                    <a
                        *ngIf="p.getCurrent() !== page.value"
                        role="button"
                        class="p-2 fs-4 text-decoration-none"
                        (click)="p.setCurrent(page.value)"
                    >
                        <span class="text-secondary">
                            {{ page.label }}
                        </span>
                    </a>
                    <div
                        *ngIf="p.getCurrent() === page.value"
                        class="p-2 fs-4"
                    >
                        <span class="fw-semibold">
                            {{ page.label }}
                        </span>
                    </div>
                </div>
            </div>
            <button
                class="btn btn-sm btn-outline-secondary"
                [class.disabled]="p.isLastPage()"
                (click)="p.next()"
            >
                Next
            </button>
        </div>
    </div>
</ng-template>
