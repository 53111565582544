
export class BookingsPerHourReportData {

    constructor(
        public bookingHour: number,
        public customerMinBookings: number,
        public customerAvgBookings: number,
        public customerMaxBookings: number,
        public merchantMinBookings: number,
        public merchantAvgBookings: number,
        public merchantMaxBookings: number
    ) { }
}
