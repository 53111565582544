import { Pipe, PipeTransform } from '@angular/core'
import { ScaleBand, ScaleLinear, ScaleTime } from 'd3'
import { Venue } from '@app/domain/Venue'

@Pipe({
    name: 'scale',
})
export class ScalePipe implements PipeTransform {

    transform(
        value: any | null,
        scale: ScaleTime<any, any> | ScaleBand<any> | ScaleLinear<any, any>,
        endValue?: any
    ): number {
        if (value === null) {
            return scale.range()[1]
        }
        const start = scale(value)
        if (endValue) {
            const end = scale(endValue)
            return Math.max(0, end - start)
        }
        return start
    }
}

export function getNiceMinutesPerTick(venue: Venue, scale: ScaleTime<number, number>) {
    const interval = venue.bookingInterval
    if (!interval) {
        return 60
    }
    const totalTime = scale.domain()[1].getTime() - scale.domain()[0].getTime()
    const totalMinutes = totalTime / 1000 / 60
    const totalIntervals = totalMinutes / interval
    const widthPerInterval = scale.range()[1] / totalIntervals
    let stepMinutes = 0
    const step = widthPerInterval < 22 ? 2 : 1
    stepMinutes = interval * step
    const tooSmallToShowAnyIntervals = widthPerInterval < 12
    if (tooSmallToShowAnyIntervals) {
        stepMinutes = 60
    }
    return stepMinutes
}
